<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <v-btn color="#1daf80" dark class="my-2" @click="novaFamilia()">
          Nova Familia
        </v-btn>
      </div>
    </div>
    <div class="p-lg-1">
      <v-data-table
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
        :headers="header"
        :items="familias"
        class="elevation-1"
        :loading="loading"
        :loading-text="'Carregando...'"
      >
        <template v-slot:item.status="{ item }">
          <div v-if="item.status == 1" style="color: green">Ativo</div>
          <div v-else style="color: red">Inativo</div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="editItem(item)"> mdi-pencil </v-icon>
        </template>
        <template v-slot:no-data>
          <p class="text-center">Nenhum registro encontrado</p>
        </template>
      </v-data-table>
      <!-- Cadastro -->
      <v-dialog
        persistent
        max-width="500px"
        v-if="dialogCadastroFamilia"
        v-model="dialogCadastroFamilia"
      >
        <modal-cadastro-familia
          v-if="dialogCadastroFamilia"
          @close="dialogCadastroFamilia = false"
          @response="(dialogCadastroFamilia = false), getFamiliaList()"
        />
      </v-dialog>
      <!-- Edição -->
      <v-dialog
        persistent
        max-width="500px"
        v-if="dialogEditFamilia"
        v-model="dialogEditFamilia"
      >
        <modal-edit-familia
          v-if="dialogEditFamilia"
          :familia="familiaEdit"
          @close="dialogEditFamilia = false"
          @response="(dialogEditFamilia = false), getFamiliaList()"
        />
      </v-dialog>
      <!-- Delete -->
      <!-- <v-dialog
        persistent
        max-width="500px"
        v-if="dialogDeleteClasse"
        v-model="dialogDeleteClasse"
      >
        <modal-delete-classe
          v-if="dialogDeleteClasse"
          :classe="classeEdit"
          @close="dialogDeleteClasse = false"
          @response="getClasseList($event)"
        />
      </v-dialog> -->
    </div>
  </div>
</template>

<script>
import ClassificacaoService from "@/services/classificacao_service.js";
import ModalCadastroFamilia from "@/components/classificacao/familia/cadastro.vue";
import ModalEditFamilia from "@/components/classificacao/familia/editar.vue";

export default {
  mixins: [],
  components: {
    ModalEditFamilia,
    ModalCadastroFamilia,
  },
  data: () => ({
    services: {
      classificacaoService: ClassificacaoService.build(),
    },
    header: [
      {
        text: "Descrição",
        align: "start",
        sortable: false,
        value: "descricao",
      },
      {
        text: "Classe",
        align: "start",
        sortable: false,
        value: "descricao_classe",
      },
      {
        text: "Status",
        align: "center",
        class: "classe-style-status",
        sortable: false,
        value: "status",
      },
      {
        text: "Ações",
        align: "center",
        class: "classe-style-status",
        value: "actions",
        sortable: false,
      },
    ],
    loading: false,
    familias: [],
    familiaEdit: {},
    dialogCadastroFamilia: false,
    dialogEditFamilia: false,
    dialogDeleteFamilia: false,
  }),
  watch: {},
  mounted() {
    this.getFamiliaList();
  },
  computed: {},
  methods: {
    getFamiliaList() {
      this.loading = true;
      this.classes = [];
      this.services.classificacaoService
        .getFamiliaList()
        .then(async (response) => {
          if (response.status == 200) {
            const body = await response.json();
            this.familias = body.data[0];
          } else {
            console.error(response);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    novaFamilia() {
      this.dialogCadastroFamilia = true;
    },
    editItem(item) {
      this.familiaEdit = item;
      this.dialogEditFamilia = true;
    },
  },
};
</script>
<style>
.classe-style-status {
  width: 100px !important;
}
</style>
