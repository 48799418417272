<template>
  <v-card>
    <v-toolbar style="background-color: #006368; color: #fff">
      <v-toolbar-title>
        <span class="headline">Medicação</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="container">
        <div class="container">
          <div class="row">
            <div class="col">
              <h5>Medicamento</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <v-select
                v-model="presc_tipo"
                :items="list_presc_tipo"
                item-text="desc"
                item-value="value"
                label="Tipo Prescrição"
                hide-details
              />
            </div>
            <div class="col-3">
              <v-select
                v-model="presc_adm_medicamento"
                :items="list_adm_medicamento"
                item-text="desc"
                item-value="value"
                label="Administração do medicamento"
                hide-details
              />
            </div>
            <div class="col-3">
              <v-select
                v-model="presc_medicamento"
                :items="list_medicamentos"
                item-text="procedimento_descricao"
                item-value="procedimento_id"
                label="Medicamento"
                hide-details
              />
            </div>
            <div class="col-3">
              <v-text-field v-model="presc_dose" label="dosagem" hide-details />
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <v-select
                v-model="presc_tip_frequencia"
                :items="list_tip_frequencia"
                item-text="desc"
                item-value="value"
                label="Tipo Frequencia"
                hide-details
              />
            </div>
            <div class="col-2">
              <v-text-field
                v-model="presc_frequencia"
                label="Frequencia"
                :type="presc_tip_frequencia == 1 ? 'time' : 'number'"
                :min="presc_tip_frequencia == 1 ? '' : '1'"
                hide-details
              />
            </div>
            <div class="col-2">
              <v-text-field v-model="presc_ciclo" label="Ciclo" hide-details />
            </div>
            <div class="col-2">
              <v-text-field
                v-model="presc_dataInicio"
                label="Data Início"
                type="date"
                hide-details
              />
            </div>
            <div class="col-2">
              <v-text-field
                v-model="presc_horaInicio"
                label="Hora Início"
                type="time"
                min="1"
                hide-details
              />
            </div>
            <div class="col-2 d-flex justify-content-end align-items-end">
              <v-btn
                icon
                color="red"
                title="Adicionar Medicamento"
                @click="clearMedicamento()"
                :disabled="checkFormMedicamentoClean()"
              >
                <v-icon>mdi mdi-close-thick</v-icon>
              </v-btn>
              <v-btn
                icon
                :color="presc_edit !== '' ? 'blue' : 'green'"
                title="Adicionar Medicamento"
                @click="addMedicamento()"
                :disabled="!checkFormMedicamento()"
              >
                <v-icon
                  >mdi
                  {{
                    presc_edit !== "" ? "mdi-pencil" : "mdi-plus-thick"
                  }}</v-icon
                >
              </v-btn>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="row">
            <div class="col-12">
              <v-simple-table height="200px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Tipo</th>
                      <th class="text-left">Adm Medicamento</th>
                      <th class="text-left">Medicamento</th>
                      <th class="text-left">dosagem</th>
                      <th class="text-left">Tipo Frequência</th>
                      <th class="text-left">Frequência</th>
                      <th class="text-left">Ciclo</th>
                      <th class="text-left">Início</th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in prescricao" :key="index">
                      <th class="text-left">
                        {{ getDesc(item.tipo, "tipo_prescricao") }}
                      </th>
                      <th class="text-left">
                        {{ getDesc(item.adm_medicamento, "adm_frequencia") }}
                      </th>
                      <th class="text-left">
                        {{ getDesc(item.medicamento, "medicamento") }}
                      </th>
                      <th class="text-left">
                        {{ item.dosagem }}
                      </th>
                      <th class="text-left">
                        {{ getDesc(item.tip_frequencia, "tipo_frequencia") }}
                      </th>
                      <th class="text-left">
                        {{ item.frequencia }}
                      </th>
                      <th class="text-left">
                        {{ item.ciclo }}
                      </th>
                      <th class="text-left">
                        {{ item.dataInicio.split("-").reverse().join("/") }}
                      </th>
                      <th class="text-left">
                        <v-btn
                          class="mr-1"
                          x-small
                          icon
                          color="red"
                          title="Deletar"
                          @click="deleteMedicamento(index)"
                        >
                          <v-icon>mdi mdi-delete</v-icon>
                        </v-btn>
                        <v-btn
                          x-small
                          icon
                          color="primary"
                          title="Editar"
                          @click="editMedicamento(item, index)"
                        >
                          <v-icon>mdi mdi-pencil</v-icon>
                        </v-btn>
                      </th>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-end">
            <div class="col-2">
              <v-btn
                color="primary"
                @click="criarPrescricao()"
                :disabled="prescricao.length == 0"
                :dark="prescricao.length > 1"
              >
                Concluir
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card-text></v-card
  >
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import MedicamentoAnimalService from "@/services/medicamento_animal_service.js";
import PrescricaoService from "@/services/prescricao_service.js";

export default {
  mixins: [Mixin],

  components: {},
  data: () => ({
    step: 1,
    services: {
      prescricaoService: PrescricaoService.build(),
      medicamentoAnimalService: MedicamentoAnimalService.build(),
    },
    // Form Ambos|Reptil
    form_peso: "",
    form_curativo: "",
    form_alimentacao: "",
    form_esCorpal: "",
    form_consciencia: "",
    form_protMedicacao: "",
    form_metas: "",
    form_evolucao: "",
    // Form Mamifero|Aves
    form_temperatura: "",
    form_glicemia: "",
    // Form Mamifero
    form_pa: "",
    // Form Prescricao
    presc_tipo: "",
    presc_adm_medicamento: "",
    presc_medicamento: "",
    presc_dose: "",
    presc_tip_frequencia: 1,
    presc_frequencia: "",
    presc_ciclo: "",
    presc_dataInicio: new Date().toISOString().split("T")[0],
    presc_horaInicio: "",
    presc_edit: "",
    prescricao: [],
    list_tip_frequencia: [
      { value: 1, desc: "Hora" },
      { value: 2, desc: "Dia" },
      { value: 3, desc: "Semana" },
    ],
    list_presc_tipo: [
      { value: 1, desc: "Medicamentosa" },
      { value: 2, desc: "Cuidados Intensivos" },
      { value: 3, desc: "Nutricional" },
      { value: 4, desc: "Terapia de Suporte" },
      { value: 5, desc: "Exames Diagnósticos" },
      { value: 6, desc: "Isolamento" },
      { value: 7, desc: "Pós-Cirúrgica" },
      { value: 8, desc: "Cuidados Paliativos" },
    ],
    list_adm_medicamento: [
      { value: 1, desc: "Via Oral" },
      { value: 2, desc: "Via Intravenosa" },
      { value: 3, desc: "Via Subcutânea" },
      { value: 4, desc: "Via Intramuscular" },
      { value: 5, desc: "Via Tópica" },
      { value: 6, desc: "Via Intraocular" },
      { value: 7, desc: "Via Intranasal" },
      { value: 8, desc: "Via Intraarticular" },
      { value: 9, desc: "Via Intraóssea" },
      { value: 10, desc: "Via Intradérmica" },
      { value: 11, desc: "Via Retal" },
      { value: 12, desc: "Via Transdérmica" },
    ],
    list_medicamentos: [],
    unidadeId: JSON.parse(sessionStorage.vuex).unidade.id,
  }),
  props: {
    infoInternacao: {
      required: true,
    },
  },
  watch: {
    presc_tip_frequencia() {
      if (this.presc_edit != -1) {
        this.presc_frequencia = "";
      }
    },
  },
  mounted() {
    this.listarMedicamento();
    const hj = new Date();
    const hora =
      hj.getHours().toString().padStart(2, "0") + ":" + hj.getMinutes();
    this.data_medicacao = hj.toISOString().split("T")[0];
    this.hora_medicacao = hora;
  },
  computed: {},
  methods: {
    // entradaInternacao() {
    //   const model = this.getModel(this.infoEntrada);
    //   const modelPrescricao = this.getModelPrescricao(1);
    //   console.log("model", model);
    //   console.log("modelPrescricao", modelPrescricao);
    // },
    listarMedicamento() {
      this.services.medicamentoAnimalService
        .getMedicamentosProcedimentobyUnidade(this.unidadeId)
        // .getAll()
        .then(async (response) => {
          if (response.status == 200) {
            const resp = await response.json();
            this.list_medicamentos = resp.data;
            // this.list_medicamentos = resp.data.medicamentos;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    async criarPrescricao() {
      this.services.prescricaoService
        .postCriarPrescricao(this.getModelPrescricao(this.infoInternacao.id))
        .then(async (response) => {
          if (response.status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Prescrição cadastrada com sucesso",
              color: "sucess",
            });
            this.$emit("concluido");
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao cadastrar prescrição",
              color: "error",
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    getModel(tipo) {
      let model = {
        peso: this.form_peso,
        curativo: this.form_curativo,
        alimentacao: this.form_alimentacao,
        escore_coporal: this.form_esCorpal,
        consciencia: this.form_consciencia,
        protocolo_medicacao: this.form_protMedicacao,
        metas: this.form_metas,
        evolucao: this.form_evolucao,
      };
      if (tipo == 1 || tipo == 2) {
        model = {
          ...model,
          temperatura: this.form_temperatura,
          glicemia: this.form_glicemia,
        };
        if (tipo == 2) {
          model = { ...model, pa: this.form_pa };
        }
      }
      return model;
    },
    checkFormMedicamento() {
      if (
        this.presc_tipo == "" ||
        this.presc_frequencia == "" ||
        this.presc_adm_medicamento == "" ||
        this.presc_medicamento == "" ||
        this.presc_dose == "" ||
        this.presc_ciclo == "" ||
        this.presc_horaInicio == "" ||
        this.presc_dataInicio == ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    checkFormMedicamentoClean() {
      if (
        this.presc_tipo != "" ||
        this.presc_frequencia != "" ||
        this.presc_adm_medicamento != "" ||
        this.presc_medicamento != "" ||
        this.presc_dose != "" ||
        this.presc_ciclo != "" ||
        this.presc_horaInicio != "" ||
        this.presc_dataInicio != ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    editMedicamento(item, index) {
      this.presc_tip_frequencia = item.tip_frequencia;
      this.presc_tipo = item.tipo;
      this.presc_adm_medicamento = item.adm_medicamento;
      this.presc_medicamento = item.medicamento;
      this.presc_dose = item.dosagem;
      this.presc_ciclo = item.ciclo;
      this.presc_dataInicio = item.dataInicio;
      this.presc_horaInicio = item.horaInicio;
      let frequencia = item.tip_frequencia == 1 ? item.frequencia : 23;
      setTimeout(() => {
        this.presc_frequencia = frequencia;
      }, 200);
      this.presc_edit = index;
    },
    deleteMedicamento(index) {
      this.prescricao.splice(index, 1);
      if (index == this.presc_edit) {
        this.clearMedicamento();
      }
    },
    clearMedicamento() {
      this.presc_edit = "";
      this.presc_tipo = "";
      this.presc_tip_frequencia = 1;
      this.presc_adm_medicamento = "";
      this.presc_medicamento = "";
      this.presc_dose = "";
      this.presc_frequencia = "";
      this.presc_ciclo = "";
      this.presc_horaInicio = "";
      this.presc_dataInicio = new Date().toISOString().split("T")[0];
    },
    getModelPrescricao(id_internacao) {
      let model = {
        id_internacao: id_internacao,
        id_responsavel: JSON.parse(sessionStorage.vuex).usuario.id,
        id_perfil_responsavel: JSON.parse(sessionStorage.vuex).perfil.id,
        prescricoes: this.getModelMedicamento(),
      };
      return model;
    },
    getModelMedicamento() {
      let model = [];
      for (let index = 0; index < this.prescricao.length; index++) {
        const element = this.prescricao[index];
        // { value: 1, desc: "Hora" },
        // { value: 2, desc: "Dia" },
        // { value: 3, desc: "Semana" },
        let frequencia = 0;
        if (element.tip_frequencia == 2) {
          frequencia = element.frequencia * 24 * 60;
        } else if (element.tip_frequencia == 3) {
          frequencia = element.frequencia * 7 * 24 * 60;
        } else {
          let hora = element.frequencia.split(":")[0];
          let minutos = element.frequencia.split(":")[1];
          frequencia = parseInt(hora) * 60 + parseInt(minutos);
        }
        model.push({
          id_medicamento: element.medicamento,
          id_convenio_procedimento: this.getConvenioMedicamento(
            element.medicamento
          ),
          dosagem: element.dosagem,
          adm_medicamento: element.adm_medicamento,
          tipoPrescricao: element.tipo,
          tip_frequencia: element.tip_frequencia,
          frequencia: frequencia,
          ciclo: element.ciclo,
          dataInicio: element.dataInicio,
          horaInicio: element.horaInicio + ":00",
        });
      }
      return model;
    },
    addMedicamento() {
      const medicacao = {
        tipo: this.presc_tipo,
        adm_medicamento: this.presc_adm_medicamento,
        medicamento: this.presc_medicamento,
        dosagem: this.presc_dose,
        tip_frequencia: this.presc_tip_frequencia,
        frequencia: this.presc_frequencia,
        ciclo: this.presc_ciclo,
        horaInicio: this.presc_horaInicio,
        dataInicio: this.presc_dataInicio,
      };
      if (this.presc_edit !== "") {
        this.prescricao.splice(this.presc_edit, 1, medicacao);

        // this.prescricao[this.presc_edit] = medicacao;
      } else {
        this.prescricao.push(medicacao);
      }
      this.clearMedicamento();
    },
    checkPrescricao() {
      if (this.prescricao.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    getConvenioMedicamento(idMedicamento) {
      let medicacao = this.list_medicamentos.find((ele) => {
        return ele.procedimento_id == idMedicamento;
      });
      return medicacao.convenio_procedimento_id;
    },
    getDesc(value, tipo) {
      let desc = "-";
      switch (tipo) {
        case "tipo_prescricao":
          desc = this.list_presc_tipo.find((ele) => {
            return ele.value == value;
          }).desc;
          break;
        case "adm_frequencia":
          desc = this.list_adm_medicamento.find((ele) => {
            return ele.value == value;
          }).desc;
          break;
        case "medicamento":
          desc = this.list_medicamentos.find((ele) => {
            return ele.procedimento_id == value;
          }).procedimento_descricao;
          break;
        // case "medicamento":
        //   desc = this.list_medicamentos.find((ele) => {
        //     return ele.id == value;
        //   }).nome;
        //   break;
        case "tipo_frequencia":
          desc = this.list_tip_frequencia.find((ele) => {
            return ele.value == value;
          }).desc;
          break;
        default:
          break;
      }
      return desc;
    },
  },
};
</script>

<style></style>
