<template>
  <v-card>
    <v-toolbar style="background-color: #bd5000; color: #fff">
      <v-toolbar-title>
        <span class="headline">Editar Classe</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="container">
        <div class="row">
          <!-- <div class="col-4">
                <v-select
                  v-model="select_orgao"
                  :items="list_orgao"
                  item-text="desc"
                  item-value="id"
                  label="Orgão"
                  hide-details
                />
              </div> -->
          <div class="col-9">
            <v-text-field v-model="descricao" label="Descrição" hide-details />
          </div>
          <div class="col-3">
            <v-switch
              v-model="ativo"
              hide-details
              :label="ativo ? 'Ativo' : 'Inativo'"
            ></v-switch>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-content-between">
      <v-btn color="primary" outlined @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        color="#bd5000"
        :dark="descricao != ''"
        :disabled="descricao == ''"
        @click="novaClasse()"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ClassificacaoService from "@/services/classificacao_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  props: {
    classe: Object,
  },
  components: {},
  data: () => ({
    services: {
      classificacaoService: ClassificacaoService.build(),
    },
    descricao: "",
    ativo: "",
  }),
  watch: {},
  mounted() {
    this.descricao = this.classe.descricao;
    this.ativo = this.classe.status == 1 ? true : false;
  },
  computed: {},
  methods: {
    novaClasse() {
      this.services.classificacaoService
        .postUpdateClasse(this.model())
        .then(async (response) => {
          const body = await response.json();
          if (response.status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: "sucess",
            });
            this.$emit("response");
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: "error",
            });
            console.error(response);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    model() {
      return {
        id: this.classe.id,
        descricao: this.descricao,
        status: this.ativo ? 1 : 2,
      };
    },
  },
};
</script>
<style scoped></style>
