<template>
  <v-card>
    <v-toolbar style="background-color: #910000; color: #fff">
      <v-toolbar-title>
        <span class="headline">Saída do Animal</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4 class="mb-0">Deseja Efetuar a saída do animal do hospital ?</h4>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-content-between">
      <v-btn color="primary" outlined @click="$emit('close')"> Cancelar </v-btn>
      <v-btn color="#910000" dark @click="emitirSaida()"> Efetuar Saída </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import EntradaService from "@/services/entrada_service.js";

export default {
  mixins: [],
  props: {
    infoAnimal: Object,
  },
  components: {},
  data: () => ({
    services: {
      entradaService: EntradaService.build(),
    },
  }),
  watch: {},
  mounted() {},
  computed: {},
  methods: {
    emitirSaida() {},
  },
};
</script>
<style scoped></style>
