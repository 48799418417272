var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"min-height":"100vh"}},[_c('v-toolbar',{staticClass:"bg-verde",attrs:{"dark":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[(_vm.visualizar)?_c('span',{staticClass:"headline"},[_vm._v("Visualizar orgão")]):_c('span',{staticClass:"headline"},[_vm._v("Editar orgão")])]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',{staticClass:"pt-8"},[(!_vm.loading)?_c('v-container',[_c('v-form',{ref:"form",staticClass:"row"},[_c('div',{staticClass:"col-lg-6 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"nome"}},[_vm._v("Nome fantasia")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.nome_fantasia,"disabled":_vm.visualizar,"rules":[
                _vm.validationService.required('Campo obrigatório.'),
                _vm.validationService.isNotEmpty(),
              ],"type":'text',"placeholder":"Nome fantasia"},model:{value:(_vm.orgao.nome_fantasia),callback:function ($$v) {_vm.$set(_vm.orgao, "nome_fantasia", $$v)},expression:"orgao.nome_fantasia"}})],1),_c('div',{staticClass:"col-lg-6 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"nome"}},[_vm._v("Razão social")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.razao_social,"disabled":_vm.visualizar,"rules":[
                _vm.validationService.required(
                  'Campo Razão Social é obrigatório.'
                ),
                _vm.validationService.isNotEmpty(),
              ],"type":'text',"placeholder":"Razão Social"},model:{value:(_vm.orgao.razao_social),callback:function ($$v) {_vm.$set(_vm.orgao, "razao_social", $$v)},expression:"orgao.razao_social"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"CNPJ"}},[_vm._v("CNPJ")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.cnpj,"disabled":_vm.visualizar,"type":'text',"rules":[
                _vm.validationService.required('Campo CNPJ é obrigatório.'),
                _vm.validationService.isNotEmpty(),
                _vm.validationService.isCNPJ(),
              ],"placeholder":"00.000.000/0000-00"},model:{value:(_vm.orgao.cnpj),callback:function ($$v) {_vm.$set(_vm.orgao, "cnpj", $$v)},expression:"orgao.cnpj"}}),_c('span',{staticClass:"text-muted"},[_vm._v("ex: \"123.456.7890-12\"")])],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Telefone01"}},[_vm._v("Telefone 1:")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##)#####-####'),expression:"'(##)#####-####'"}],staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.telefone_1,"disabled":_vm.visualizar,"type":'text',"rules":[
                _vm.validationService.required('Campo Telefone é obrigatório.'),
                _vm.validationService.isNotEmpty(),
              ],"placeholder":"(00) 00000-0000"},model:{value:(_vm.orgao.telefone_1),callback:function ($$v) {_vm.$set(_vm.orgao, "telefone_1", $$v)},expression:"orgao.telefone_1"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Telefone01"}},[_vm._v("Telefone 2:")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##)####-####'),expression:"'(##)####-####'"}],staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.telefone_2,"disabled":_vm.visualizar,"type":'text',"placeholder":"(00) 00000-0000"},model:{value:(_vm.orgao.telefone_2),callback:function ($$v) {_vm.$set(_vm.orgao, "telefone_2", $$v)},expression:"orgao.telefone_2"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"CEP"}},[_vm._v("CEP")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.cep,"disabled":_vm.visualizar,"type":'text',"rules":[
                _vm.validationService.required('Campo CEP é obrigatório.'),
              ],"placeholder":"00000-000"},on:{"blur":_vm.searchCepEntrada},model:{value:(_vm.orgao.cep),callback:function ($$v) {_vm.$set(_vm.orgao, "cep", $$v)},expression:"orgao.cep"}}),_c('span',{staticClass:"text-muted"},[_vm._v("ex: \"xxxxx-xxx\"")])],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Rua"}},[_vm._v("Logradouro")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.logradouro,"disabled":_vm.visualizar,"rules":[
                _vm.validationService.required('Campo Logradouro é obrigatório.'),
              ],"type":'text'},model:{value:(_vm.orgao.logradouro),callback:function ($$v) {_vm.$set(_vm.orgao, "logradouro", $$v)},expression:"orgao.logradouro"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Bairro"}},[_vm._v("Bairro")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.bairro,"disabled":_vm.visualizar,"rules":[
                _vm.validationService.required('Campo Bairro é obrigatório.'),
              ],"type":'text'},model:{value:(_vm.orgao.bairro),callback:function ($$v) {_vm.$set(_vm.orgao, "bairro", $$v)},expression:"orgao.bairro"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Numero"}},[_vm._v("Número")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.numero,"disabled":_vm.visualizar,"type":'text',"rules":[
                _vm.validationService.required('Campo Número é obrigatório.'),
              ]},model:{value:(_vm.orgao.numero),callback:function ($$v) {_vm.$set(_vm.orgao, "numero", $$v)},expression:"orgao.numero"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Complemento"}},[_vm._v("Complemento")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.complemento,"disabled":_vm.visualizar,"type":'text'},model:{value:(_vm.orgao.complemento),callback:function ($$v) {_vm.$set(_vm.orgao, "complemento", $$v)},expression:"orgao.complemento"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Referencia"}},[_vm._v("Referência")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.referencias,"disabled":_vm.visualizar,"type":'text'},model:{value:(_vm.orgao.referencias),callback:function ($$v) {_vm.$set(_vm.orgao, "referencias", $$v)},expression:"orgao.referencias"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Cidade"}},[_vm._v("Cidade")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.cidade,"disabled":_vm.visualizar,"rules":[
                _vm.validationService.required('Campo Cidade é obrigatório.'),
              ],"type":'text'},model:{value:(_vm.orgao.cidade),callback:function ($$v) {_vm.$set(_vm.orgao, "cidade", $$v)},expression:"orgao.cidade"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Estado"}},[_vm._v("Estado")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.estado,"disabled":_vm.visualizar,"rules":[
                _vm.validationService.required('Campo Estado é obrigatório.'),
              ],"type":'text'},model:{value:(_vm.orgao.estado),callback:function ($$v) {_vm.$set(_vm.orgao, "estado", $$v)},expression:"orgao.estado"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',[_vm._v("Região Administrativa")]),_c('v-select',{staticStyle:{"padding-top":"0 !important"},attrs:{"items":_vm.list_reg_adm,"item-text":"desc","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"color":"#313030","margin-bottom":"5px"}},[_vm._v(" Cidade: "+_vm._s(data.item.desc)+" ")]),_c('v-list-item-subtitle',{staticStyle:{"font-weight":"bold","color":"#999"}},[_vm._v(" RA: "+_vm._s(data.item.ra)+" ")])],1)]}}],null,false,2550489381),model:{value:(_vm.selected_reg_adm),callback:function ($$v) {_vm.selected_reg_adm=$$v},expression:"selected_reg_adm"}})],1),_c('div',{staticClass:"col-12 form-group"},[(!_vm.visualizar)?_c('button',{staticClass:"btn btn-app-primary btn-flex btn-rounded font-weight-bold justify-content-end",on:{"click":function($event){$event.preventDefault();return _vm.atualizarOrgao()}}},[(!_vm.loading)?_c('span',[_vm._v(" Salvar ")]):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white"}})],1):_vm._e()])]),(_vm.messageSnackbar)?_c('v-snackbar',{attrs:{"color":_vm.typeMessageSnackbar,"timeout":-1},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":"","color":'white'},on:{"click":_vm.fecharSnackbar}},'v-btn',attrs,false),[_vm._v(" Ok ")])]}}],null,false,3805457681),model:{value:(_vm.messageSnackbar),callback:function ($$v) {_vm.messageSnackbar=$$v},expression:"messageSnackbar"}},[_vm._v(" "+_vm._s(_vm.messageSnackbar)+" ")]):_vm._e()],1):_c('v-container',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#1daf80"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }