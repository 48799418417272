import { DataHoraHj, LogoHeader } from "./utils_docs";

export function AutorizoVideo(animal, responsavel, unidade) {
  let content = [];

  const dateNow = DataHoraHj();

  content.push({
    columns: [
      {
        stack: [
          {
            image: LogoHeader(unidade.id),
            width: 55,
          },
        ],
        alignment: "left",
        border: [false, false, false, false],
      },
      {
        type: "none",
        ol: [
          { text: "Unidade", style: "text-header", bold: true },
          { text: unidade.razao_social, style: "text-header" },
          { text: "Endereço", style: "text-header", bold: true },
          { text: getEndereco(unidade), style: "text-header" },
        ],
      },
    ],
  });

  // Perguntas
  content.push([
    {
      text: "Termo de autorização de gravação e/ou envio de imagem e/ou áudio",
      style: "title",
    },
    {
      text: `Eu, ${responsavel.nomeResp}, portador(a) do CPF ${
        responsavel.cpfResp
      }, representando ${responsavel.nome} do CNPJ ${
        responsavel.cnpj
      }, localizado ${getEnderecoResponsavel(
        responsavel
      )}, na condição de responsável e responsável pelo animal de nome ${
        animal.nome
      }, classe ${animal.classe}, espécie ${animal.especie}, sexo ${
        animal.sexo
      }, autorizo a gravação de imagem e/ou áudio do Animal, a ser realizada pelo ${
        unidade.razao_social
      }.`,
      style: "text",
    },
    // {
    //   text: `Eu, ${responsavel.nomeResp}, portador(a) do CPF ${
    //     responsavel.cpfResp
    //   }, representando ${responsavel.nome} do CNPJ ${
    //     responsavel.cnpj
    //   }, localizado ${getEnderecoResponsavel(responsavel)}, do telefone n° ${
    //     responsavel.telefone
    //   }, na condição de responsável e responsável pelo animal de nome ${
    //     animal.nome
    //   }, classe ${animal.classe}, espécie ${animal.especie}, sexo ${
    //     animal.sexo
    //   }, autorizo a gravação de imagem e/ou áudio do Animal, a ser realizada pelo ${
    //     unidade.razao_social
    //   }.`,
    //   style: "text",
    // },
    {
      text: `Assumo a responsabilidade por solicitar com no mínimo 8 (oito) horas de antecedência, que sejam gravadas imagens diariamente, enquanto perdurar o período de internação.`,
      style: "text",
    },
    {
      text: `Em caso de solicitação prévia, declaro que fui esclarecido que o vídeo/áudio terá no máximo 2 (dois) minutos, e será enviado somente uma vez ao dia, no período entre 12:00 e 18:00, dependendo da disponibilidade da equipe do ${unidade.razao_social}.`,
      style: "text",
    },
    {
      text: `Conforme os t ermos acima, aut orizo o envio para o número: _______________________.`,
      style: "text",
    },
    {
      text: `Autorizo o tratamento e processamento dos dados pessoais do tutor(a) e responsávelacima identificado, para as finalidades estabelecidas neste termo e na forma prevista na legislação.`,
      style: "text",
    },
    {
      text: `As informações contidas neste documento foram explicadas detalhadamente e em linguagem acessível e de fácil compreensão. Sendo assim, assino abaixo.`,
      style: "text",
    },
    {
      text: `${unidade.razao_social}, ${dateNow}`,
      style: "text-date",
    },
    {
      style: "table",
      table: {
        widths: "*",
        body: [
          [
            {
              text: "",
              border: [false, false, false, false],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, false],
            },
          ],
          [
            {
              text: "",
              border: [false, false, false, false],
            },
            {
              text: `Assinatura do(a) responsável`,
              colSpan: 3,
              alignment: "center",
              border: [false, false, false, false],
            },
            {
              text: "",
            },
            {
              text: "",
            },
            {
              text: "",
              border: [false, false, false, false],
            },
          ],
        ],
      },
    },
    {
      text: "Testemunhas",
      style: "title-testemunha",
    },
    {
      columns: [
        {
          table: {
            widths: "*",
            body: [
              [
                {
                  text: "Nome:",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "CPF:",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "Assinatura :",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: "*",
            body: [
              [
                {
                  text: "Nome:",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "CPF:",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "Assinatura :",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
      ],
    },
  ]);

  let modelo = {
    content: content,
    styles: {
      "text-header": {
        fontSize: 8,
      },
      title: {
        fontSize: 13,
        color: "#8a8a8a",
        bold: true,
        alignment: "center",
        margin: [0, 20, 0, 10],
      },
      text: {
        margin: [0, 0, 0, 10],
      },
      "text-date": {
        alignment: "right",
        margin: [0, 0, 0, 10],
      },
      "title-testemunha": {
        fontSize: 10,
        bold: true,
        alignment: "center",
        margin: [0, 20, 0, 30],
      },
      table: {
        margin: [0, 40, 0, 0],
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 9,
    },
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          { width: 20, text: "" },
          {
            width: "*",
            text: {
              // text: `Anamnese gerada por ${usuario}`,
              text: ``,
              fontSize: 6,
            },
          },
          {
            width: "*",
            text: {
              text: "Página " + currentPage.toString() + " de " + pageCount,
              alignment: "right",
              fontSize: 6,
            },
          },
          { width: 20, text: "" },
        ],
        margin: [0, 10, 0, 0],
      };
    },
  };
  return modelo;
}

function getEndereco(item) {
  return `${item.logradouro},${item.numero} | ${item.cidade} - ${item.cep}`;
}

function getEnderecoResponsavel(item) {
  return `${item.logradouro},${item.numero} | ${item.cidade} - ${item.cep}`;
}
