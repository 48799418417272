<template>
  <v-card>
    <!-- <v-toolbar style="background-color: #006f91; color: #fff"> -->
    <v-toolbar style="background-color: #1d9b03; color: #fff">
      <v-toolbar-title>
        <!-- <span class="headline">Gerar Documento</span> -->
        <span class="headline">Galería Animal</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-3">
            <v-file-input
              v-model="selectedFiles"
              label="Seleciona imagem"
              ref="fileInput"
              accept="image/jpeg, image/png"
              hide-details
            ></v-file-input>
          </div>
          <div class="col-1">
            <v-btn
              class="mt-3"
              @click="addImages"
              color="primary"
              :disabled="selectedFiles == ''"
              >Adicionar</v-btn
            >
          </div>
        </div>
        <v-divider></v-divider>
        <div class="row">
          <div class="col-12 imagens-style" v-if="!loading">
            <div
              v-if="images.length > 0"
              class="d-flex justify-content-center flex-wrap"
              style="gap: 20px"
            >
              <v-card
                v-for="(image, index) in images"
                :key="index"
                width="200px"
              >
                <v-skeleton-loader
                  v-if="!image.url"
                  v-bind="attrs"
                  type="image"
                ></v-skeleton-loader>
                <v-img
                  v-else
                  :src="image.url"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="200px"
                  width="200px"
                >
                  <!-- <v-card-title v-text="image.arquivo_nome"></v-card-title> -->
                </v-img>

                <v-card-actions>
                  <v-btn
                    @click="destacarImagem(image)"
                    icon
                    :title="
                      image.thumb == 1 ? 'Imagem Destaque' : 'Destacar Imagem'
                    "
                    :disabled="image.thumb == 1"
                  >
                    <v-icon>
                      {{
                        image.thumb == 1
                          ? "mdi-bookmark"
                          : "mdi-bookmark-outline"
                      }}
                    </v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="viewImage(image)" icon>
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>

                  <v-btn @click="deletarImage(image)" icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div v-else>
              <h4 class="text-center">Sem imagens na galería.</h4>
            </div>
          </div>
          <div class="col-12" v-else>
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
          <v-dialog
            v-if="dialogImageView"
            v-model="dialogImageView"
            width="1000"
            transition="dialog-bottom-transition"
          >
            <v-img
              :src="imageView.url"
              class="white--text align-end"
              style="background-color: white"
            >
              <v-card-title style="background-color: rgba(0, 0, 0, 0.4)">
                <h4 class="" style="color: white">
                  {{ imageView.arquivo_nome }}
                </h4>
                <v-spacer></v-spacer>
                <v-btn
                  @click="dialogImageView = false"
                  fab
                  x-small
                  color="red"
                  dark
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
            </v-img>
          </v-dialog>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import EntradaService from "@/services/entrada_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  props: {
    entradaInfo: Object,
  },
  data: () => ({
    services: {
      entradaService: EntradaService.build(),
    },
    selectedFiles: "",
    images: [],
    imageView: {},
    loading: false,
    loadingImagens: false,
    dialogImageView: false,
    checkImagesSend: [],
  }),
  watch: {},
  mounted() {
    this.getImagensList();
  },
  computed: {},
  methods: {
    getImagensList() {
      this.loading = true;
      this.services.entradaService
        .getImagensbyEntradaId(this.entradaInfo.id)
        .then(async (response) => {
          if (response.status == 200) {
            const resp = await response.json();
            this.images = [];
            for (let index = 0; index < resp.data.length; index++) {
              const element = resp.data[index];
              this.getImagembyId(element);
              this.images.push({ ...element, url: "" });
            }
          }
        })
        .catch((e) => {
          console.log("erro ", { e });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getImagembyId(img) {
      this.services.entradaService
        .getImagensbyImagemId(img.id)
        .then(async (response) => {
          if (response.status == 200) {
            const resp = await response.json();
            const ext = img.arquivo_nome.split(".");
            const index = this.images.findIndex((ele) => ele.id == img.id);
            this.images[
              index
            ].url = `data:image/${ext[1]};base64,${resp.data.base64}`;
          }
        })
        .catch((e) => {
          console.log("erro ", { e });
        })
        .finally(() => {});
    },
    addImages() {
      // let file = "";
      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   file = { url: e.target.result, file: this.selectedFiles };
      // };
      // reader.readAsDataURL(this.selectedFiles);

      let formData = new FormData();
      formData.append(
        "id_unidade",
        JSON.parse(sessionStorage.vuex).unidade.id.toString()
      );
      formData.append("id_pet", this.entradaInfo.id_pet.toString());
      formData.append("id_entrada", this.entradaInfo.id.toString());
      formData.append("arquivo", this.selectedFiles);
      formData.append("thumb", 0);
      formData.append(
        "descricao",
        this.rgv_animal ? this.rgv_animal : "Sem descrição"
      );
      this.$refs.fileInput.reset();
      this.selectedFiles = "";
      this.sendImageEntrada(formData);

      // if (this.selectedFiles.length) {
      //   Array.from(this.selectedFiles).forEach((file) => {
      //     const reader = new FileReader();
      //     reader.onload = (e) => {
      //       this.images.push({ url: e.target.result, file }); // Armazenar URL e arquivo
      //     };
      //     reader.readAsDataURL(file);
      //   });
      //   // Limpar o input após adicionar as imagens
      //   this.$refs.fileInput.reset();
      //   this.selectedFiles = []; // Limpa o valor do modelo
      // }
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
    viewImage(image) {
      this.imageView = image;
      this.dialogImageView = true;
    },
    async sendImageEntrada(model) {
      this.services.entradaService
        .postNovaImagem(model)
        .then(async (response) => {
          if (response.status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Imagem salva com sucesso",
              color: "sucess",
            });
            this.getImagensList();
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao salvar imagem",
              color: "error",
            });
          }
        })
        .catch((e) => {
          console.log("erro ", { e });
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        })
        .finally(() => {});
    },
    async destacarImagem(image) {
      if (image.thumb == 0) {
        this.services.entradaService
          .postDestaqueImage({
            id_imagem: image.id,
            id_entrada: image.id_entrada,
          })
          .then(async (response) => {
            if (response.status == 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Destaque efetuado sucesso",
                color: "sucess",
              });
              this.getImagensList();
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Erro ao destacar imagem",
                color: "error",
              });
            }
          })
          .catch((e) => {
            console.log("erro ", { e });
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          })
          .finally(() => {});
      }
    },
    async deletarImage(image) {
      console.log("i", image);
      this.services.entradaService
        .deleteImagem(image.id)
        .then(async (response) => {
          if (response.status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Imagem deletada sucesso",
              color: "sucess",
            });
            let index = this.images.findIndex((ele) => {
              return ele.id == image.id;
            });
            this.images.splice(index, 1);
            // this.getImagensList();
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao deletar imagem",
              color: "error",
            });
          }
        })
        .catch((e) => {
          console.log("erro ", { e });
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped></style>
<style>
.new-edit-2 .ql-toolbar {
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
}
.new-edit-2 .ql-editor {
  max-height: 300px;
  /* max-height: 400px; */
}
.imagens-style {
  /* max-height: 500px; */
  overflow: auto;
}
.v-btn--disabled.mark-green {
  color: green !important;
}
.mark-red {
  color: red;
}
</style>
