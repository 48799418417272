import { DataHoraHj, LogoHeader } from "./utils_docs";

export function TermoAtesViagem(animal, responsavel, unidade, usuario) {
  let content = [];
  const dateNow = DataHoraHj();

  content.push({
    columns: [
      {
        stack: [
          {
            image: LogoHeader(unidade.id),
            width: 55,
          },
        ],
        alignment: "left",
        border: [false, false, false, false],
      },
      {
        type: "none",
        ol: [
          { text: "Unidade", style: "text-header", bold: true },
          { text: unidade.razao_social, style: "text-header" },
          { text: "Endereço", style: "text-header", bold: true },
          { text: getEndereco(unidade), style: "text-header" },
        ],
      },
    ],
  });

  // Perguntas
  content.push([
    {
      text: "Atestado de saúde para viagens",
      style: "title",
    },
    {
      text: "1. Identificação do proprietário",
      style: "title-topic",
    },
    {
      margin: [0, 0, 0, 30],
      table: {
        widths: ["auto", "*", "auto", "*"],
        // heights: 20,
        body: [
          [
            {
              text: "Nome completo",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: responsavel.nome,
              margin: [0, 2, 0, 2],
              colSpan: 3,
              border: [true, true, true, true],
            },
            { text: "" },
            { text: "" },
          ],
          [
            {
              text: "CPF/Passaporte",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "",
              border: [true, true, true, true],
            },
            {
              text: "Telefone",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "",
              border: [true, true, true, true],
            },
          ],
          [
            {
              text: "Endereço",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: `${responsavel.logradouro}, ${responsavel.numero} | ${responsavel.cep}`,
              colSpan: 3,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            { text: "" },
            { text: "" },
          ],
          [
            {
              text: "Cidade",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: responsavel.cidade,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "Estado",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: responsavel.uf,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
          ],
        ],
      },
    },
    {
      text: "2. Identificação do Animal",
      style: "title-topic",
    },
    {
      margin: [0, 0, 0, 30],
      table: {
        widths: ["auto", "*", "auto", "*", "auto", "*"],
        // heights: 20,
        body: [
          [
            {
              text: "Nome",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: animal.nome,
              colSpan: 2,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            { text: "" },
            {
              text: "Rgv",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: animal.rgv,
              colSpan: 2,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            { text: "" },
          ],
          [
            {
              text: "Classificação",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: `${animal.an_classe} | ${animal.an_familia} | ${animal.an_esp_esp}`,
              colSpan: 5,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            { text: "" },
            { text: "" },
            { text: "" },
            { text: "" },
          ],
          [
            {
              text: "Sexo",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: animal.sexo,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "Data Nascimento",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "",
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "Idade",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: animal.idade,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
          ],
          [
            {
              text: "Microchip",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "",
              colSpan: 2,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            { text: "" },
            {
              text: "Data Aplicação",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "",
              colSpan: 2,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            { text: "" },
          ],
        ],
      },
    },
    {
      text: "3. Vacina anti-rábica",
      style: "title-topic",
    },
    {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*", "*", "*", "*", "*"],
        // heights: 20,
        body: [
          [
            {
              text: "Laboratório",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "Nome comercial",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "Data Aplicação",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "Validade Vacinação",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "N° lote",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
          ],
          [
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
          ],
        ],
      },
    },
    {
      text: "*para viagens à União Europeia é necessário anexar laudo sorológico conforme Regulamento (UE) 576/2013.",
      style: "sub-detail",
      margin: [0, 0, 0, 30],
    },
    {
      text: "4. Informações Sanitárias",
      style: "title-topic",
      margin: [0, 0, 0, 5],
    },
    {
      text: "Atesto para os devidos fins que o animal acima identificado, foi por mim examinado, está clinicamente sadio, não apresenta sinais de doenças infectocontagiosas e parasitárias, inclusive livre da presença de miíases, e até onde alcança meu conhecimento não foi diagnosticado positivamente para leishmaniose, portanto está apto para o transporte.",
      margin: [0, 0, 0, 30],
    },
    {
      pageBreak: "before",
      text: "5. Tratamento Antiparasitário",
      style: "title-topic",
    },
    {
      margin: [0, 5, 0, 5],
      text: "Antiparasitário interno",
      style: "title-topic",
    },
    {
      margin: [0, 0, 0, 5],
      table: {
        widths: ["*", "*", "*", "*"],
        // heights: 20,
        body: [
          [
            {
              text: "Data aplicação",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "Laboratório",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "Nome comercial",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "Princípio(s) Ativo(s)",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
          ],
          [
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
          ],
          [
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
          ],
        ],
      },
    },
    {
      margin: [0, 0, 0, 10],
      text: "Informações adicionais",
      style: "title-topic",
    },
    {
      margin: [0, 0, 0, 30],
      table: {
        widths: ["*", "*", "*", "*"],
        // heights: 20,
        body: [
          [
            {
              text: "Data aplicação",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "Laboratório",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "Nome comercial",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "Princípio(s) Ativo(s)",
              bold: true,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
          ],
          [
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
          ],
          [
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
            {
              text: " ",
              margin: [0, 8, 0, 8],
              border: [true, true, true, true],
            },
          ],
        ],
      },
    },
    {
      text: "6. Informações adicionais",
      style: "title-topic",
    },
    {
      margin: [0, 0, 0, 60],
      table: {
        widths: ["*"],
        // heights: 20,
        body: [
          [
            {
              text: " ",
              margin: [0, 40, 0, 40],
              border: [true, true, true, true],
            },
          ],
        ],
      },
    },
    {
      table: {
        widths: ["*", "*", "*", "*", "*", "*"],
        body: [
          [
            {
              text: getEndereco(unidade),
              margin: [0, 2, 0, 2],
              colSpan: 3,
              border: [true, true, true, true],
            },
            { text: "" },
            { text: "" },
            {
              text: dateNow,
              margin: [0, 2, 0, 2],
              colSpan: 3,
              border: [true, true, true, true],
            },
            { text: "" },
            { text: "" },
          ],
          [
            {
              text: "Nome",
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: usuario.nome,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "CRMV",
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: usuario.crmv,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: usuario.perfil,
              colSpan: 2,
              margin: [0, 2, 0, 2],
              border: [true, true, true, true],
            },
            {
              text: "",
            },
          ],
        ],
      },
    },
  ]);

  let modelo = {
    content: content,
    styles: {
      "text-header": {
        fontSize: 8,
      },
      title: {
        fontSize: 13,
        color: "#8a8a8a",
        bold: true,
        alignment: "center",
        margin: [0, 20, 0, 10],
      },
      "title-topic": {
        fontSize: 11,
        color: "#444444",
        bold: true,
        margin: [0, 0, 0, 5],
      },
      "sub-detail": {
        fontSize: 8,
        bold: true,
      },
      text: {
        margin: [0, 0, 0, 10],
      },
      "text-obs": {
        margin: [0, 0, 0, 0],
      },
      "text-date": {
        alignment: "right",
        margin: [0, 0, 0, 10],
      },
      "title-testemunha": {
        fontSize: 10,
        bold: true,
        alignment: "center",
        margin: [0, 20, 0, 30],
      },
      table: {
        margin: [0, 40, 0, 0],
      },
      "table-text": {
        margin: [0, 0, 0, 10],
      },
      "table-obs": {
        margin: [0, 10, 0, 5],
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 9,
    },
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          { width: 20, text: "" },
          {
            width: "*",
            text: {
              text: `Gerada por ${usuario.nome} | ${dateNow}`,
              fontSize: 6,
            },
          },
          {
            width: "*",
            text: {
              text: "Página " + currentPage.toString() + " de " + pageCount,
              alignment: "right",
              fontSize: 6,
            },
          },
          { width: 20, text: "" },
        ],
        margin: [0, 10, 0, 0],
      };
    },
  };
  return modelo;
}

function getEndereco(item) {
  return `${item.logradouro}, ${item.numero} | ${item.cidade} - ${item.cep}`;
}

// function getEnderecoResponsavel(item) {
//   return `${item.logradouro},${item.numero} | ${item.cidade} - ${item.cep}`;
// }
