<template>
  <div class="w-100">
    <div class="row">
      <div class="col-lg-4">
        <fila-consultas :consultas="consultas"/>
      </div>
      <div class="col-lg-4">
        <fila-cirurgias :cirurgias="cirurgias"/>
      </div>
      <div class="col-lg-4">
        <fila-compromissos-pessoais :compromissos-pessoais="compromissosPessoais"/>
      </div>
    </div>
  </div>
</template>

<script>
import FilaCirurgias from "@/components/dashboards/dashboard_medico/compromissos/fila_cirurgias";
import FilaConsultas from '@/components/dashboards/dashboard_medico/compromissos/fila_consultas'
import FilaCompromissosPessoais from '@/components/dashboards/dashboard_medico/compromissos/fila_compromissos_pessoais'
export default {
  components: {FilaCirurgias, FilaConsultas, FilaCompromissosPessoais},
  props: {
    compromissos: { type: Array, required: true }
  },
  computed: {
    consultas () {
      return this.compromissos.filter(
          compromisso => compromisso.idTipoProcedimento === this.$global.tiposProcedimentos.consulta
      )
    },
    cirurgias () {
      return this.compromissos.filter(
          compromisso => compromisso.idTipoProcedimento === this.$global.tiposProcedimentos.cirurgia
      )
    },
    compromissosPessoais () {
      return this.compromissos.filter(
          compromisso => compromisso.idTipoProcedimento === this.$global.tiposProcedimentos.compromissoPessoal
      )
    }
  }
}
</script>