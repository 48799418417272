import HTTPService from "@/services/http_service.js";
export default class MedicamentoAnimalService extends HTTPService {
  static build() {
    return new MedicamentoAnimalService();
  }

  resource = "medicamento";
  getMedicamentos({ page = 1, per_page = 9999, search, order, desc }) {
    return this.get(`${this.resource}`, {
      page,
      per_page,
      search,
      order,
      desc,
    });
  }

  getAll() {
    return this.get(`${this.resource}`);
  }

  getMedicamentosProcedimentobyUnidade(unidadeId) {
    return this.get(`procedimento/medicacao/unidade/publico/list/${unidadeId}`);
  }
}
