import { DataHj, LogoHeader } from "./utils_docs";

export function ReceitaCarbonara(
  animal,
  responsavel,
  unidade,
  medico,
  medicamento,
  usuario
) {
  let content = [];
  //  medicamento: this.medicamento,
  // quantidade: this.qntd,
  // posologia: this.dosagem,

  // const dateNow = DataHoraHj();

  content.push({
    columns: [
      {
        stack: [
          {
            image: LogoHeader(unidade.id),
            width: 55,
          },
        ],
        alignment: "left",
        border: [false, false, false, false],
      },
      {
        type: "none",
        ol: [
          { text: "Unidade", style: "text-header", bold: true },
          { text: unidade.razao_social, style: "text-header" },
          { text: "Endereço", style: "text-header", bold: true },
          { text: getEndereco(unidade), style: "text-header" },
        ],
      },
    ],
  });

  // Perguntas
  content.push([
    {
      margin: [0, 20, 0, 20],
      table: {
        headerRows: 1,
        widths: "*",
        body: [[{ text: "", border: [false, false, false, true] }]],
      },
      layout: {
        hLineWidth: function () {
          return 2;
        },
        vLineWidth: function () {
          return 2;
        },
        hLineColor: "lightgray",
        vLineColor: "lightgray",
      },
    },
    {
      columns: [
        {
          text: "Receitade Controle Especial",
          style: "text-info",
          alignment: "left",
        },
        {
          text: "1ª via: Farmácia | 2ª via: Paciente",
          style: "text-info",
          alignment: "right",
        },
      ],
    },
    {
      style: "table-info-geral",
      table: {
        widths: "*",
        body: [
          [
            {
              margin: [8, 8, 8, 8],
              stack: [
                {
                  text: "Identificação do Emitente",
                  bold: true,
                  margin: [0, 0, 0, 8],
                },
                {
                  text: [
                    { text: "Nome: ", style: "text", bold: true },
                    { text: medico.nome, style: "text" },
                  ],
                },
                {
                  text: [
                    { text: "CRMV: ", style: "text", bold: true },
                    { text: medico.crmv, style: "text" },
                  ],
                },
                {
                  text: [
                    { text: "Endereço: ", style: "text", bold: true },
                    { text: getEndereco(unidade), style: "text" },
                  ],
                },
              ],
            },
            {
              margin: [8, 8, 8, 8],
              stack: [
                { text: "Animal", bold: true, margin: [0, 0, 0, 8] },
                {
                  text: [
                    { text: "Nome: ", style: "text", bold: true },
                    { text: animal.nome, style: "text" },
                  ],
                },
                {
                  text: [
                    { text: "Classe: ", style: "text", bold: true },
                    { text: animal.classe, style: "text" },
                  ],
                },
                {
                  text: [
                    { text: "Espécie: ", style: "text", bold: true },
                    { text: animal.especie, style: "text" },
                  ],
                },
                {
                  text: [
                    { text: "Sexo: ", style: "text", bold: true },
                    { text: animal.sexo, style: "text" },
                  ],
                },
                {
                  text: [
                    { text: "RGV: ", style: "text", bold: true },
                    { text: animal.rgv, style: "text" },
                  ],
                },
              ],
            },
            {
              margin: [8, 8, 8, 8],
              stack: [
                {
                  text: "Responsável",
                  bold: true,
                  margin: [0, 0, 0, 8],
                },
                {
                  text: [
                    { text: "Nome: ", bold: true, style: "text" },
                    { text: responsavel.nome, style: "text" },
                  ],
                },
                {
                  text: [
                    { text: "CPF: ", bold: true, style: "text" },
                    { text: responsavel.cnpj, style: "text" },
                  ],
                },
                {
                  text: [
                    { text: "Endereço: ", bold: true, style: "text" },
                    {
                      text: getEnderecoResponsavel(responsavel),
                      style: "text",
                    },
                  ],
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineColor: "lightgray",
        vLineColor: "lightgray",
      },
    },
    {
      style: "table-medicamento",
      table: {
        margins: [0, 15, 0, 15],
        widths: "*",
        body: getListMedicamento(medicamento),
      },
    },
    {
      margin: [0, 20, 0, 20],
      table: {
        headerRows: 1,
        widths: "*",
        body: [[{ text: "", border: [false, false, false, true] }]],
      },
      layout: {
        hLineWidth: function () {
          return 2;
        },
        vLineWidth: function () {
          return 2;
        },
        hLineColor: "lightgray",
        vLineColor: "lightgray",
      },
    },
    {
      style: "tableExample",
      table: {
        widths: "*",
        body: [
          [
            {
              margin: [8, 8, 8, 8],
              border: [false, false, false, false],
              stack: [
                {
                  text: getEndereco(unidade),
                  bold: true,
                  margin: [0, 10, 0, 10],
                },
                { text: medico.nome, bold: true },
                { text: "CRMV: " },
                { text: medico.crmv, bold: true },
                {
                  style: "assinatura",
                  table: {
                    widths: "*",
                    heights: 10,
                    body: [
                      [
                        {
                          text: "",
                          border: [false, false, false, true],
                        },
                        {
                          text: "",
                          border: [false, false, false, true],
                        },
                        {
                          text: "",
                          border: [false, false, false, true],
                        },
                        {
                          text: "",
                          border: [false, false, false, true],
                        },
                        {
                          text: "",
                          border: [false, false, false, true],
                        },
                      ],
                      [
                        {
                          text: "",
                          border: [false, false, false, false],
                        },
                        {
                          colSpan: 3,
                          text: "Assinatura",
                          alignment: "center",
                          border: [false, false, false, false],
                        },
                        {
                          text: "",
                          border: [false, false, false, false],
                        },
                        {
                          text: "",
                          border: [false, false, false, false],
                        },
                        {
                          text: "",
                          border: [false, false, false, false],
                        },
                      ],
                    ],
                  },
                },
              ],
            },
            {
              margin: [10, 10, 10, 10],
              border: [false, false, false, false],
              stack: [
                {
                  text: "Identificação do Comprador",
                  bold: true,
                  margin: [0, 10, 0, 10],
                },
                { text: "Nome:", margin: [0, 0, 0, 5] },
                { text: "CPF:", margin: [0, 0, 0, 5] },
                { text: "Endereço:", margin: [0, 0, 0, 5] },
                { text: "Cidade e UF:", margin: [0, 0, 0, 5] },
                { text: "Telefone:", margin: [0, 0, 0, 5] },
              ],
            },
            {
              margin: [10, 10, 10, 10],
              border: [false, false, false, false],
              stack: [
                {
                  text: `Identificação do Fornece`,
                  bold: true,
                  margin: [0, 10, 0, 10],
                },
                { text: "Data: ___/___/_____", bold: true },
                { text: "" },
                {
                  style: "assinatura",
                  table: {
                    widths: "*",
                    heights: 15,
                    body: [
                      [
                        {
                          text: "",
                          border: [false, false, false, true],
                        },
                        {
                          text: "",
                          border: [false, false, false, true],
                        },
                        {
                          text: "",
                          border: [false, false, false, true],
                        },
                        {
                          text: "",
                          border: [false, false, false, true],
                        },
                        {
                          text: "",
                          border: [false, false, false, true],
                        },
                      ],
                      [
                        {
                          text: "",
                          border: [false, false, false, false],
                        },
                        {
                          colSpan: 3,
                          text: "Assinatura",
                          alignment: "center",
                          border: [false, false, false, false],
                        },
                        {
                          text: "",
                          border: [false, false, false, false],
                        },
                        {
                          text: "",
                          border: [false, false, false, false],
                        },
                        {
                          text: "",
                          border: [false, false, false, false],
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        ],
      },
    },
  ]);

  let modelo = {
    content: content,
    styles: {
      "text-header": {
        fontSize: 8,
      },
      title: {
        fontSize: 13,
        color: "#8a8a8a",
        bold: true,
        alignment: "center",
        margin: [0, 20, 0, 10],
      },
      text: {
        margin: [0, 0, 0, 10],
      },
      "text-obs": {
        margin: [0, 0, 0, 0],
      },
      "text-date": {
        alignment: "right",
        margin: [0, 0, 0, 10],
      },
      "title-testemunha": {
        fontSize: 10,
        bold: true,
        alignment: "center",
        margin: [0, 20, 0, 30],
      },
      table: {
        margin: [0, 40, 0, 0],
      },
      "table-text": {
        margin: [0, 0, 0, 10],
      },
      "table-obs": {
        margin: [0, 10, 0, 5],
      },
      assinatura: {
        margin: [0, 10, 0, 0],
      },
      "text-info": {
        margin: [0, 0, 0, 0],
      },
      "table-info-geral": {
        margin: [0, 10, 0, 10],
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 9,
    },
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          { width: 20, text: `` },
          {
            width: "*",
            text: {
              text: `Receita gerada ${DataHj()} por ${usuario}`,
              fontSize: 6,
            },
          },
          {
            width: "*",
            text: {
              text: "Página " + currentPage.toString() + " de " + pageCount,
              alignment: "right",
              fontSize: 6,
            },
          },
          { width: 20, text: "" },
        ],
        margin: [0, 10, 0, 0],
      };
    },
  };
  return modelo;
}

function getEndereco(item) {
  return `${item.logradouro},${item.numero} | ${item.cidade} - ${item.cep}`;
}

function getEnderecoResponsavel(item) {
  return `${item.logradouro},${item.numero} | ${item.cidade} - ${item.cep}`;
}

function getListMedicamento(medicamento) {
  let listMed = [
    [
      {
        text: "Medicamento",
        border: [false, false, false, true],
      },
      {
        text: "Quantidade",
        border: [false, false, false, true],
      },
      {
        text: "Posologia",
        border: [false, false, false, true],
      },
    ],
    [
      {
        text: "",
        border: [false, false, false, false],
      },
      {
        text: "",
        border: [false, false, false, false],
      },
      {
        text: "",
        border: [false, false, false, false],
      },
    ],
  ];
  if (medicamento && medicamento.length > 0) {
    for (let index = 0; index < medicamento.length; index++) {
      const element = medicamento[index];
      listMed.push([
        { text: element.medicamento, border: [false, false, false, false] },
        { text: element.quantidade, border: [false, false, false, false] },
        { text: element.posologia, border: [false, false, false, false] },
      ]);
    }
  }

  return listMed;
}
