import { DataHoraHj, LogoHeader } from "./utils_docs";

export function AutorizoGeral(animal, responsavel, unidade) {
  let content = [];

  const dateNow = DataHoraHj();

  content.push({
    columns: [
      {
        stack: [
          {
            image: LogoHeader(unidade.id),
            width: 55,
          },
        ],
        alignment: "left",
        border: [false, false, false, false],
      },
      {
        type: "none",
        ol: [
          { text: "Unidade", style: "text-header", bold: true },
          { text: unidade.razao_social, style: "text-header" },
          { text: "Endereço", style: "text-header", bold: true },
          { text: getEndereco(unidade), style: "text-header" },
        ],
      },
    ],
  });

  // Perguntas
  content.push([
    {
      text: `Termo de consentimento livre e esclarecido para internação, exames e/ou procedimentos clínicos, ambulatoriais, anestésicos, terapêuticos e/ou cirúrgicos`,
      style: "title",
    },
    {
      text: `Eu, ${responsavel.nomeResp}, portador(a) do CPF ${
        responsavel.cpfResp
      }, representando ${responsavel.nome} do CNPJ ${
        responsavel.cnpj
      }, localizado ${getEnderecoResponsavel(
        responsavel
      )}, na condição de responsável e responsável pelo animal de nome ${
        animal.nome
      }, classe ${animal.classe}, espécie ${animal.especie}, sexo ${
        animal.sexo
      }, cuja realização será feita pelo ${unidade.razao_social}.`,
      style: "text",
    },
    // {
    //   text: `Eu, ${responsavel.nomeResp}, portador(a) do CPF ${
    //     responsavel.cpfResp
    //   }, representando ${responsavel.nome} do CNPJ ${
    //     responsavel.cnpj
    //   }, localizado ${getEnderecoResponsavel(responsavel)}, do telefone n° ${
    //     responsavel.telefone
    //   }, na condição de responsável e responsável pelo animal de nome ${
    //     animal.nome
    //   }, classe ${animal.classe}, espécie ${animal.especie}, sexo ${
    //     animal.sexo
    //   }, cuja realização será feita pelo ${unidade.razao_social}.`,
    //   style: "text",
    // },
    {
      text: `Declaro, ainda, ter sido esclarecido sobre o(s) exame(s) e/ ou procedimento(s) clínico(s), ambulatorial(is), anestésico(s), terapêutico(s) e/ou cirúrgico(s) indicado(s), os possíveis riscos inerentes à situação clínica do animal, bem como do(s)
tratamento(s) proposto(s), estando o(a) Médico(a) Veterinário(a) e o ${unidade.razao_social}. isento(a) de quaisquer responsabilidades decorrentes de tais riscos, estando ciente de todas as informações. Em caso de emergência, autorizo e
dou autonomia ao Médico(a) Vet erinário(a) para adotar todos os procedimentos destinados a restabelecer o quadro clínico do animal.`,
      style: "text",
    },
    {
      text: `Assumo a responsabilidade total pelos procedimentos pós-cirúrgicos, tais como: curativos, uso de colar elizabetano e/ou roupas cirúrgicas, comprometendo-me a comunicar os profissionais da equipe caso haja qualquer complicação e/ ou alteração no quadro do Animal. Por fim, após o paciente ter recebido alta médica com a consecutiva comunicação do fato, fico ciente de que transcorrido 1 (um) dia e não havendo a retirada do Animal do estabeleciment o dentro do aludido prazo, será considerado abandono, sendo o Animal encaminhado para adoção, sem o prejuízo das penas legais cabíveis. Com relação aos procedimentos pós-cirúrgicos, reconheço não haver o que se possa reclamar em qualquer esfera administrativa e/ ou jurídica contra o Médico(a) Veterinário(a) e/ou ao ${unidade.razao_social}. Atesto, ainda, estar ciente de que o ${unidade.razao_social} não se responsabiliza por itens pessoais ou do animal deixados na internação, e que fui orientado a não deixar tais itens junto ao animal no ambient e hospitalar.`,
      style: "text-obs",
    },
    {
      style: "table-obs",
      table: {
        widths: ["auto", "*"],
        heights: 10,
        body: [
          [
            {
              text: "Observações:",
              border: [false, false, false, false],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
          ],
          [
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
          ],
        ],
      },
    },
    {
      text: `Ordem de não reanimação: ( ) Sim ( ) Não`,
      style: "text",
    },
    {
      text: `As informações contidas neste documento foram explicadas detalhadamente e em linguagem acessível e de fácil compreensão. Sendo assim, assino abaixo.`,
      style: "text",
    },
    {
      text: `${unidade.razao_social}, ${dateNow}`,
      style: "text-date",
    },
    {
      style: "table",
      table: {
        widths: "*",
        body: [
          [
            {
              text: "",
              border: [false, false, false, false],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, false],
            },
          ],
          [
            {
              text: "",
              border: [false, false, false, false],
            },
            {
              text: `Assinatura do(a) responsável`,
              colSpan: 3,
              alignment: "center",
              border: [false, false, false, false],
            },
            {
              text: "",
            },
            {
              text: "",
            },
            {
              text: "",
              border: [false, false, false, false],
            },
          ],
        ],
      },
    },
    {
      text: "Testemunhas",
      style: "title-testemunha",
    },
    {
      columns: [
        {
          table: {
            widths: "*",
            body: [
              [
                {
                  text: "Nome:",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "CPF:",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "Assinatura :",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: "*",
            body: [
              [
                {
                  text: "Nome:",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "CPF:",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "Assinatura :",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
      ],
    },
  ]);

  let modelo = {
    content: content,
    styles: {
      "text-header": {
        fontSize: 8,
      },
      title: {
        fontSize: 13,
        color: "#8a8a8a",
        bold: true,
        alignment: "center",
        margin: [0, 20, 0, 10],
      },
      text: {
        margin: [0, 0, 0, 10],
      },
      "text-obs": {
        margin: [0, 0, 0, 0],
      },
      "text-date": {
        alignment: "right",
        margin: [0, 0, 0, 10],
      },
      "title-testemunha": {
        fontSize: 10,
        bold: true,
        alignment: "center",
        margin: [0, 20, 0, 30],
      },
      table: {
        margin: [0, 40, 0, 0],
      },
      "table-text": {
        margin: [0, 0, 0, 10],
      },
      "table-obs": {
        margin: [0, 10, 0, 5],
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 9,
    },
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          { width: 20, text: "" },
          {
            width: "*",
            text: {
              // text: `Anamnese gerada por ${usuario}`,
              text: ``,
              fontSize: 6,
            },
          },
          {
            width: "*",
            text: {
              text: "Página " + currentPage.toString() + " de " + pageCount,
              alignment: "right",
              fontSize: 6,
            },
          },
          { width: 20, text: "" },
        ],
        margin: [0, 10, 0, 0],
      };
    },
  };
  return modelo;
}

function getEndereco(item) {
  return `${item.logradouro},${item.numero} | ${item.cidade} - ${item.cep}`;
}

function getEnderecoResponsavel(item) {
  return `${item.logradouro},${item.numero} | ${item.cidade} - ${item.cep}`;
}
