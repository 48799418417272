<template>
  <v-card>
    <v-toolbar style="background-color: #7735b9; color: #fff">
      <v-toolbar-title>
        <span class="headline">Receitas</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-tabs v-model="tab" background-color="transparent" color="#7735b9" grow>
      <v-tab key="receita"> Receita </v-tab>
      <v-tab key="receita-carbonara"> Receita Carbonara </v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-items v-model="tab">
        <v-tab-item key="receita">
          <v-card color="basil" flat>
            <v-card-text>
              <div class="container-fluid mb-0">
                <div class="row">
                  <div class="col-12 text-end">
                    <v-btn
                      depressed
                      color="#7735b9"
                      :disabled="!validReceita()"
                      :dark="validReceita()"
                      @click="formatEdit()"
                    >
                      Gerar Receita
                    </v-btn>
                  </div>
                </div>
              </div>
              <div class="new-edit">
                <vue-editor
                  v-model="content"
                  :editorToolbar="toolbar"
                ></vue-editor>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item key="receita-carbonara">
          <v-card flat>
            <v-card-text>
              <div class="container-fluid mb-0">
                <div class="row">
                  <div class="col-12 text-end">
                    <v-btn
                      color="#7735b9"
                      @click="gerarReceitaCarbonara()"
                      :dark="listMed.length > 0"
                      :disabled="listMed.length == 0"
                    >
                      Gerar documento
                    </v-btn>
                  </div>
                </div>
              </div>
              <div class="container-fluid my-5 card-medicamento">
                <h5 class="title-card">Medicamento</h5>
                <div class="row justify-content-center align-vertical-center">
                  <div class="col-3">
                    <v-select
                      v-model="inputMed"
                      :items="medicamentos"
                      :loading="isLoading"
                      item-text="nome"
                      label="Medicamento"
                      return-object
                      hide-details
                    ></v-select>
                  </div>
                  <div class="col-3">
                    <v-text-field
                      v-model="qntd"
                      hide-details
                      label="Quantidade"
                    ></v-text-field>
                  </div>
                  <div class="col-3">
                    <v-text-field
                      v-model="dosagem"
                      hide-details
                      label="Posologia"
                    ></v-text-field>
                  </div>
                  <div class="col-1 style-btn">
                    <v-btn
                      x-small
                      fab
                      dark
                      small
                      elevation="2"
                      color="#0442b7"
                      title="Adicionar medicamento"
                      @click="addMedicamento()"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <v-simple-table height="300px" dense fixed-header>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Medicamento</th>
                            <th class="text-left">Quantidade</th>
                            <th class="text-left">Posologia</th>
                            <th class="text-center">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(med, index) in listMed"
                            :key="med.medicamento"
                          >
                            <td class="text-left">{{ med.medicamento }}</td>
                            <td class="text-left">{{ med.quantidade }}</td>
                            <td class="text-left">{{ med.posologia }}</td>
                            <td>
                              <v-btn
                                class="ml-2"
                                icon
                                color="#8e0808"
                                x-small
                                title="Detalhe Animal"
                                @click="removerMedicamento(index)"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import EntradaService from "@/services/entrada_service.js";
import MedicamentoService from "@/services/medicamento_service.js";
import { VueEditor } from "vue2-editor";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ReceitaCarbonara } from "@/components/documentos/modelos/receita_carbonara.js";
import { Receita } from "@/components/documentos/modelos/receita.js";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  mixins: [],
  props: {
    infoAnimal: Object,
  },
  components: { VueEditor },
  data: () => ({
    services: {
      medicamentoService: new MedicamentoService(),
      entradaService: EntradaService.build(),
    },
    content: "",
    contentFormat: "",
    isLoading: true,
    medicamentos: [],
    listMed: [],
    inputMed: {},
    medicamento: "",
    qntd: "",
    dosagem: "",
    tab: "",
    medicoMoc: {
      nome: "-- nome med --",
      crmv: "-- crmv med --",
    },
    animalMoc: {
      rgv: "-- num rgv --",
      nome: "-- nome animal --",
      classe: "-- classe --",
      especie: "-- especie --",
      sexo: "-- sexo --",
    },
    responsavelMoc: {
      nome: "-- nome orgão --",
      cnpj: "-- cnpj --",
      telefone: "-- telefone --",
      nomeResp: "-- nome Responsavel --",
      cpfResp: "-- cpf --",
      logradouro: "-- logradouro --",
      numero: "-- numero --",
      cidade: "-- cidade --",
      cep: "-- cep --",
    },
    unidadeMoc: {
      id: "-- id --",
      razao_social: "-- razao_social --",
      cnpj: "-- cnpj --",
      logradouro: "-- logradouro --",
      numero: "-- numero --",
      cidade: "-- cidade --",
      cep: "-- cep --",
    },
    toolbar: [[]],
  }),
  watch: {
    inputMed() {
      this.receita_fill();
    },
    medicamento(e) {
      this.medicamento = e;
      console.log(this.medicamento);
    },
    qntd(e) {
      this.qntd = e;
      console.log(this.qntd);
    },
    dosagem(e) {
      this.dosagem = e;
      console.log(this.dosagem);
    },
  },
  mounted() {
    this.loadMedicamentos();
  },
  computed: {},
  methods: {
    loadMedicamentos() {
      this.isLoading = true;
      this.services.medicamentoService.getList({
        onSucess: (status) => (body) => {
          if (status === 200) {
            this.medicamentos = body.data.medicamentos;
          }
        },
        onError: (error) => {
          console.log(error);
        },
        onEnd: () => {
          this.isLoading = false;
        },
      });
    },
    removerMedicamento(index) {
      this.listMed.splice(index, 1);
    },
    addMedicamento() {
      for (let index = 0; index < 20; index++) {
        this.listMed.push({
          medicamento: this.medicamento,
          quantidade: this.qntd,
          posologia: this.dosagem,
        });
      }
      this.medicamento = "";
      this.qntd = "";
      this.dosagem = "";
      this.inputMed = {};
    },
    receita_fill() {
      this.dosagem = this.inputMed.posologia;
      this.qntd = this.inputMed.quantidade;
      this.medicamento = this.inputMed.nome;
    },
    gerarReceitaCarbonara() {
      const docArquivo = ReceitaCarbonara(
        this.animalMoc,
        this.responsavelMoc,
        this.unidadeMoc,
        this.medicoMoc,
        this.listMed,
        JSON.parse(sessionStorage.getItem("vuex")).usuario.dados.nome
      );
      const descDoc = "Receita Carbonara.pdf";
      pdfMake.createPdf(docArquivo).download(descDoc);
      // this.$emit("emitirSaida");
    },
    validReceita() {
      return this.content == "" ? false : true;
    },
    formatEdit() {
      let text = this.content
        .replaceAll("<p>", "")
        .replaceAll("<br>", "")
        .split("</p>");
      this.contentFormat = [];
      if (text.length > 0) {
        for (let index = 0; index < text.length; index++) {
          const element = text[index];
          if (index != text.length - 1 && element != "") {
            this.contentFormat.push({
              text: element,
              style: "text",
            });
          }
        }
        this.gerarReceita();
      }
    },
    gerarReceita() {
      const docArquivo = Receita(
        this.animalMoc,
        this.responsavelMoc,
        this.unidadeMoc,
        this.medicoMoc,
        this.contentFormat,
        JSON.parse(sessionStorage.getItem("vuex")).usuario.dados.nome
      );
      const descDoc = "Receita Carbonara.pdf";
      pdfMake.createPdf(docArquivo).download(descDoc);
      // this.$emit("emitirSaida");
    },
  },
};
</script>
<style scoped>
.card-medicamento {
  border: 2px solid #e1e1e1;
  border-radius: 10px !important;
  padding: 20px 20px;
  position: relative;
}
.style-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-card {
  position: absolute;
  top: -20px;
  background-color: white;
  padding: 0px 10px;
  color: #5b5b5b;
}
</style>
<style>
.new-edit .ql-toolbar {
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
}
.new-edit .ql-editor {
  height: 400px;
  /* max-height: 400px; */
}
</style>
