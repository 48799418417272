import { render, staticRenderFns } from "./cadastro_novo_supervisor.vue?vue&type=template&id=657fe076&scoped=true"
import script from "./cadastro_novo_supervisor.vue?vue&type=script&lang=js"
export * from "./cadastro_novo_supervisor.vue?vue&type=script&lang=js"
import style0 from "./cadastro_novo_supervisor.vue?vue&type=style&index=0&id=657fe076&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "657fe076",
  null
  
)

export default component.exports