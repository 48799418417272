import { mapActions, mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(
            {
                $_GETTERS_token: 'getToken',
            }
        ),
    },
    methods: {
        ...mapActions({
            $_ACTIONS_setToken: 'setToken',
        }),
    }
}