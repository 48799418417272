<template>
  <v-card>
    <v-toolbar style="background-color: #0e3d79; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Editar Região Administrativa</span>
      </v-toolbar-title>
    </v-toolbar>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-10">
          <v-text-field v-model="inputRegiao" label="Região" hide-details />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-10">
          <v-text-field v-model="inputCidade" label="Cidade" hide-details />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-10 d-flex justify-content-end align-items-end">
          <v-btn color="#0e3d79" dark @click="editarRegiao()"> Editar </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import RegiaoAdministrativaService from "@/services/regiao_administrativa_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  components: {},
  props: {
    regiao: Object,
  },
  data: () => ({
    services: {
      regiaoAdministrativaService: RegiaoAdministrativaService.build(),
    },
    inputRegiao: "",
    inputCidade: "",
  }),
  watch: {},
  async mounted() {
    this.inputRegiao = this.regiao.regiao;
    this.inputCidade = this.regiao.cidade;
  },
  computed: {},
  methods: {
    editarRegiao() {
      const model = {
        id: this.regiao.id,
        ra: this.inputRegiao,
        cidade: this.inputCidade,
      };
      this.services.regiaoAdministrativaService
        .putEditarRegiao(model)
        .then(async (response) => {
          console.log("resp", response);
          if (response.status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Região cadastrada com sucesso!",
              color: "sucess",
            });
            this.$emit("concluido");
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao cadastrar",
              color: "error",
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped></style>
