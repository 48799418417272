import HTTPService from "@/services/http_service.js";

export default class ViaCepService extends HTTPService {
  static build() {
    return new ViaCepService();
  }

  buscarCep(cep) {
    return fetch(`https://viacep.com.br/ws/${cep}/json`, {
      method: "GET",
      redirect: "follow",
    });
  }

  async getRegiaoAdmin() {
    return await this.get(`regiao-administrativa`);
  }
}
