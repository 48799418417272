<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <v-btn color="#1daf80" dark class="my-2" @click="novaEspecie()">
          Nova Espécie
        </v-btn>
      </div>
    </div>
    <div class="p-lg-1">
      <v-data-table
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
        :headers="header"
        :items="especies"
        class="elevation-1"
        :loading="loading"
        :loading-text="'Carregando...'"
      >
        <template v-slot:item.status="{ item }">
          <div v-if="item.status == 1" style="color: green">Ativo</div>
          <div v-else style="color: red">Inativo</div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="editItem(item)"> mdi-pencil </v-icon>
        </template>
        <template v-slot:no-data>
          <p class="text-center">Nenhum registro encontrado</p>
        </template>
      </v-data-table>
      <!-- Cadastro -->
      <v-dialog
        persistent
        max-width="500px"
        v-if="dialogCadastroEspecie"
        v-model="dialogCadastroEspecie"
      >
        <modal-cadastro-especie
          v-if="dialogCadastroEspecie"
          @close="dialogCadastroEspecie = false"
          @response="(dialogCadastroEspecie = false), getEspecieList()"
        />
      </v-dialog>
      <!-- Edição -->
      <v-dialog
        persistent
        max-width="500px"
        v-if="dialogEditEspecie"
        v-model="dialogEditEspecie"
      >
        <modal-edit-especie
          v-if="dialogEditEspecie"
          :especie="especieEdit"
          @close="dialogEditEspecie = false"
          @response="(dialogEditEspecie = false), getEspecieList()"
        />
      </v-dialog>
      <!-- Delete -->
      <!-- <v-dialog
        persistent
        max-width="500px"
        v-if="dialogDeleteClasse"
        v-model="dialogDeleteClasse"
      >
        <modal-delete-classe
          v-if="dialogDeleteClasse"
          :classe="classeEdit"
          @close="dialogDeleteClasse = false"
          @response="getClasseList($event)"
        />
      </v-dialog> -->
    </div>
  </div>
</template>

<script>
import ClassificacaoService from "@/services/classificacao_service.js";
import ModalCadastroEspecie from "@/components/classificacao/especie/cadastro.vue";
import ModalEditEspecie from "@/components/classificacao/especie/editar.vue";

export default {
  mixins: [],
  components: {
    ModalEditEspecie,
    ModalCadastroEspecie,
  },
  data: () => ({
    services: {
      classificacaoService: ClassificacaoService.build(),
    },
    header: [
      {
        text: "Classe",
        align: "start",
        sortable: false,
        value: "classe",
      },
      {
        text: "Família",
        align: "start",
        sortable: false,
        value: "familia",
      },
      {
        text: "Científico",
        align: "start",
        sortable: false,
        value: "nome_cientifico",
      },
      {
        text: "Popular",
        align: "start",
        sortable: false,
        value: "nome_popular",
      },
      {
        text: "Status",
        align: "center",
        class: "classe-style-status",
        sortable: false,
        value: "status",
      },
      {
        text: "Ações",
        align: "center",
        class: "classe-style-status",
        value: "actions",
        sortable: false,
      },
    ],
    loading: false,
    especies: [],
    especieEdit: {},
    dialogCadastroEspecie: false,
    dialogEditEspecie: false,
    dialogDeleteEspecie: false,
  }),
  watch: {},
  mounted() {
    this.getEspecieList();
  },
  computed: {},
  methods: {
    getEspecieList() {
      this.loading = true;
      this.classes = [];
      this.services.classificacaoService
        .getEspecieList()
        .then(async (response) => {
          if (response.status == 200) {
            const body = await response.json();
            this.especies = body.data[0];
          } else {
            console.error(response);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    novaEspecie() {
      this.dialogCadastroEspecie = true;
    },
    editItem(item) {
      this.especieEdit = item;
      this.dialogEditEspecie = true;
    },
  },
};
</script>
<style>
.classe-style-status {
  width: 100px !important;
}
</style>
