<template>
  <v-dialog v-model="enabled" max-width="290">
    <v-card>
      <v-card-title class="headline">
        <h3 class="text-break">
          Tem certeza que deseja mover paciente para lista de atrasados?
        </h3>
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="cancelar"> Cancelar </v-btn>

        <v-btn
            color="red darken-2"
            dark
            @click="confirmar"
        >
          Mover
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    enabled: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    cancelar() {
      this.$emit('cancel')
    },
    confirmar() {
      this.$emit('confirm')
    }
  }
}
</script>