<template>
  <card-compromisso
      :hora-inicial="horaInicial"
      :hora-final="horaFinal"
      :nome-paciente="nomePaciente"
      :procedimento="procedimento"
      :cor="'#1186D9'"
  />
</template>

<script>
import CardCompromisso from "@/components/dashboards/dashboard_medico/compromissos/templates/card_compromisso.vue";
export default {
  components: {CardCompromisso},
  props: {
    procedimento: {type: String, required: true},
    nomePaciente: {type: String, required: true},
    horaInicial: {type: String, required: true, validator: str => /^\d{2}:\d{2}$/.test(str)},
    horaFinal: {type: String, required: true, validator: str => /^\d{2}:\d{2}$/.test(str)},
  }
}
</script>