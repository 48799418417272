<template>
  <v-card>
    <v-toolbar style="background-color: #006368; color: #fff">
      <v-toolbar-title>
        <span class="headline mr-3">Mapa Prescricao Animal</span>
        <span
          ><strong>Entrada</strong>:
          {{
            internacaoInfo.data_entrada
              ? internacaoInfo.data_entrada.split("-").reverse().join("/")
              : "-"
          }}
          | {{ internacaoInfo.hora_entrada ?? "-" }}</span
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="row">
                  <div class="col-2">
                    <v-skeleton-loader
                      v-if="!imagem.url"
                      v-bind="attrs"
                      type="image"
                      height="100px"
                      width="100px"
                      class="my-1"
                      style="
                        border-radius: 5px;
                        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
                      "
                    ></v-skeleton-loader>
                    <v-template v-else>
                      <!-- {{ item.url }} -->
                      <v-img
                        v-if="imagem.url == 'sem-imagem'"
                        src="@/assets/images/default_image.jpg"
                        height="100px"
                        width="100px"
                        class="my-1"
                        style="
                          border-radius: 5px;
                          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
                        "
                      >
                      </v-img>
                      <v-img
                        v-else
                        :src="imagem.url"
                        height="100px"
                        width="100px"
                        class="my-1"
                        style="
                          border-radius: 5px;
                          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
                        "
                      >
                      </v-img>
                    </v-template>
                  </div>
                  <div class="col-5 align-header">
                    <div>
                      <span
                        ><strong>Animal</strong>:
                        {{ internacaoInfo.animal_nome ?? "-" }}</span
                      >
                    </div>
                    <span
                      ><strong>RGV</strong>:
                      {{ internacaoInfo.rgv ?? "-" }}</span
                    >
                  </div>
                  <div class="col-5 align-header">
                    <div>
                      <span
                        ><strong>Classificação</strong>:
                        {{ internacaoInfo.classe ?? "-" }} |
                        {{ internacaoInfo.familia ?? "-" }} |
                        {{ internacaoInfo.nome_popular ?? "-" }}</span
                      >
                    </div>
                    <div>
                      <span
                        ><strong>Peso</strong>:
                        {{
                          internacaoInfo.peso ? internacaoInfo.peso + "kg" : "-"
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="container">
                  <div class="row text-left">
                    <div class="col-6">
                      <div class="mb-5">
                        <p><strong>Alimentação</strong>:</p>
                        <div class="textarea-info">
                          {{ internacaoInfo.alimentacao ?? "-" }}
                        </div>
                      </div>
                      <div>
                        <p><strong>Metas</strong>:</p>
                        <div class="textarea-info">
                          {{ internacaoInfo.metas ?? "-" }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="mb-5">
                        <p><strong>Protocolo Medicação</strong>:</p>
                        <div class="textarea-info">
                          {{ internacaoInfo.prot_medicacao ?? "-" }}
                        </div>
                      </div>
                      <div>
                        <p><strong>Evolução</strong>:</p>
                        <div class="textarea-info">
                          {{ internacaoInfo.evolucao ?? "-" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
      <div class="row">
        <div class="container-fluid mt-0 mb-5">
          <div class="row justify-content-between">
            <div class="col-6 text-left">
              <v-btn
                color="#006368"
                class="mr-2 mt-4"
                :dark="perfilId != 1"
                @click="novaPrescricao()"
                :disabled="perfilId == 1"
              >
                Nova Prescrição
              </v-btn>
              <v-btn class="mt-4" color="#1d9b03" dark @click="verGaleria()">
                Galería Internação
              </v-btn>
              <!-- <v-btn class="mt-4" color="#79540e" dark @click="novoPeso()">
                Atualizar Peso
              </v-btn> -->
            </div>
            <v-spacer></v-spacer>
            <div class="col-1 text-right">
              <v-btn
                class="mt-4"
                fab
                color="primary"
                elevation="1"
                x-small
                title="Saída Internação"
                @click="getPrescricao()"
              >
                <v-icon>mdi mdi-refresh</v-icon>
              </v-btn>
            </div>
            <div class="col-2">
              <v-text-field
                v-model="data_prescricao"
                label="Data"
                type="date"
                hide-details
              />
            </div>
          </div>
        </div>
        <div v-if="!loading" class="container-fluid mt-0 mb-5">
          <div class="mapa" v-if="agendas.length > 0">
            <div class="prescricao">
              <div class="agenda-item">
                <div class="info-animal style-header"></div>
                <div class="info-horarios">
                  <div
                    class="horario style-header-time"
                    v-for="(horario, index) in horarios"
                    :key="index + horario"
                  >
                    <h6>{{ horario.split(":")[0] }}</h6>
                  </div>
                </div>
              </div>
              <div
                class="agenda-item"
                v-for="(item, index) in agendas"
                :key="index + '-agenda-' + index"
              >
                <div class="info-animal">
                  <div class="nome-animal">
                    <v-btn
                      icon
                      x-small
                      color="#ff0000"
                      title="Cancelar prescrição"
                      @click="cancelarPrescricao(item)"
                      class="mr-1"
                      :dark="perfilId != 1"
                      :disabled="perfilId == 1"
                    >
                      <v-icon>mdi mdi-calendar-remove</v-icon>
                    </v-btn>
                    <span>
                      {{ item.medicamento }}
                    </span>
                  </div>
                  <div class="desc-animal">
                    Dosagem: {{ item.dosagem }}<br />
                    Administração: {{ item.administracao_medicamento }}
                  </div>
                </div>
                <div class="info-horarios">
                  <div
                    class="horario"
                    v-for="horario in item.agenda"
                    :key="item.id_medicamento + '-agenda-' + horario.hora"
                  >
                    <div v-if="horario.compromisso.id_prescricao">
                      <div>
                        <v-btn
                          icon
                          x-small
                          class="horaItem"
                          :class="getColorMedicamento(horario.compromisso)"
                          title="Medicamento"
                          dark
                          @click="medicamento(item, horario.compromisso)"
                        >
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <h4 class="text-center">Nenhum prescrição para essa internação</h4>
          </div>
        </div>
        <div class="text-center m-5 col-12" v-else>
          <v-progress-circular
            indeterminate
            color="#006368"
          ></v-progress-circular>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="dialogExecutarMedicamento"
      width="1000"
      transition="dialog-bottom-transition"
    >
      <ModalMedicamentoInterno
        v-if="dialogExecutarMedicamento"
        :info="infoMedicamento"
        @close="
          getPrescricao();
          dialogExecutarMedicamento = false;
        "
      ></ModalMedicamentoInterno>
    </v-dialog>
    <v-dialog
      v-model="dialogPrescricao"
      width="1100"
      transition="dialog-bottom-transition"
    >
      <ModalNovaPrescricao
        v-if="dialogPrescricao"
        :infoInternacao="internacaoInfo"
        @close="dialogPrescricao = false"
        @concluido="
          getPrescricao();
          dialogPrescricao = false;
        "
      ></ModalNovaPrescricao>
    </v-dialog>
    <v-dialog
      v-model="dialogPeso"
      width="700"
      transition="dialog-bottom-transition"
    >
      <ModalAtualizarPeso
        v-if="dialogPeso"
        :infoAnimal="internacaoInfo"
        @close="dialogPeso = false"
        @concluido="
          getPrescricao();
          dialogPeso = false;
        "
      ></ModalAtualizarPeso>
    </v-dialog>
    <v-dialog
      v-model="dialogCancelarPrescricao"
      width="700"
      transition="dialog-bottom-transition"
    >
      <ModalCancelarPrescricao
        v-if="dialogCancelarPrescricao"
        :info="infoCancelamento"
        @close="
          dialogCancelarPrescricao = false;
          infoCancelamento = {};
        "
        @concluido="
          getPrescricao();
          dialogCancelarPrescricao = false;
          infoCancelamento = {};
        "
      ></ModalCancelarPrescricao> </v-dialog
    ><!-- Modal Galeria -->
    <v-dialog
      v-model="dialogGaleria"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <ModalGaleriaInternacao
        v-if="dialogGaleria"
        :internacaoInfo="info"
        @close="() => (dialogGaleria = false)"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import ModalMedicamentoInterno from "@/components/mapa_prescricao/modal_medicacao_interno.vue";
import ModalAtualizarPeso from "@/components/mapa_prescricao/modal_atualizar_peso.vue";
import ModalCancelarPrescricao from "@/components/mapa_prescricao/modal_excluir_prescricao.vue";
import ModalNovaPrescricao from "@/components/mapa_prescricao/criar_prescricao.vue";
import PrescricaoService from "@/services/prescricao_service.js";
import InternacaoService from "@/services/internacao_service.js";
import EntradaService from "@/services/entrada_service.js";
import ModalGaleriaInternacao from "@/components/animal/galeria_internacao/modal_galeria";

export default {
  mixins: [Mixin],
  components: {
    ModalMedicamentoInterno,
    ModalAtualizarPeso,
    ModalNovaPrescricao,
    ModalCancelarPrescricao,
    ModalGaleriaInternacao,
  },
  props: {
    info: {
      required: true,
    },
    dataMapa: {
      required: false,
    },
  },
  data: () => ({
    services: {
      // authService: AuthService.build(),
      // authService: AuthService.build(),
      prescricaoService: PrescricaoService.build(),
      internacaoService: InternacaoService.build(),
      entradaService: EntradaService.build(),
    },
    horarios: [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ],
    agendas: [],
    dataHj: new Date(),
    dialogExecutarMedicamento: false,
    dialogPrescricao: false,
    dialogPeso: false,
    infoInternacao: {},
    infoMedicamento: {},
    unidadeId: JSON.parse(sessionStorage.vuex).unidade.id,
    loading: false,
    internacaoInfo: {},
    data_prescricao: "",
    infoCancelamento: {},
    dialogCancelarPrescricao: false,
    imagem: { url: "" },
    dialogGaleria: false,
    perfilId: "",
  }),

  computed: {},

  watch: {
    data_prescricao() {
      if (this.data_prescricao) {
        this.getPrescricao();
      }
    },
  },

  mounted() {
    this.perfilId = JSON.parse(sessionStorage.vuex).perfil.id;
    this.getInfoInternacao();
    if (this.dataMapa) {
      this.data_prescricao = this.dataMapa;
    } else {
      this.data_prescricao = new Date().toISOString().substr(0, 10);
    }
    this.getPrescricao();
    this.getThumbEntrada();
  },

  methods: {
    getPrescricao() {
      if (!this.loading) {
        this.agendas = [];
        this.loading = true;
        this.services.prescricaoService
          .getPrescricao(this.info.id, this.data_prescricao.replaceAll("-", ""))
          // .getPrescricao(1, "20240827")
          .then(async (response) => {
            if (response.status == 200) {
              const resp = await response.json();
              // this.internacaoInfo = resp.data.internacao;
              let medicamentos = this.getMedicamentosList(resp.data.dados);
              for (let index = 0; index < medicamentos.length; index++) {
                let medicacao = medicamentos[index];
                for (let indexH = 0; indexH < this.horarios.length; indexH++) {
                  medicacao.agenda.push(
                    this.getVacinasHora(
                      medicacao.compromisso,
                      this.horarios[indexH]
                    )
                  );
                }
                this.agendas.push(medicacao);
              }
              this.getInfoInternacao();
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getInfoInternacao() {
      this.services.internacaoService
        .getInternacaoDetalhe(this.info.id)
        .then(async (response) => {
          if (response.status == 200) {
            const resp = await response.json();
            this.internacaoInfo = resp.data;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getVacinasHora(compromissos, hora) {
      let agenda = { hora: hora, compromisso: {} };
      for (let i = 0; i < compromissos.length; i++) {
        const item = compromissos[i];
        if (item.hora_agenda.split(":")[0] == hora.split(":")[0]) {
          agenda.compromisso = item;
        }
      }
      return agenda;
    },
    getMedicamentosList(medicamentos) {
      let lista = [];
      for (let index = 0; index < medicamentos.length; index++) {
        const element = medicamentos[index];
        const indexMedicamento = lista.findIndex((ele) => {
          return ele.id_prescricao == element.id_prescricao;
          // return ele.id_medicamento == element.id_medicamento;
        });
        if (indexMedicamento > -1) {
          lista[indexMedicamento].compromisso.push({
            adm_medicamento: element.adm_medicamento,
            administracao_medicamento: element.administracao_medicamento,
            data_agenda: element.data_agenda,
            descricao_tipo: element.descricao_tipo,
            dosagem: element.dosagem,
            hora_agenda: element.hora_agenda,
            id_internacao: element.id_internacao,
            id_data_agenda: element.id_data_agenda,
            id_prescricao: element.id_prescricao,
            id_tipo_prescricao: element.id_tipo_prescricao,
            status_agenda: element.status_agenda,
            hora_executada:
              element.status_agenda == 2 ? element.hora_execucao : "",
            data_executada:
              element.status_agenda == 2 ? element.data_execucao : "",
            obs_executada: element.observacao ?? "",
            status: element.status_agenda == 2 ? "aplicado" : "pendente",
          });
        } else {
          lista.push({
            id_prescricao: element.id_prescricao,
            id_medicamento: element.id_medicamento,
            medicamento: element.medicamento,
            descricao_tipo: element.descricao_tipo,
            dosagem: element.dosagem,
            administracao_medicamento: element.administracao_medicamento,
            agenda: [],
            compromisso: [
              {
                adm_medicamento: element.adm_medicamento,
                administracao_medicamento: element.administracao_medicamento,
                data_agenda: element.data_agenda,
                descricao_tipo: element.descricao_tipo,
                dosagem: element.dosagem,
                hora_agenda: element.hora_agenda,
                id_internacao: element.id_internacao,
                id_prescricao: element.id_prescricao,
                id_tipo_prescricao: element.id_tipo_prescricao,
                id_data_agenda: element.id_data_agenda,
                status_agenda: element.status_agenda,
                hora_executada:
                  element.status_agenda == 2 ? element.hora_execucao : "",
                data_executada:
                  element.status_agenda == 2 ? element.data_execucao : "",
                obs_executada: element.observacao ?? "",
                status: element.status_agenda == 2 ? "aplicado" : "pendente",
              },
            ],
          });
        }
      }
      return lista;
    },
    getColorMedicamento(compromisso) {
      let color = "aplicado";
      // let compromissoPassado =
      //   this.dataHj.getHours() > compromisso.hora_agenda.split(":")[0];
      let marcaoData = new Date(
        `${compromisso.data_agenda}T${compromisso.hora_agenda}`
      );
      let novaData = new Date();
      let compromissoPassado = marcaoData < novaData;
      if (compromisso.status == "pendente") {
        if (compromissoPassado) {
          color = "atrasado";
        } else {
          color = "pendente";
        }
      }
      return color;
    },
    medicamento(item, compromisso) {
      this.infoMedicamento = {
        id_medicamento: item.id_medicamento,
        medicamento: item.medicamento,
        compromisso: compromisso,
      };
      this.dialogExecutarMedicamento = true;
    },
    cancelarPrescricao(item) {
      this.infoCancelamento = item;
      this.dialogCancelarPrescricao = true;
    },
    novaPrescricao() {
      this.dialogPrescricao = true;
    },
    novoPeso() {
      this.infoInternacao = this.internacaoInfo;
      this.dialogPeso = true;
    },
    async getThumbEntrada() {
      await this.services.entradaService
        .getFirstImagemEntrada(this.info.id_entrada)
        .then(async (response) => {
          if (response.status == 200) {
            const resp = await response.json();
            // console.log("r", resp);
            const ext = resp.data.arquivo_nome.split(".");
            this.imagem.url = `data:image/${ext[1]};base64,${resp.data.base64}`;
          } else {
            this.imagem.url = "sem-imagem";
            // this.pets[index].url = "../assets/images/default_image.jpeg";
          }
        })
        .catch((e) => {
          console.log("erro ", { e });
        })
        .finally(() => {});
    },
    verGaleria() {
      this.dialogGaleria = true;
    },
  },
};
</script>
<style scoped>
.mapa {
  overflow: auto;
}
.agenda-item {
  display: flex;
}
.info-horarios {
  display: flex;
}
.info-animal {
  border: 1px solid rgba(0, 0, 0, 0.5);
  min-width: 80px;
  text-align: left;
  padding: 5px;
  flex: 1;
}
.nome-animal {
  font-size: 1rem;
  font-weight: bold;
  color: #3f3f3f;
  align-content: end;
  display: flex;
  padding: 5px;
}
.desc-animal {
  font-size: 0.75rem;
  font-weight: bold;
  color: #8a8a8a;
  text-transform: lowercase;
}
.horario {
  min-width: 34px;
  max-width: 34px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.horaItem {
  /* padding: 3px 8px;
  border-radius: 20px;
  font-size: smaller; */
  font-weight: bold;
}
.atrasado {
  background-color: rgb(176, 0, 0);
  color: white;
}
.atrasado:hover {
  background-color: rgb(204, 2, 2);
}
.pendente {
  background-color: #2c46cf;
  /* background-color: rgb(244, 115, 0); */
  color: white;
}
.pendente:hover {
  background-color: #546bdf;
  /* background-color: rgb(248, 134, 35); */
}
.aplicado {
  background-color: rgb(22, 185, 0);
  color: white;
}
.aplicado:hover {
  background-color: rgb(36, 204, 14);
}
.style-header {
  background-color: #006368;
  border-color: white;
}
.style-header-time {
  background-color: #006368;
  border-top-color: white;
  border-bottom-color: white;
  border-right-color: white;
  border-left-color: white;
}
.style-header-time h6 {
  color: white;
  font-weight: bold;
}
.textarea-info {
  height: 100px;
  overflow: auto;
}
.align-header {
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
}
</style>
