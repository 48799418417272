<template>
  <v-card
    class="ma-4 h-90"
    elevation="2"
    rounded
    max-width="500px"
    width="400px"
    hover
    :style="{ backgroundColor: cor }"
    @mouseover="$emit('mouseover')"
    @mouseout="$emit('mouseout')"
  >
    <v-card-title class="titulo-cards-fila" style="color: #fff">
      <span>{{ tipoProcedimento }}</span>
      <hr />
    </v-card-title>
    <v-card-text>
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="text-left nome-paciente" @click="mostraPerfilPaciente">
          {{ nomePaciente }}
        </h5>
        <h5 style="color: #fff">{{ horaInicial }}</h5>
      </div>

      <v-dialog
        v-model="dialog"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <cadastro-edicao-paciente v-if="dialog" :paciente="paciente_detalhe" :visualizar='true'  @close="dialog=false" />
      </v-dialog>
      <div class="d-flex justify-content-between align-items-center">
        <!-- <p
          class="descricao-procedimento "
          style="color: #fff"
          v-text="procedimento"
        > -->
        <!-- {{ procedimento }} -->
        <!-- </p> -->
        <v-list-item-subtitle
          class="descricao-procedimento text-left my-0"
          v-text="procedimento"
          style="color: #fff"
        ></v-list-item-subtitle>
        <h6 class="w-30 tempo-procedimento my-0 text-right" style="color: #fff">
          {{ horaInicial + " - " + horaFinal }}
        </h6>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import PacienteService from "@/services/paciente_service.js";
import CadastroEdicaoPaciente from "@/components/pacientes/cadastro_edicao_paciente.vue";

export default {
  components: {
    CadastroEdicaoPaciente,
  },

  props: {
    tipoProcedimento: { type: String, required: true },
    procedimento: { type: String, required: true },
    nomePaciente: { type: String, required: true },
    paciente_id: { type: String, required: true },
    horaInicial: {
      type: String,
      required: true,
      validator: (str) => /^\d{2}:\d{2}$/.test(str),
    },
    horaFinal: {
      type: String,
      required: true,
      validator: (str) => /^\d{2}:\d{2}$/.test(str),
    },
    cor: { type: String, required: true },
  },
  data: () => ({
    dialog: false,
    paciente: {},
    paciente_detalhe: {}
  }),

  methods: {
    mostraPerfilPaciente() {
      this.loadingPaciente(this.paciente_id)  
    },

    loadingPaciente(id) {
      this.paciente_detalhe = {}
      const pacienteService = new PacienteService();
      pacienteService.get(
        {
          onSucess: (status) => (body) => {
            if(status === 200){
              this.paciente_detalhe = body.data;
              this.dialog = true;
            }

            this.dialog = true;
          },
          onError: (error) => {
            this.messageSnackbar = error;
            this.typeMessageSnackbar = "error";
            console.log(error);
          },
          onEnd: () => {},
        },id
      );
    }
  }
};
</script>
<style scoped>
.titulo-cards-fila span {
  margin-right: 1rem;
  font-size: 1rem;
}

.titulo-cards-fila hr {
  display: block;
  width: 100%;
  color: #fff;
  margin: 0;
}

.tempo-procedimento {
  font-size: 0.8rem;
}

.descricao-procedimento {
  font-size: 0.8rem;
}

.h-98 {
  height: 90%;
}

.w-30 {
  width: 30%;
}

.nome-paciente {
  color: #fff;
}

.nome-paciente:hover {
  color: yellowgreen;
}


</style>