<template>
  <v-card>
    <v-card-title>
      <span class="headline">Edição de medicamentos</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="nome" label="Nome"></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12">
            <v-text-field v-model="quantidade" label="Quantidade"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="posologia" label="Posologia"></v-text-field>
          </v-col>
        </v-row> -->
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        class="btn-app-primary"
        style="color: #fff !important"
        :loading="loading"
        text
        @click="edit"
        :disabled="!allowEdition"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import MedicamentoService from "@/services/medicamento_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  mounted() {
    this.nome = this.medicamento.nome;
    this.posologia = this.medicamento.posologia;
    this.quantidade = this.medicamento.quantidade;
  },
  data: () => ({
    nome: "",
    posologia: "",
    quantidade: "",
    loading: false,
  }),
  props: {
    medicamento: {
      type: Object,
      required: true,
      validator: (obj) => {
        return obj.id && obj.nome && obj.posologia && obj.quantidade;
      },
    },
  },
  methods: {
    edit() {
      const medicamento = {};
      Object.assign(medicamento, this.medicamento);
      medicamento.nome = this.nome;
      medicamento.quantidade = "1 caixa";
      medicamento.posologia = "Tomar 1 por dia";
      // medicamento.quantidade = this.quantidade
      // medicamento.posologia = this.posologia
      // medicamento.alterado_por = this.$_GETTERS_usuario.id
      const medicamentoService = new MedicamentoService();
      this.loading = true;
      medicamentoService.updateNomeMedicamento(
        {
          onSucess: (status) => (body) => {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: status === 200 ? "sucess" : "error",
            });
          },
          onError: () => {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
            this.$emit("response", medicamento);
          },
        },
        medicamento
      );
    },
  },
  computed: {
    allowEdition() {
      return true;
    },
  },
};
</script>
