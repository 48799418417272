import HTTPService from "@/services/http_service.js";

export default class DashboardRelatorioService extends HTTPService {
  static build() {
    return new DashboardRelatorioService();
  }

  getRelatorioUnidadeAno(idUnidade, ano) {
    return this.get(`dashboard/unidade/atendimento/ano/${idUnidade}/${ano}`);
  }

  //   postModel(model) {
  //     return this.post("model", model);
  //   }
}
