<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cadastro de espécie</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="nome" label="Nome Científico" />
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="nome_pop" label="Nome Popular" />
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="especieId"
              :items="especieSelectList"
              label="Classe"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#3E682A" text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        color="#3E682A"
        :loading="loading"
        text
        @click="cadastrar"
        :disabled="!nome"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import RacaService from "../../services/raca_service";
import EspecieService from "../../services/especie_service";

export default {
  mixins: [Mixin],
  data: () => ({
    nome: "",
    nome_pop: "",
    especieId: 0,
    permissoes: [],
    loading: false,
    services: {
      racaService: RacaService.build(),
      especieService: EspecieService.build(),
    },
    especies: [],
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
  }),
  async mounted() {
    this.especies = await this.services.especieService
      .getEspeciesPaginatedList({
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        search: this.search,
        sort: this.options.ordenacao,
        desc: this.options.desc,
      })
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        return resp.data.especies;
      });

    console.log(this.especies);
  },
  computed: {
    especieSelectList() {
      return this.especies.map((e) => ({
        text: e.nome,
        value: e.id,
        disabled: false,
        divider: false,
        header: "",
      }));
    },
  },
  methods: {
    async cadastrar() {
      this.loading = true;
      const { nome, especieId, nome_pop } = this;
      const racaId = await this.services.racaService.cadastrarRaca({
        nome,
        nome_pop,
        especieId,
      });
      console.log({ racaId });
      this.loading = false;
      if (racaId) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Espécie cadastrada com sucesso.",
          color: "sucess",
        });
        this.$emit("response", { racaId, nome, especieId });
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Erro desconhecico no cadastro da espécie.",
          color: "error",
        });
      }
    },
  },
};
</script>
