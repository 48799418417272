<template>
  <v-card>
    <v-toolbar style="background-color: #79540e; color: #fff">
      <v-toolbar-title>
        <span class="headline">Atualizar Peso</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="container">
      <div class="row pb-2">
        <div class="col-4">
          <v-text-field
            v-model="inputPesoAtual"
            label="Peso atual"
            hide-details
          />
        </div>
        <div class="col-4">
          <v-text-field
            v-model="inputNovoPeso"
            label="Novo peso"
            hide-details
          />
        </div>
        <div class="col-4 d-flex justify-content-end align-items-end">
          <v-btn color="#79540e" dark @click="editarMarcacao()">
            Atualizar
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
// import EntradaDetalhe from "@/components/animal/entrada_detalhe.vue";
import EntradaService from "@/services/entrada_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  components: {},
  props: {
    infoAnimal: Object,
  },
  data: () => ({
    services: {
      entradaService: EntradaService.build(),
    },
    inputPesoAtual: "",
    inputNovoPeso: "",
  }),
  watch: {},
  async mounted() {
    this.inputPesoAtual = this.infoAnimal.peso;
  },
  computed: {},
  methods: {
    editarMarcacao() {
      const model = {
        id: this.infoAnimal.id,
        data: this.inputNovoPeso,
      };
      this.services.entradaService
        .updateMarcacao(model)
        .then(async (response) => {
          console.log("resp", response);
          if (response.status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Marcação atualizado com sucesso",
              color: "sucess",
            });
            this.$emit("marcacaoConcluida");
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao atualizar",
              color: "error",
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped></style>
