<template>
  <fila-compromisso :compromissos="cirurgias" :titulo="'Cirurgias'" message-on-empty="Não há nenhuma cirurgia marcada para hoje.">
    <template v-slot:card-compromisso="{ compromisso }">
      <card-cirurgia
          :hora-inicial="compromisso.horarioInicio"
          :hora-final="compromisso.horarioFinal"
          :nome-paciente="compromisso.nomePaciente"
          :procedimento="compromisso.procedimento"
      />
    </template>
  </fila-compromisso>
</template>

<script>
import FilaCompromisso from "@/components/dashboards/dashboard_medico/compromissos/templates/fila_compromisso";
import CardCirurgia from "@/components/dashboards/dashboard_medico/compromissos/card_cirurgia";
export default {
  components: { FilaCompromisso, CardCirurgia },
  props: {
    cirurgias: { type: Array, required: true }
  }
}
</script>