<template>
  <v-card>
    <v-toolbar style="background-color: #bd5000; color: #fff">
      <v-toolbar-title>
        <span class="headline">Nova Espécie</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="container">
        <div class="row">
          <div class="col-6">
            <v-select
              v-model="selectedClasse"
              :items="listClasse"
              item-text="descricao"
              item-value="id"
              label="Classe"
              hide-details
            />
          </div>
          <div class="col-6">
            <v-select
              v-model="selectedFamilia"
              :items="listFamilia"
              :disabled="listFamilia.length == 0"
              item-text="descricao"
              item-value="id"
              label="Família"
              hide-details
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <v-text-field
              v-model="descricaoPop"
              label="Descrição Popular"
              hide-details
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <v-text-field
              v-model="descricaoCientifica"
              label="Descrição Científico"
              hide-details
            />
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-3">
            <v-switch
              v-model="ativo"
              hide-details
              :label="ativo ? 'Ativo' : 'Inativo'"
            ></v-switch>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-content-between">
      <v-btn color="primary" outlined @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        color="#bd5000"
        :dark="
          descricaoPop != '' &&
          descricaoCientifica != '' &&
          selectedFamilia != ''
        "
        :disabled="
          descricaoPop == '' ||
          descricaoCientifica == '' ||
          selectedFamilia == ''
        "
        @click="novaFamilia()"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ClassificacaoService from "@/services/classificacao_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  props: {
    especie: Object,
  },
  components: {},
  data: () => ({
    services: {
      classificacaoService: ClassificacaoService.build(),
    },
    descricaoPop: "",
    descricaoCientifica: "",
    ativo: true,
    listClasse: [],
    selectedClasse: "",
    familias: [],
    listFamilia: [],
    selectedFamilia: "",
  }),
  watch: {
    selectedClasse() {
      this.selectedFamilia = "";
      if (this.selectedClasse != "") {
        this.listFamilia = [];
        for (let index = 0; index < this.familias.length; index++) {
          const element = this.familias[index];
          if (element.id_classe == this.selectedClasse) {
            this.listFamilia.push(element);
          }
        }
      }
    },
  },
  async mounted() {
    await this.listClasses();
    this.selectedClasse = this.especie.id_classe.toString();
    await this.listFamilias();
    this.descricaoPop = this.especie.nome_popular;
    this.descricaoCientifica = this.especie.nome_cientifico;
    this.ativo = this.especie.status == 1 ? true : false;
    this.selectedFamilia = this.especie.id_familia.toString();
  },
  computed: {},
  methods: {
    async listClasses() {
      this.services.classificacaoService
        .getClasseList(1)
        .then(async (response) => {
          const body = await response.json();
          this.listClasse = body.data[0];
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    async listFamilias() {
      this.services.classificacaoService
        .getFamiliaList(1)
        .then(async (response) => {
          const body = await response.json();
          this.familias = body.data[0];
          for (let index = 0; index < this.familias.length; index++) {
            const element = this.familias[index];
            if (element.id_classe == this.selectedClasse) {
              this.listFamilia.push(element);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    novaFamilia() {
      this.services.classificacaoService
        .postUpdateEspecie(this.model())
        .then(async (response) => {
          const body = await response.json();
          if (response.status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: "sucess",
            });
            this.$emit("response");
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: "error",
            });
            console.error(response);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    model() {
      // id:2
      // descricao-pop:teste familia testando agora
      // descricao-cientifica:teste familia testando agora
      // status: 1 true || 2 false
      // id_familia:3
      return {
        id: this.especie.id,
        nome_popular: this.descricaoPop,
        nome_cientifico: this.descricaoCientifica,
        id_familia: this.selectedFamilia,
        status: this.ativo ? 1 : 2,
      };
    },
  },
};
</script>
<style scoped></style>
