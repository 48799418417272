import { DataHoraHj, LogoHeader } from "./utils_docs";

export function TermoAnestesia(animal, responsavel, unidade) {
  let content = [];

  const dateNow = DataHoraHj();

  content.push({
    columns: [
      {
        stack: [
          {
            image: LogoHeader(unidade.id),
            width: 55,
          },
        ],
        alignment: "left",
        border: [false, false, false, false],
      },
      {
        type: "none",
        ol: [
          { text: "Unidade", style: "text-header", bold: true },
          { text: unidade.razao_social, style: "text-header" },
          { text: "Endereço", style: "text-header", bold: true },
          { text: getEndereco(unidade), style: "text-header" },
        ],
      },
    ],
  });

  // Perguntas
  content.push([
    { text: "TERMO DE RESPONSABILIDADE PARA ANESTESIA", style: "title" },
    {
      text: "Para fins de ordem legal, declaro que estou ciente dos riscos inerentes a qualquer prática anestésica, nada podendo reivindicar em qualquer momento, dando, assim, plenos poderes aos médicos - veterinários para fazê-lo com minha aquiescência. Igualmente, declaro as especificações do animal de minha propriedade, dato e assino o presente documento.",
      style: "text",
    },
    {
      text: `${unidade.razao_social}, ${dateNow}`,
      style: "text-date",
    },
    {
      type: "none",
      ol: [
        {
          text: [
            { text: "Rgv: ", style: "" },
            { text: animal.rgv, style: "", bold: true },
          ],
        },
        {
          text: [
            { text: "Nome: ", style: "" },
            { text: animal.nome, style: "", bold: true },
          ],
        },
        {
          text: [
            { text: "Classe: ", style: "" },
            { text: animal.classe, style: "", bold: true },
          ],
        },
        {
          text: [
            { text: "Espécie: ", style: "" },
            { text: animal.especie, style: "", bold: true },
          ],
        },
        {
          text: [
            { text: "Sexo: ", style: "" },
            { text: animal.sexo, style: "", bold: true },
          ],
        },
        {
          text: [
            { text: "Orgão: ", style: "" },
            { text: responsavel.nome, style: "", bold: true },
          ],
        },
        {
          text: [
            { text: "Endereço: ", style: "" },
            {
              text: getEnderecoResponsavel(responsavel),
              style: "",
              bold: true,
            },
          ],
        },
        {
          text: [
            { text: "CNPJ: ", style: "" },
            { text: responsavel.cnpj, style: "", bold: true },
          ],
        },
      ],
    },
    {
      style: "table",
      table: {
        widths: "*",
        body: [
          [
            {
              text: "",
              border: [false, false, false, false],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, false],
            },
          ],
          [
            {
              text: "",
              border: [false, false, false, false],
            },
            {
              text: responsavel.nome,
              colSpan: 3,
              alignment: "center",
              border: [false, false, false, false],
            },
            {
              text: "",
            },
            {
              text: "",
            },
            {
              text: "",
              border: [false, false, false, false],
            },
          ],
        ],
      },
    },
  ]);

  let modelo = {
    content: content,
    styles: {
      "text-header": {
        fontSize: 8,
      },
      title: {
        fontSize: 13,
        color: "#8a8a8a",
        bold: true,
        alignment: "center",
        margin: [0, 20, 0, 10],
      },
      text: {
        margin: [0, 0, 0, 10],
      },
      "text-date": {
        margin: [0, 0, 0, 20],
      },
      table: {
        margin: [0, 40, 0, 0],
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 9,
    },
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          { width: 20, text: "" },
          {
            width: "*",
            text: {
              // text: `Anamnese gerada por ${usuario}`,
              text: ``,
              fontSize: 6,
            },
          },
          {
            width: "*",
            text: {
              text: "Página " + currentPage.toString() + " de " + pageCount,
              alignment: "right",
              fontSize: 6,
            },
          },
          { width: 20, text: "" },
        ],
        margin: [0, 10, 0, 0],
      };
    },
  };
  return modelo;
}

function getEndereco(item) {
  return `${item.logradouro},${item.numero} | ${item.cidade} - ${item.cep}`;
}

function getEnderecoResponsavel(item) {
  return `${item.logradouro},${item.numero} | ${item.cidade} - ${item.cep}`;
}
