<template>
  <v-card>
    <v-toolbar style="background-color: #606060; color: #fff">
      <v-toolbar-title>
        <span class="headline">Emissão de Documento</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4>Seleciona o documento que deseja emitir?</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <v-select
              v-model="documentoSelect"
              label="Documentos"
              outlined
              :items="listaDocumentos"
              item-text="desc"
              item-value="valor"
              hide-details
            ></v-select>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-content-between">
      <v-btn color="primary" outlined @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        color="#606060"
        @click="gerarDoc()"
        :dark="documentoSelect != ''"
        :disabled="documentoSelect == ''"
      >
        Gerar documento
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import EntradaService from "@/services/entrada_service.js";
import AnimalService from "@/services/animal_service.js";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { TermoAnestesia } from "@/components/documentos/modelos/termo_anestesia.js";
import { AutorizoEutanasia } from "@/components/documentos/modelos/termo_autorizo_eutanasia.js";
import { AutorizoGeral } from "@/components/documentos/modelos/termo_autorizo_geral.js";
import { AutorizoVideo } from "@/components/documentos/modelos/termo_autorizo_video.js";
import { TermoRecusa } from "@/components/documentos/modelos/termo_recusa.js";
import { TermoAtesViagem } from "@/components/documentos/modelos/termo_atestado_viagem.js";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  mixins: [],
  props: {
    infoAnimal: Object,
  },
  components: {},
  data: () => ({
    services: {
      entradaService: EntradaService.build(),
      animalService: AnimalService.build(),
    },
    listaDocumentos: [
      { valor: "atestado-viagem", desc: "Atestado de saúde para viagens" },
      { valor: "recusa", desc: "Recusa Final" },
      { valor: "aut-geral", desc: "Autorização Geral" },
      { valor: "aut-eutanasia", desc: "Autorização eutanasia" },
      { valor: "aut-video", desc: "Autorização de imagem e video" },
      { valor: "anestesia", desc: "Termo de Resposabilidade para Anestesia" },
    ],
    documentoSelect: "",
    animalInfo: {
      rgv: "-- num rgv --",
      nome: "-- nome animal --",
      classe: "-- classe --",
      especie: "-- especie --",
      sexo: "-- sexo --",
    },
    orgaoInfo: {
      nome: "-- nome orgão --",
      cnpj: "-- cnpj --",
      telefone: "-- telefone --",
      nomeResp: "-- nome Responsavel --",
      cpfResp: "-- cpf --",
      logradouro: "-- logradouro --",
      numero: "-- numero --",
      cidade: "-- cidade --",
      cep: "-- cep --",
    },
    responsavelInfo: {
      nome: "-- nome orgão --",
      cnpj: "-- cnpj --",
      telefone: "-- telefone --",
      nomeResp: "-- nome Responsavel --",
      cpfResp: "-- cpf --",
      logradouro: "-- logradouro --",
      numero: "-- numero --",
      cidade: "-- cidade --",
      cep: "-- cep --",
      uf: "-- uf --",
    },
    unidadeInfo: {
      id: "-- id --",
      razao_social: "-- razao_social --",
      cnpj: "-- cnpj --",
      logradouro: "-- logradouro --",
      numero: "-- numero --",
      cidade: "-- cidade --",
      cep: "-- cep --",
    },
    usuario: "",
  }),
  watch: {},
  mounted() {
    this.getInfoEntrada();
    const unidade = JSON.parse(sessionStorage.vuex).unidade;
    this.unidadeInfo = {
      id: unidade.id,
      razao_social: unidade.razao_social,
      cnpj: unidade.cnpj,
      logradouro: unidade.logradouro,
      numero: unidade.numero,
      cidade: unidade.cidade,
      cep: unidade.cep,
    };
    const usuario = JSON.parse(sessionStorage.vuex).usuario.dados;
    const perfil = JSON.parse(sessionStorage.vuex).perfil;
    this.usuario = {
      nome: usuario.nome,
      crmv: perfil.documento ? perfil.documento : "-",
      perfil: perfil.descricao,
    };
  },
  computed: {},
  methods: {
    getInfoEntrada() {
      this.services.animalService
        .getEntradaAnimalbyId(this.infoAnimal.id)
        .then(async (response) => {
          if (response.status == 200) {
            const { data } = await response.json();
            const info = data;
            this.animalInfo = {
              rgv: info.pet_data.rgv,
              nome: info.pet_data.nome,
              classe: info.pet_data.descricao_classe,
              especie: `Popular: ${info.pet_data.nome_popular} | Científico ${info.pet_data.nome_cientifico}`,
              // especie: `Científico: ${info.nome_cientifico} | Popular: ${info.nome_popular}`,
              // cientifico: info.nome_cientifico,
              // popular: info.nome_popular,
              an_classe: info.pet_data.descricao_classe,
              an_familia: info.pet_data.familia,
              an_esp_pop: info.pet_data.nome_popular,
              an_esp_esp: info.pet_data.nome_cientifico,
              sexo: info.pet_data.sexo,
              idade: info.pet_data.idade,
            };
            this.orgaoInfo = {
              nome: info.orgao_data.razao_social,
              cnpj: info.orgao_data.cnpj,
              telefone: info.orgao_data.telefone,
              nomeResp: info.responsavel_data.nome,
              cpfResp: info.responsavel_data.cpf,
              logradouro: info.responsavel_data.logradouro,
              numero: info.responsavel_data.numero,
              cidade: info.responsavel_data.cidade,
              cep: info.responsavel_data.cep,
              uf: info.responsavel_data.estado,
            };
          }
        })
        .catch((err) => {
          console.err(err);
        })
        .finally(() => {});
    },
    gerarDoc() {
      const model = this.getModel();
      pdfMake.createPdf(model.docArquivo).download(model.descDoc);
      this.$emit("emitirSaida");
      // pdfMake.createPdf(model.docArquivo).getBase64((data) => {
      //   console.log("base64");
      //   console.log(data);
      // });
    },
    getModel() {
      switch (this.documentoSelect) {
        case "atestado-viagem":
          return {
            docArquivo: TermoAtesViagem(
              this.animalInfo,
              this.orgaoInfo,
              this.unidadeInfo,
              this.usuario
            ),
            descDoc: "Atestado Viagem.pdf",
          };
        case "recusa":
          return {
            docArquivo: TermoRecusa(
              this.animalInfo,
              this.orgaoInfo,
              this.unidadeInfo,
              this.usuario
            ),
            descDoc: "Recusa Final.pdf",
          };
        case "aut-geral":
          return {
            docArquivo: AutorizoGeral(
              this.animalInfo,
              this.orgaoInfo,
              this.unidadeInfo,
              this.usuario
            ),
            descDoc: "Autorização Geral.pdf",
          };
        case "aut-eutanasia":
          return {
            docArquivo: AutorizoEutanasia(
              this.animalInfo,
              this.orgaoInfo,
              this.unidadeInfo,
              this.usuario
            ),
            descDoc: "Autorização eutanasia.pdf",
          };
        case "aut-video":
          return {
            docArquivo: AutorizoVideo(
              this.animalInfo,
              this.orgaoInfo,
              this.unidadeInfo,
              this.usuario
            ),
            descDoc: "Autorização de imagem e video.pdf",
          };
        case "anestesia":
          return {
            docArquivo: TermoAnestesia(
              this.animalInfo,
              this.orgaoInfo,
              this.unidadeInfo,
              this.usuario
            ),
            descDoc: "Termo de Resposabilidade para Anestesia.pdf",
          };

        default:
          return false;
      }
    },
  },
};
</script>
