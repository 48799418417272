<template>
  <v-card>
    <v-toolbar style="background-color: #910000; color: #fff">
      <v-toolbar-title>
        <span class="headline">Cancelar Prescrição</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4 class="mb-0">Deseja cancelar prescrição?</h4>
            <h6 class="mb-0">
              Caso tenha executado alguma agendamento, ele será preservado e os
              demais cancelados.
            </h6>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="text-left mb-0">
              Medicamento: {{ info.medicamento }} <br />
              Descrição: {{ info.descricao_tipo }} <br />
              Dosagem: {{ info.dosagem }} <br />
              Administração : {{ info.administracao_medicamento }} <br />
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <v-textarea
              v-model="form_observacao"
              label="Observação"
              hide-details
              rows="3"
            />
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-content-end">
      <v-btn color="#910000" dark @click="cancelarPrescricao()">
        Executar Cancelamento</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import PrescricaoService from "@/services/prescricao_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  props: {
    info: Object,
  },
  components: {},
  data: () => ({
    services: {
      prescricaoService: PrescricaoService.build(),
    },
    form_observacao: "",
  }),
  watch: {},
  mounted() {},
  computed: {},
  methods: {
    cancelarPrescricao() {
      const model = {
        id_prescricao: this.info.id_prescricao,
        obs: this.form_observacao,
      };
      this.services.prescricaoService
        .postCancelarPrescricao(model)
        .then(async (response) => {
          if (response.status == 200) {
            const { data } = await response.json();
            console.log("d", data);
            this.$_ACTIONS_showSnackbarMessage({
              message: "Prescrição cancelado com sucesso!",
              color: "sucess",
            });
            this.$emit("concluido");
          }
        })
        .catch((err) => {
          console.err(err);
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped></style>
