import { render, staticRenderFns } from "./cadastro_novo_unidade.vue?vue&type=template&id=6c0f9db1&scoped=true"
import script from "./cadastro_novo_unidade.vue?vue&type=script&lang=js"
export * from "./cadastro_novo_unidade.vue?vue&type=script&lang=js"
import style0 from "./cadastro_novo_unidade.vue?vue&type=style&index=0&id=6c0f9db1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c0f9db1",
  null
  
)

export default component.exports