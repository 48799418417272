import HTTPService from "@/services/http_service.js";
import { global } from "@/config/constants.js";

export default class EntradaService extends HTTPService {
  static build() {
    return new EntradaService();
  }
  resource = "entrada";
  entradaHospital(model) {
    return this.postJson(`pet/entrada-animal`, model);
  }

  editarEntradaHospital(model) {
    return this.putJson(`entrada-animal/edit`, model);
  }

  entradaHospitalGet(model) {
    return this.getJson(`${this.resource}/hostipal`, model);
  }

  statusEntrada(idEntrada, idStatus) {
    //   1	Aguardando
    //   2	Atendimento
    //   3	Atendido
    //   4	Alta
    //   5	Obito- Descarte
    //   6	Obito-Pesquisa
    return this.postJson(
      `pet/updateStatusEntradaAnimal/${idEntrada}/${idStatus}`,
      {}
    );
  }

  updateMarcacao(model) {
    return this.post(`pet/updatemarcacoes`, model);
  }

  putSaidaHospital(model) {
    return this.putJson(`entrada/realizar-saida`, model);
  }

  postNovaImagem(formData) {
    return this.postFormData(`arquivo/entrada/img`, formData);
  }

  getImagensbyImagemId(idImagem) {
    return this.get(`arquivo/entrada/img/${idImagem}`);
  }

  deleteImagem(idImagem) {
    return this.deleteJson(`arquivo/entrada/img/remove/${idImagem}`);
  }

  getImagensbyEntradaId(idEntrada) {
    return this.get(`arquivo/entrada/img/list/${idEntrada}`);
  }

  getFirstImagemEntrada(idEntrada) {
    if (global.imagem) {
      return this.get(`arquivo/entrada/img/first/${idEntrada}`);
    } else {
      console.log("en", idEntrada);
      return "";
    }
  }

  postDestaqueImage(model) {
    return this.postJson(`arquivo/entrada/img/setdefault`, model);
  }
}
