<template>
  <div>
    <VueApexCharts
      type="line"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  // mixins: [Mixin],
  props: {
    lista: { required: true, type: Object },
  },
  components: {
    VueApexCharts,
  },
  data: () => ({
    series: [
      // {
      //   name: "Aves",
      //   data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 2, 13, 22],
      // },
      // {
      //   name: "Mamiferos",
      //   data: [42, 21, 74, 11, 21, 97, 30, 33, 1, 100, 23, 11],
      // },
      // {
      //   name: "Reptils",
      //   data: [4, 2, 59, 34, 8, 34, 39, 59, 71, 3, 2, 95],
      // },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
          tools: {
            download: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 5,
        curve: "smooth",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      colors: ["#0c35ed"],
      xaxis: {
        categories: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
      },
    },
  }),
  beforeMount() {
    console.log("l", this.lista);
    // this.chartOptions.xaxis.categories = this.lista.categorias;
    this.series = [
      {
        name: "Atendimentos",
        data: this.lista,
      },
    ];

    // this.chartOptions.colors[0] = this.color;
  },
};
</script>
