import HTTPService from '@/services/http_service.js'

export default class MedicamentoService extends HTTPService {
    resource = 'medicamento'
    cadastrar (responseFunctions, medicamento) {
        this.request(responseFunctions, 'POST', this.resource, medicamento)
    }

    getMedicamentos (responseFunctions, nome) {
        let imprevisto = nome ?? ''
        if (imprevisto === '') {
            this.request(responseFunctions, 'GET', this.resource, null)
        } else {
            this.request(responseFunctions, 'GET', this.resource, null, nome)
        }
    }

    getList (responseFunctions) {
      this.request(responseFunctions, 'GET', this.resource,null)
    }

    getAll (onSucess, onError, onEnd, page, per_page, filter, order, desc) {
      const responseFunctions = {
          onSucess,
          onError,
          onEnd
      }
      let queryparams ={
        page: page,
        per_page: per_page
      }
      if(filter ===''){
        if(order){
          let queryparams ={
            page: page,
            per_page: per_page,
            sort: desc?'nome':'-nome'
          }
          this.request(responseFunctions, 'GET', this.resource,null,queryparams)
        }else{
          this.request(responseFunctions, 'GET', this.resource,null,queryparams)
        }
      }else{
        let queryparams ={
          page: page,
          per_page: per_page,
          nome: filter,
        }
        if(order){
          let queryparams ={
            page: page,
            per_page: per_page,
            nome: filter,
            sort: desc?'nome':'-nome'
          }
          this.request(responseFunctions, 'GET', this.resource,null,queryparams)
        }else{
          this.request(responseFunctions, 'GET', this.resource, null, queryparams)
        }
      }
    }

    deleteMedicamento ({ onSucess, onError, onEnd }, { id }) {
      // ESTÁ SENDO UTILIZADO POST PARA EXCLUSÃO PORQUE A UMBLER INFORMOU ERRO DE CORS AO USAR O MÉTODO DELETE. CORRIGIR. 
        const uri = `${this.resource}/${id}/deletar`
        this.request({ onSucess, onError, onEnd }, 'POST', uri)
    }

    updateNomeMedicamento ({ onSucess, onError, onEnd }, medicamento) {
        const uri = `${this.resource}/${medicamento.id}`
        this.request({ onSucess, onError, onEnd }, 'POST', uri, medicamento)
    }

    searchByNome (vm) {
        // Para usar esse método, o componente deve ter como atributos em data
        // "medicamentos" (array) e "loading"(boolean)
        return nome => {
            if (!nome) {
                vm.medicamentos = [];
                vm.medicamento='';
              }
              if (
                !nome ||
                !(nome = nome.trim()) ||
                nome.length < 3 ||
                vm.loading_medicamento
              ) {
                return;
              }
              vm.loading_medicamento = true;
              vm.medicamentos = [];
              let queryParam = {
                nome: nome
              }
            //   const procedimentoService = new ProcedimentoService();
              const requestFunctions = {
                onSucess: (status) => (body) => {
                  if (status === 200) {
                    vm.medicamentos = body.data.medicamentos;
                  } else {
                    vm.medicamentos = [];
                  }
                },
                onError: (error) => {
                  console.error(error);
                  vm.medicamentos = [];
                },
                onEnd: () => {
                  vm.loading_medicamento = false;
                },
              };
              this.getMedicamentos(requestFunctions, queryParam );
        }
    }
} 