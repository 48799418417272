import HTTPService from "@/services/http_service.js";

export default class InternacaoService extends HTTPService {
  static build() {
    return new InternacaoService();
  }
  resource = "internacao";

  getInternacaoPaginatedList({ page, per_page, sort, desc, search }) {
    return this.get(`${this.resource}/listar`, {
      page,
      per_page,
      sort,
      desc,
      search,
    });
  }

  getInternacaoDetalhe(internacaoId) {
    return this.get(`${this.resource}/detalhes/${internacaoId}`);
  }

  postEntradaInternacaoAves(model) {
    return this.postJson(`${this.resource}/ave/registrar`, model);
  }

  postEntradaInternacaoMammalia(model) {
    return this.postJson(`${this.resource}/mamifero/registrar`, model);
  }

  postEntradaInternacaoReptillia(model) {
    return this.postJson(`${this.resource}/reptil/registrar`, model);
  }

  putSaidaInternacao(model) {
    return this.putJson(`${this.resource}/saida`, model);
  }

  postNovaImagem(formData) {
    return this.postFormData(`arquivo/internacao/img`, formData);
  }

  getImagensbyImagemId(idImagem) {
    return this.get(`arquivo/internacao/img/${idImagem}`);
  }

  deleteImagem(idImagem) {
    return this.deleteJson(`arquivo/internacao/img/remove/${idImagem}`);
  }

  getImagensbyInternacaoId(idInternacao) {
    return this.get(`arquivo/internacao/img/list/${idInternacao}`);
  }

  // getFirstImagemInternacao(idInternacao) {
  //   return this.get(`arquivo/internacao/img/first/${idInternacao}`);
  // }

  // postDestaqueImage(model) {
  //   return this.postJson(`arquivo/internacao/img/thumb`, model);
  // }
}
