<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Internação</h3>
      </div>
    </div>
    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :headers="headers"
      :items="internacoes"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:item.thumb="{ item }">
        <v-skeleton-loader
          v-if="!item.url"
          v-bind="attrs"
          type="image"
          height="100px"
          width="100px"
          class="my-1"
          style="
            border-radius: 5px;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
          "
        ></v-skeleton-loader>
        <v-template v-else>
          <!-- {{ item.url }} -->
          <v-img
            v-if="item.url == 'sem-imagem'"
            src="@/assets/images/default_image.jpg"
            height="100px"
            width="100px"
            class="my-1"
            style="
              border-radius: 5px;
              box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
            "
          >
          </v-img>
          <v-img
            v-else
            :src="item.url"
            height="100px"
            width="100px"
            class="my-1"
            style="
              border-radius: 5px;
              box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
            "
          >
          </v-img>
        </v-template>
      </template>
      <template v-slot:item.classificacao="{ item }">
        {{ item.classe }} | {{ item.familia }} | {{ item.especie_nome_popular }}
      </template>
      <template v-slot:item.entrada="{ item }">
        {{ getEntrada(item) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="ml-2"
          icon
          color="#1d9b03"
          x-small
          @click="verGaleria(item)"
          title="Galeria"
        >
          <v-icon> mdi mdi-image-multiple </v-icon>
        </v-btn>
        <v-btn
          class="ml-1"
          icon
          color="#006368"
          x-small
          title="Mapa Internação"
          @click="mapaInternacao(item)"
        >
          <v-icon> mdi mdi-calendar-month</v-icon>
        </v-btn>
        <!-- <v-btn
          class="ml-1"
          icon
          color="#79540e"
          x-small
          title="Atualizar Peso"
          @click="editarPeso(item)"
        >
          <v-icon> mdi mdi-scale</v-icon>
        </v-btn> -->
        <v-btn
          class="ml-1"
          icon
          color="#8a0000"
          x-small
          title="Saída Internação"
          @click="emitirSaida(item)"
          :disabled="perfilId == 1"
        >
          <v-icon>mdi-backspace-reverse</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <p class="text center">Nenhum registro encontrado</p>
        <v-btn color="#3E682A" dark @click="getInternacao"> Atualizar </v-btn>
      </template>
    </v-data-table>
    <v-dialog
      v-model="modalSaidaInternacao"
      width="700"
      transition="dialog-bottom-transition"
    >
      <modal-documento-saida-internacao
        v-if="modalSaidaInternacao"
        :infoAnimal="infoInternacao"
        @close="modalSaidaInternacao = false"
        @emitirSaida="
          getInternacao();
          infoInternacao = {};
          modalSaidaInternacao = false;
        "
      ></modal-documento-saida-internacao>
    </v-dialog>
    <v-dialog
      v-model="modalPeso"
      width="700"
      transition="dialog-bottom-transition"
    >
      <ModalAtualizarPeso
        v-if="modalPeso"
        :infoAnimal="infoInternacao"
        @close="modalPeso = false"
        @marcacaoConcluida="
          getInternacao();
          infoInternacao = {};
          modalPeso = false;
        "
      ></ModalAtualizarPeso>
    </v-dialog>
    <v-dialog
      v-model="modalMapaInternacao"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <ModalMapaInternacao
        v-if="modalMapaInternacao"
        :info="infoInternacao"
        @close="
          getInternacao();
          modalMapaInternacao = false;
        "
      ></ModalMapaInternacao>
    </v-dialog>
    <!-- Modal Galeria -->
    <v-dialog
      v-model="dialogGaleria"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <ModalGaleriaInternacao
        v-if="dialogGaleria"
        :internacaoInfo="infoInternacao"
        @close="() => (dialogGaleria = false)"
      />
    </v-dialog>
  </div>
</template>

<script>
import InternacaoService from "@/services/internacao_service.js";
import ModalDocumentoSaidaInternacao from "@/components/animal/modal_documento_saida_internacao.vue";
import ModalAtualizarPeso from "@/components/mapa_prescricao/modal_atualizar_peso.vue";
import ModalMapaInternacao from "@/components/mapa_prescricao/modal_prescricao_animal.vue";
import ModalGaleriaInternacao from "@/components/animal/galeria_internacao/modal_galeria";
import EntradaService from "@/services/entrada_service.js";

export default {
  components: {
    ModalAtualizarPeso,
    ModalDocumentoSaidaInternacao,
    ModalMapaInternacao,
    ModalGaleriaInternacao,
  },

  data: () => ({
    loading: false,
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      {
        text: "",
        align: "start",
        sortable: false,
        value: "thumb",
      },
      { text: "ID", value: "id", sortable: false },
      { text: "Nome", value: "nome", sortable: false },
      { text: "Classificação", value: "classificacao", sortable: false },
      { text: "Peso", value: "peso", sortable: false },
      // { text: "Peso Atual", value: "peso_atual", sortable: false },
      { text: "Entrada", value: "entrada", sortable: false },
      {
        text: "",
        align: "end",
        value: "actions",
        sortable: false,
      },
    ],
    services: {
      internacaoService: InternacaoService.build(),
      entradaService: EntradaService.build(),
    },
    perfilId: JSON.parse(sessionStorage.getItem("vuex")).perfil.id,
    internacoes: [],
    infoInternacao: {},
    modalSaidaInternacao: false,
    modalPeso: false,
    modalMapaInternacao: false,
    dialogGaleria: false,
  }),

  computed: {},

  watch: {
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.getInternacao();
      },
      deep: true,
    },
    // search(value, oldValue) {
    //   if (value.length > 2) {
    //     this.options.page = 1;
    //     this.atualizarListaAssistentes();
    //   }
    //   if (value.length === 0 && oldValue.length > 0) {
    //     this.options.page = 1;
    //     this.atualizarListaAssistentes();
    //   }
    // },
  },

  mounted() {
    this.getInternacao();
  },

  methods: {
    async getInternacao() {
      if (!this.loading) {
        this.loading = true;
        this.internacoes = [];
        const paginationParams = {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          sort: this.options.order,
          desc: this.options.desc,
          search: "",
        };
        await this.services.internacaoService
          .getInternacaoPaginatedList(paginationParams)
          // .getAnimaisPaginatedList()
          .then(async (response) => {
            if (response.status !== 200) return;
            const { data } = await response.json();
            // this.internacoes = data.dados;
            for (let index = 0; index < data.dados.length; index++) {
              const element = data.dados[index];
              this.internacoes.push({ ...element, url: "" });
              this.getThumbEntrada(element);
            }
            this.totalList = data.paginacao.num_rows;
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getEntrada(item) {
      let data = item.data_entrada.split("-").reverse().join("/");
      let hora = item.hora_entrada.split(":");
      return `${data} | ${hora[0]}:${hora[1]}`;
    },
    async getThumbEntrada(internacao) {
      await this.services.entradaService
        .getFirstImagemEntrada(internacao.id_entrada)
        .then(async (response) => {
          const index = this.internacoes.findIndex(
            (ele) => ele.id_entrada == internacao.id_entrada
          );
          if (response.status == 200) {
            const resp = await response.json();
            // console.log("r", resp);
            const ext = resp.data.arquivo_nome.split(".");
            this.internacoes[
              index
            ].url = `data:image/${ext[1]};base64,${resp.data.base64}`;
          } else {
            this.internacoes[index].url = "sem-imagem";
            // this.pets[index].url = "../assets/images/default_image.jpeg";
          }
        })
        .catch((e) => {
          console.log("erro ", { e });
        })
        .finally(() => {});
    },
    mapaInternacao(item) {
      this.infoInternacao = {
        ...item,
        idInternacao: item.idInternacao,
      };
      this.modalMapaInternacao = true;
    },
    editarPeso(item) {
      this.infoInternacao = item;
      this.modalPeso = true;
    },
    emitirSaida(item) {
      this.infoInternacao = item;
      this.modalSaidaInternacao = true;
    },
    verGaleria(item) {
      this.infoInternacao = item;
      this.dialogGaleria = true;
    },
  },
};
</script>
