import HTTPService from "@/services/http_service.js";

export default class PrescricaoService extends HTTPService {
  static build() {
    return new PrescricaoService();
  }
  resource = "prescricao";

  getPrescricao(idInternacao, dataMapa) {
    return this.get(
      `${this.resource}/internacao/agenda/dia/list/${idInternacao}/${dataMapa}`
    );
  }
  getPrescricaoUnidade(idUnidade, dataMapa) {
    return this.get(
      `${this.resource}/internacao/unidade/dia/list/${idUnidade}/${dataMapa}`
    );
  }

  // getInternacaoPaginatedList({ page, per_page, sort, desc, search }) {
  //   return this.get(`${this.resource}/listar`, {
  //     page,
  //     per_page,
  //     sort,
  //     desc,
  //     search,
  //   });
  // }

  postCriarPrescricao(model) {
    return this.postJson(`${this.resource}/registrar`, model);
  }

  postExecutarPrescricao(model) {
    return this.postJson(`${this.resource}/agenda/executar`, model);
  }

  postCancelarPrescricao(model) {
    return this.postJson(`${this.resource}/cancelar`, model);
  }
}
