import HTTPService from "@/services/http_service.js";

export default class ClassificacaoService extends HTTPService {
  static build() {
    return new ClassificacaoService();
  }
  // Classe
  // model
  // descricao:jumentobis de pirambas
  // id:5
  // status: 1 true || 2 false

  getClasseList() {
    return this.get("pet/classes");
  }

  postCreateClasse(model) {
    return this.post("pet/classe", model);
  }

  postUpdateClasse(model) {
    return this.post("pet/classe-update", model);
  }

  // Familia
  // model
  // id:2
  // descricao:teste familia testando agora
  // status: 1 true || 2 false
  // id_classe:3

  getFamiliaList() {
    return this.get("pet/get-familia");
  }

  postCreateFamilia(model) {
    return this.post("pet/familia", model);
  }

  postUpdateFamilia(model) {
    return this.post("pet/familia-update", model);
  }

  // Especie
  // model
  // id:2
  // descricao-pop:teste familia testando agora
  // descricao-cientifica:teste familia testando agora
  // status: 1 true || 2 false
  // id_familia:3

  getEspecieList() {
    return this.get("pet/especies");
  }

  postCreateEspecie(model) {
    return this.post("pet/especie", model);
  }

  postUpdateEspecie(model) {
    return this.post("pet/especie-update", model);
  }

  // Listagens para seletores
  // status: 1 true || 2 false
  listSelectClasse(status) {
    return this.get(`pet/classe/${status}`);
  }
  listSelectFamilia(status) {
    return this.get(`pet/familia/${status}`);
  }
  listSelectEspecie(status) {
    return this.get(`pet/especies/${status}`);
  }
}
