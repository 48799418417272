<template>
  <v-card>
    <v-toolbar style="background-color: #00868d; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Entrada do Animal</span>
      </v-toolbar-title>
    </v-toolbar>
    <!-- <v-card-text> -->
    <v-stepper v-model="step" non-linear>
      <v-stepper-header>
        <v-stepper-step
          step="1"
          editable
          :edit-icon="formEntrada() ? 'mdi-check' : 'mdi-pencil'"
          :complete="formEntrada()"
        >
          Entrada
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="2"
          editable
          :edit-icon="formAnimal() ? 'mdi-check' : 'mdi-pencil'"
          :complete="formAnimal()"
        >
          Animal
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="3"
          editable
          :edit-icon="formResponsavel() ? 'mdi-check' : 'mdi-pencil'"
          :complete="formResponsavel()"
        >
          Responsável
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="4"
          editable
          :edit-icon="resumoCheck() ? 'mdi-check' : 'mdi-pencil'"
          :complete="resumoCheck()"
        >
          Resumo
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- Entrada -->
        <v-stepper-content step="1">
          <div class="container-fluid">
            <div class="row">
              <div class="col-4">
                <v-select
                  v-model="select_orgao"
                  :items="list_orgao"
                  item-text="desc"
                  item-value="id"
                  label="Orgão"
                  hide-details
                />
              </div>
              <div class="col-4">
                <v-select
                  v-model="select_entrada"
                  :items="list_entrada"
                  item-text="desc"
                  item-value="id"
                  label="Tipo Entrada"
                  hide-details
                />
              </div>
              <div class="col-4">
                <v-select
                  v-model="select_estado_animal"
                  :items="list_estado_animal"
                  item-text="desc"
                  item-value="id"
                  label="Estado do Animal"
                  hide-details
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <v-textarea
                  v-model="observacao_entrada"
                  label="Observação"
                  hide-details
                  rows="3"
                />
              </div>
              <div class="col-2">
                <v-text-field
                  v-model="data_entrada"
                  label="Data"
                  type="date"
                  hide-details
                  class="mb-3"
                />
                <v-text-field
                  v-model="hora_entrada"
                  label="Hora"
                  hide-details
                  type="time"
                />
              </div>
              <div class="col-4">
                <v-text-field
                  v-model="marcacao_entrada"
                  label="Marcação"
                  hide-details
                />
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <v-text-field
                  v-model="cep_entrada"
                  label="CEP"
                  @blur="searchCepEntrada"
                  hide-details
                  v-mask="'#####-###'"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <v-text-field
                  v-model="logradouro_entrada"
                  label="Logradouro"
                  hide-details
                />
              </div>
              <div class="col-2">
                <v-text-field
                  v-model="numero_entrada"
                  label="Número"
                  hide-details
                />
              </div>
              <div class="col-3">
                <v-text-field
                  v-model="bairro_entrada"
                  label="Bairro"
                  hide-details
                />
              </div>
              <div class="col-3">
                <v-select
                  v-model="reg_adm_entrada"
                  :items="list_reg_adm"
                  item-text="desc"
                  item-value="id"
                  label="Região Administrativa"
                  hide-details
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title
                        style="color: #313030; margin-bottom: 5px"
                      >
                        Cidade: {{ data.item.desc }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        style="font-weight: bold; color: #999"
                      >
                        RA: {{ data.item.ra }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <v-text-field
                  v-model="complemento_entrada"
                  label="Complemento"
                  hide-details
                />
              </div>
              <div class="col-4">
                <v-text-field
                  v-model="cidade_entrada"
                  label="Cidade"
                  hide-details
                />
              </div>
              <div class="col-4">
                <v-autocomplete
                  v-model="estado_entrada"
                  :items="listEstados"
                  item-text="descricao"
                  item-value="uf"
                  label="Estado"
                  hide-details
                />
                <!-- <v-text-field
                  v-model="estado_entrada"
                  label="Estado"
                  hide-details
                /> -->
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end py-5">
            <v-btn color="primary" @click="step = 2">Seguinte</v-btn>
          </div>
        </v-stepper-content>
        <!-- Animal -->
        <v-stepper-content step="2">
          <div class="container-fluid">
            <div class="row">
              <div class="col-3">
                <v-text-field v-model="rgv_animal" label="RGV" hide-details />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <v-text-field v-model="nome_animal" label="Nome" hide-details />
              </div>
              <!-- <div class="col-4">
                <v-text-field v-model="nome_animal" label="Nome" hide-details />
              </div>
              <div class="col-2">
                <v-text-field v-model="peso_animal" label="Peso" hide-details />
              </div> -->
              <div class="col-3">
                <v-select
                  v-model="select_sexo"
                  :items="list_sexo"
                  item-text="desc"
                  item-value="id"
                  label="Sexo"
                  hide-details
                />
              </div>
              <div class="col-3">
                <v-select
                  v-model="select_idade"
                  :items="list_idade"
                  item-text="desc"
                  item-value="id"
                  label="Idade"
                  hide-details
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <v-select
                  v-model="select_classe"
                  :items="list_classe"
                  item-text="desc"
                  item-value="id"
                  label="Classe"
                  hide-details
                />
              </div>
              <div class="col-4">
                <v-select
                  v-model="select_familia"
                  :items="list_familia"
                  item-text="desc"
                  item-value="id"
                  label="Família"
                  hide-details
                  :disabled="list_familia.length == 0"
                />
              </div>
              <div class="col-4">
                <v-select
                  v-model="select_especie"
                  :items="list_especie"
                  item-text="popular"
                  item-value="id"
                  label="Espécie"
                  hide-details
                  :disabled="list_especie.length == 0"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title
                        style="color: #313030; margin-bottom: 5px"
                      >
                        Popular: {{ data.item.popular }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        style="font-weight: bold; color: #999"
                      >
                        Cientifico: {{ data.item.cientifico }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
            </div>
            <v-divider class="mt-8"></v-divider>
            <div class="row justify-content-center">
              <div class="col-10">
                <h4>Fotos animal</h4>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-3">
                <v-file-input
                  v-model="selectedFiles"
                  label="Escolha uma ou mais imagens"
                  multiple
                  ref="fileInput"
                  accept="image/jpeg, image/png"
                ></v-file-input>
              </div>
              <div class="col-1">
                <v-btn
                  class="mt-3"
                  @click="addImages"
                  color="primary"
                  :disabled="selectedFiles == ''"
                  >Adicionar</v-btn
                >
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="d-flex justify-content-center flex-wrap"
                  style="gap: 20px"
                >
                  <v-card
                    v-for="(image, index) in images"
                    :key="index"
                    width="200px"
                  >
                    <v-img
                      :src="image.url"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="200px"
                      width="200px"
                    >
                      <v-card-title v-text="image.file.name"></v-card-title>
                    </v-img>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn @click="viewImage(image)" icon>
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>

                      <v-btn @click="removeImage(index)" icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
              </div>
              <v-dialog
                v-if="dialogImageView"
                v-model="dialogImageView"
                width="1000"
                transition="dialog-bottom-transition"
              >
                <v-img :src="imageView.url" class="white--text align-end">
                  <v-card-title>
                    <h4 class="" style="color: white">
                      {{ imageView.file.name }}
                    </h4>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="dialogImageView = false"
                      fab
                      x-small
                      color="red"
                      dark
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                </v-img>
              </v-dialog>
            </div>
          </div>
          <div class="d-flex justify-content-end py-5">
            <v-btn color="primary" @click="step = 3">Seguinte</v-btn>
          </div>
        </v-stepper-content>
        <!-- Responsável -->
        <v-stepper-content step="3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-4">
                <v-text-field
                  v-model="cpf_responsavel"
                  @blur="searchResponsavelCPF"
                  label="CPF"
                  hide-details
                  v-mask="'###.###.###-##'"
                />
              </div>
              <div class="col-8">
                <v-text-field
                  v-model="nome_responsavel"
                  label="Nome"
                  hide-details
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <v-text-field
                  v-model="rg_responsavel"
                  label="RG"
                  hide-details
                />
              </div>
              <div class="col-3">
                <v-text-field
                  v-model="nascimento_responsavel"
                  label="Data"
                  type="date"
                  hide-details
                />
              </div>
              <div class="col-3">
                <v-select
                  v-model="genero_responsavel"
                  :items="list_genero_repsonsavel"
                  item-text="desc"
                  item-value="id"
                  label="Gênero"
                  hide-details
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <v-text-field
                  v-model="matricula_responsavel"
                  label="Matricula"
                  hide-details
                />
              </div>
              <div class="col-4">
                <v-text-field
                  v-model="telefone1_responsavel"
                  v-mask="'(##)#####-####'"
                  label="Telefone 1"
                  hide-details
                />
              </div>
              <div class="col-4">
                <v-text-field
                  v-model="telefone2_responsavel"
                  v-mask="'(##)#####-####'"
                  label="Telefone 2"
                  hide-details
                />
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <v-text-field
                  v-model="cep_responsavel"
                  label="CEP"
                  @blur="searchCepResponsavel"
                  hide-details
                  v-mask="'#####-###'"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <v-text-field
                  v-model="logradouro_responsavel"
                  label="Logradouro"
                  hide-details
                />
              </div>
              <div class="col-2">
                <v-text-field
                  v-model="numero_responsavel"
                  label="Número"
                  hide-details
                />
              </div>
              <div class="col-3">
                <v-text-field
                  v-model="bairro_responsavel"
                  label="Bairro"
                  hide-details
                />
              </div>
              <div class="col-3">
                <v-select
                  v-model="reg_adm_responsavel"
                  :items="list_reg_adm"
                  item-text="desc"
                  item-value="id"
                  label="Região Administrativa"
                  hide-details
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title
                        style="color: #313030; margin-bottom: 5px"
                      >
                        Cidade: {{ data.item.desc }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        style="font-weight: bold; color: #999"
                      >
                        RA: {{ data.item.ra }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <v-text-field
                  v-model="complemento_responsavel"
                  label="Complemento"
                  hide-details
                />
              </div>
              <div class="col-4">
                <v-text-field
                  v-model="cidade_responsavel"
                  label="Cidade"
                  hide-details
                />
              </div>
              <div class="col-4">
                <!-- <v-text-field
                  label="Estado"
                  hide-details
                /> -->
                <v-autocomplete
                  v-model="estado_responsavel"
                  :items="listEstados"
                  item-text="descricao"
                  item-value="uf"
                  label="Estado"
                  hide-details
                />
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end py-5">
            <v-btn color="primary" @click="step = 4">Seguinte</v-btn>
          </div>
        </v-stepper-content>
        <!-- Resumo -->
        <v-stepper-content step="4">
          <div class="d-flex justify-content-end py-5">
            <v-btn
              color="primary"
              @click="concluirEntrada()"
              :disabled="!resumoCheck() || loading || loadingImagens"
              :dark="resumoCheck() && !loading && !loadingImagens"
              width="120px"
            >
              <span v-if="!loading || !loadingImagens"> Concluir </span>
              <v-progress-circular
                indeterminate
                size="20"
                width="3"
                v-else
              ></v-progress-circular>
            </v-btn>
          </div>
          <v-divider></v-divider>
          <entrada-detalhe :info="resumoEntradaForm()" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <!-- </v-card-text> -->
  </v-card>
</template>

<script>
import EntradaDetalhe from "@/components/animal/entrada_detalhe.vue";
import ViaCepService from "@/services/via_cep_service.js";
import RegiaoAdministrativaService from "@/services/regiao_administrativa_service.js";
import ResponsavelService from "@/services/responsavel_service.js";
import AnimalService from "@/services/animal_service.js";
import EntradaService from "@/services/entrada_service.js";
import OrgaoService from "@/services/orgao_service";
import PetsService from "../../services/pets_service";
import EspecieService from "../../services/especie_service.js";
import Mixin from "@/mixins/vuex_mixin.js";
import ClassificacaoService from "@/services/classificacao_service.js";

export default {
  mixins: [Mixin],
  components: {
    EntradaDetalhe,
  },
  data: () => ({
    services: {
      viaCepService: ViaCepService.build(),
      regiaoAdministrativaService: RegiaoAdministrativaService.build(),
      responsavelService: ResponsavelService.build(),
      animalService: AnimalService.build(),
      entradaService: EntradaService.build(),
      orgaoService: OrgaoService.build(),
      petsService: PetsService.build(),
      especieService: EspecieService.build(),
      classificacaoService: ClassificacaoService.build(),
    },
    step: 1,
    // form animal
    select_classe: "",
    select_familia: "",
    select_especie: "",
    fotos_animal: [],
    especies: [],
    familias: [],
    list_especie: [],
    list_familia: [],
    list_classe: [],
    select_sexo: "",
    select_idade: "",
    nome_animal: "",
    rgv_animal: "",
    peso_animal: "",
    list_sexo: [
      { id: "1", desc: "Macho" },
      { id: "2", desc: "Femea" },
      { id: "3", desc: "Indeterminado" },
    ],
    list_idade: [
      { id: "1", desc: "Filhote" },
      { id: "2", desc: "Jovem" },
      { id: "3", desc: "Adulto" },
      { id: "4", desc: "Não é possível verificar a idade" },
    ],
    // form responsavel
    nome_responsavel: "",
    cpf_responsavel: "",
    rg_responsavel: "",
    genero_responsavel: "N",
    nascimento_responsavel: "",
    matricula_responsavel: "",
    telefone1_responsavel: "",
    telefone2_responsavel: "",
    cep_responsavel: "",
    logradouro_responsavel: "",
    numero_responsavel: "",
    bairro_responsavel: "",
    reg_adm_responsavel: "",
    cidade_responsavel: "",
    estado_responsavel: "",
    complemento_responsavel: "",
    list_genero_repsonsavel: [
      { id: "N", desc: "Não Informado" },
      { id: "M", desc: "Masculino" },
      { id: "F", desc: "Feminino" },
    ],
    // form entrada
    select_orgao: "",
    select_entrada: "",
    select_estado_animal: "",
    data_entrada: "",
    hora_entrada: "",
    marcacao_entrada: "",
    observacao_entrada: "",
    cep_entrada: "",
    logradouro_entrada: "",
    numero_entrada: "",
    bairro_entrada: "",
    reg_adm_entrada: "",
    cidade_entrada: "",
    estado_entrada: "",
    complemento_entrada: "",
    list_orgao: [
      { id: "1", desc: "BPMA" },
      { id: "2", desc: "IBAMA" },
      { id: "3", desc: "IBRAM" },
      { id: "4", desc: "CBMDF" },
      { id: "5", desc: "PCDF" },
      { id: "6", desc: "Entrega Voluntária" },
      { id: "7", desc: "Outro" },
    ],
    list_entrada: [
      { id: "1", desc: "Apreensão" },
      { id: "2", desc: "Resgate" },
      { id: "3", desc: "Entrega Voluntária" },
      { id: "4", desc: "Abandono" },
      { id: "5", desc: "Exames/Avaliações" },
    ],
    list_estado_animal: [
      { id: "1", desc: "Ferido" },
      { id: "2", desc: "Inconsciente" },
      { id: "3", desc: "Não foi possível identificar" },
    ],
    list_reg_adm: [],
    listEstados: [
      { uf: "AC", descricao: "Acre" },
      { uf: "AL", descricao: "Alagoas" },
      { uf: "AP", descricao: "Amapá" },
      { uf: "AM", descricao: "Amazonas" },
      { uf: "BA", descricao: "Bahia" },
      { uf: "CE", descricao: "Ceará" },
      { uf: "DF", descricao: "Distrito Federal" },
      { uf: "ES", descricao: "Espírito Santo" },
      { uf: "GO", descricao: "Goiás" },
      { uf: "MA", descricao: "Maranhão" },
      { uf: "MT", descricao: "Mato Grosso" },
      { uf: "MS", descricao: "Mato Grosso do Sul" },
      { uf: "MG", descricao: "Minas Gerais" },
      { uf: "PA", descricao: "Pará" },
      { uf: "PB", descricao: "Paraíba" },
      { uf: "PR", descricao: "Paraná" },
      { uf: "PE", descricao: "Pernambuco" },
      { uf: "PI", descricao: "Piauí" },
      { uf: "RJ", descricao: "Rio de Janeiro" },
      { uf: "RN", descricao: "Rio Grande do Norte" },
      { uf: "RS", descricao: "Rio Grande do Sul" },
      { uf: "RO", descricao: "Rondônia" },
      { uf: "RR", descricao: "Roraima" },
      { uf: "SC", descricao: "Santa Catarina" },
      { uf: "SP", descricao: "São Paulo" },
      { uf: "SE", descricao: "Sergipe" },
      { uf: "TO", descricao: "Tocantins" },
    ],
    selectedFiles: [],
    images: [],
    imageView: {},
    loading: false,
    loadingImagens: false,
    dialogImageView: false,
    checkImagesSend: [],
  }),
  watch: {
    select_classe() {
      this.select_familia = "";
      this.select_especie = "";
      if (this.select_classe) {
        this.list_familia = [];
        this.list_especie = [];
        for (let index = 0; index < this.familias.length; index++) {
          const element = this.familias[index];
          if (element.id_classe == this.select_classe) {
            this.list_familia.push(element);
          }
        }
      }
    },
    select_familia() {
      this.select_especie = "";
      if (this.select_familia) {
        this.list_especie = [];
        for (let index = 0; index < this.especies.length; index++) {
          const element = this.especies[index];
          if (element.id_familia == this.select_familia) {
            this.list_especie.push(element);
          }
        }
      }
    },
    checkImagesSend() {
      if (this.checkImagesSend.length == this.images.length) {
        this.loadingImagens = false;
        const checkFalse = this.checkImagesSend.some(
          (element) => element == false
        );
        if (checkFalse) {
          this.$_ACTIONS_showSnackbarMessage({
            message:
              "Entrada cadastrada com sucesso, porem algumas imagens não puderam ser salvas.",
            color: "warning",
          });
        } else {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Entrada cadastrada com sucesso.",
            color: "sucess",
          });
        }
        this.$emit("entradaConcluida");
      }
    },
  },
  mounted() {
    this.atualizarListaOrgaos();
    this.getListaClasse();
    this.getListaFamilia();
    this.getListaEspecies();
    this.getListaRegiaoAdmin();
    const hj = new Date();
    const hora =
      hj.getHours().toString().padStart(2, "0") + ":" + hj.getMinutes();
    this.data_entrada = hj.toISOString().split("T")[0];
    this.hora_entrada = hora;
  },
  computed: {},
  methods: {
    concluirEntrada() {
      this.loading = true;
      this.services.entradaService
        .entradaHospital(this.getModel())
        .then(async (response) => {
          const body = await response.json();
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({ body, color: "error" });
            const errors = body.errors;
            Object.keys(body.errors).forEach((error) => {
              if (errors[error]) {
                this.error[error] = errors[error];
              }
            });
            return;
          }
          if (this.images.length > 0) {
            this.uploadImages(body.data);
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: "sucess",
            });
            this.$emit("entradaConcluida");
          }
        })
        .catch((e) => {
          console.log("erro ", { e });
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resumoCheck() {
      return this.formEntrada() && this.formAnimal() && this.formResponsavel()
        ? true
        : false;
    },
    formEntrada() {
      if (
        this.validarSelect(this.select_orgao) &&
        this.validarSelect(this.select_entrada) &&
        this.validarSelect(this.select_estado_animal) &&
        this.validarForm(this.data_entrada) &&
        this.validarForm(this.hora_entrada)
        // this.validarForm(this.hora_entrada) &&
        // this.validarForm(this.observacao_entrada) &&
        // this.validarForm(this.cep_entrada) &&
        // this.validarForm(this.logradouro_entrada) &&
        // this.validarForm(this.numero_entrada) &&
        // this.validarForm(this.bairro_entrada) &&
        // this.validarForm(this.cidade_entrada) &&
        // this.validarForm(this.estado_entrada) &&
        // this.validarForm(this.complemento_entrada)
      ) {
        return true;
      } else {
        return false;
      }
    },
    formAnimal() {
      if (
        this.validarSelect(this.select_classe) &&
        this.validarSelect(this.select_familia) &&
        this.validarSelect(this.select_especie) &&
        this.validarSelect(this.select_sexo) &&
        this.validarSelect(this.select_idade) &&
        this.validarForm(this.nome_animal) &&
        // this.validarForm(this.peso_animal) &&
        this.validarForm(this.rgv_animal)
      ) {
        return true;
      } else {
        return false;
      }
    },
    formResponsavel() {
      if (
        this.validarForm(this.nome_responsavel) &&
        this.validarForm(this.cpf_responsavel) &&
        this.validarForm(this.matricula_responsavel) &&
        this.validarForm(this.telefone1_responsavel) &&
        this.validarForm(this.telefone2_responsavel) &&
        this.validarForm(this.cep_responsavel) &&
        this.validarForm(this.logradouro_responsavel) &&
        this.validarForm(this.numero_responsavel) &&
        this.validarForm(this.bairro_responsavel) &&
        this.validarForm(this.cidade_responsavel) &&
        this.validarForm(this.estado_responsavel) &&
        this.validarForm(this.complemento_responsavel)
      ) {
        return true;
      } else {
        return false;
      }
    },
    validarForm(item) {
      if (item != "") {
        return true;
      } else {
        return false;
      }
    },
    validarSelect(item) {
      if (item != "") {
        return true;
      } else {
        return false;
      }
    },
    getDescList(id, tipoLista) {
      let item = { desc: "-" };
      if (id != "" && tipoLista != "") {
        if (tipoLista == "Sexo") {
          item = this.list_sexo.find((ele) => {
            return ele.id == id;
          });
        } else if (tipoLista == "Classe") {
          item = this.list_classe.find((ele) => {
            return ele.id == id;
          });
        } else if (tipoLista == "Especie") {
          item = this.especies.find((ele) => {
            return ele.id == id;
          });
        } else if (tipoLista == "Idade") {
          item = this.list_idade.find((ele) => {
            return ele.id == id;
          });
        } else if (tipoLista == "Orgão") {
          item = this.list_orgao.find((ele) => {
            return ele.id == id;
          });
        } else if (tipoLista == "Tipo Entrada") {
          item = this.list_entrada.find((ele) => {
            return ele.id == id;
          });
        } else if (tipoLista == "Estado Animal") {
          item = this.list_entrada.find((ele) => {
            return ele.id == id;
          });
        }
      }
      return tipoLista == "Especie" ? item.popular : item.desc;
    },
    resumoEntradaForm() {
      return [
        // Animal
        {
          desc: "RGV",
          resp: this.rgv_animal ? this.rgv_animal : "-",
          tipo: "animal",
        },
        { desc: "Nome", resp: this.nome_animal, tipo: "animal" },
        // { desc: "Peso", resp: this.peso_animal, tipo: "animal" },
        {
          desc: "Sexo",
          resp: this.getDescList(this.select_sexo, "Sexo"),
          tipo: "animal",
        },
        {
          desc: "Idade",
          resp: this.getDescList(this.select_idade, "Idade"),
          tipo: "animal",
        },
        {
          desc: "Classe",
          resp: this.getDescList(this.select_classe, "Classe"),
          tipo: "animal",
        },
        {
          desc: "Espécie",
          resp: this.getDescList(this.select_classe, "Especie"),
          tipo: "animal",
        },
        // Responsável
        { desc: "Nome", resp: this.nome_responsavel, tipo: "responsavel" },
        { desc: "CPF", resp: this.cpf_responsavel, tipo: "responsavel" },
        {
          desc: "Matricula",
          resp: this.matricula_responsavel,
          tipo: "responsavel",
        },
        {
          desc: "Telefone 1",
          resp: this.telefone1_responsavel,
          tipo: "responsavel",
        },
        {
          desc: "Telefone 2",
          resp: this.telefone2_responsavel,
          tipo: "responsavel",
        },
        { desc: "CEP", resp: this.cep_responsavel, tipo: "responsavel" },
        {
          desc: "Logradouro",
          resp: this.logradouro_responsavel,
          tipo: "responsavel",
        },
        { desc: "Número", resp: this.numero_responsavel, tipo: "responsavel" },
        {
          desc: "Complemento",
          resp: this.complemento_responsavel,
          tipo: "responsavel",
        },
        { desc: "Bairro", resp: this.bairro_responsavel, tipo: "responsavel" },
        { desc: "Cidade", resp: this.cidade_responsavel, tipo: "responsavel" },
        { desc: "Estado", resp: this.estado_responsavel, tipo: "responsavel" },
        // Entrada
        {
          desc: "Orgão",
          resp: this.getDescList(this.select_orgao, "Orgão"),
          tipo: "entrada",
        },
        {
          desc: "Tipo de entrada",
          resp: this.getDescList(this.select_entrada, "Tipo Entrada"),
          tipo: "entrada",
        },
        {
          desc: "Estado Animal",
          resp: this.getDescList(this.select_estado_animal, "Estado Animal"),
          tipo: "entrada",
        },
        { desc: "Data Entrada", resp: this.data_entrada, tipo: "entrada" },
        { desc: "Hora Entrada", resp: this.hora_entrada, tipo: "entrada" },
        {
          desc: "Marcação Entrada",
          resp: this.marcacao_entrada,
          tipo: "entrada",
        },
        { desc: "Observação", resp: this.observacao_entrada, tipo: "entrada" },
        { desc: "CEP", resp: this.cep_entrada, tipo: "entrada" },
        { desc: "Logradouro", resp: this.logradouro_entrada, tipo: "entrada" },
        { desc: "Número", resp: this.numero_entrada, tipo: "entrada" },
        {
          desc: "Complemento",
          resp: this.complemento_entrada,
          tipo: "entrada",
        },
        { desc: "Bairro", resp: this.bairro_entrada, tipo: "entrada" },
        { desc: "Cidade", resp: this.cidade_entrada, tipo: "entrada" },
        { desc: "Estado", resp: this.estado_entrada, tipo: "entrada" },
      ];
    },
    searchCepEntrada() {
      if (this.cep_entrada.length == 9) {
        const cep = this.cep_entrada.replace("-", "");
        this.services.viaCepService.buscarCep(cep).then(async (response) => {
          if (response.status == 200) {
            const resp = await response.json();
            this.logradouro_entrada = resp.logradouro;
            this.numero_entrada = "";
            this.bairro_entrada = resp.bairro;
            this.cidade_entrada = resp.localidade;
            this.estado_entrada = resp.uf;
          } else {
            this.logradouro_entrada = "";
            this.numero_entrada = "";
            this.bairro_entrada = "";
            this.cidade_entrada = "";
            this.estado_entrada = "";
          }
        });
      }
    },
    searchCepResponsavel() {
      if (this.cep_responsavel.length == 9) {
        const cep = this.cep_responsavel.replace("-", "");
        this.services.viaCepService.buscarCep(cep).then(async (response) => {
          if (response.status == 200) {
            const resp = await response.json();
            this.logradouro_responsavel = resp.logradouro;
            this.numero_responsavel = "";
            this.bairro_responsavel = resp.bairro;
            this.cidade_responsavel = resp.localidade;
            this.estado_responsavel = resp.uf;
          } else {
            this.logradouro_responsavel = "";
            this.numero_responsavel = "";
            this.bairro_responsavel = "";
            this.cidade_responsavel = "";
            this.estado_responsavel = "";
          }
        });
      }
    },
    searchResponsavelCPF() {
      if (this.cpf_responsavel.length == 14) {
        const cpf = this.cpf_responsavel
          .replaceAll("-", "")
          .replaceAll(".", "");
        this.services.responsavelService
          .buscarCpf(cpf)
          .then(async (response) => {
            if (response.status == 200) {
              const resp = await response.json();
              console.log("resp", resp);
            }
          });
      }
    },
    getModel() {
      // let mod = {
      //   animal: {
      //     rgv: "7544869",
      //     nome: "Ozi",
      //     sexo: "Macho",
      //     idade: "Filhote",
      //     classe: "Aves",
      //     especie: "Águia Careca",
      //     id_pet_status: "2",
      //     id_cliente: "1",
      //   },

      //   responsavel: {
      //     nome: "teste",
      //     cpf: "454.990.495-53",
      //     matricula: "4588568589",
      //     telefone_1: "(11)98765-4321",
      //     telefone_2: "(11)98765-4321",
      //     cep: "60740-120",
      //     logradouro: "Rua José Queiroz Porto",
      //     numero: "123456",
      //     complemento: "Com222plemento",
      //     bairro: "Parangaba",
      //     cidade: "Fortaleza",
      //     estado: "CE",
      //     rg: "2007070255920",
      //     genero: "M",
      //     data_nascimento: "1996-04-15",
      //   },

      //   entrada: {
      //     orgao: "2",
      //     tipo_entrada: "1",
      //     estado_animal: "1",
      //     data_entrada: "2024-07-01",
      //     hora_entrada: "14:19",
      //     observacao: "Obs Entrada",
      //     cep: "60820-720",
      //     logradouro: "Largo 4",
      //     numero: "123456",
      //     complemento: "Com222plemento",
      //     bairro: "Jardim das Oliveiras",
      //     cidade: "Fortaleza",
      //     uf: "CE",
      //     id_endereco: "1",
      //     id_unidade: "1",
      //   },
      // };

      return {
        animal: {
          rgv: this.rgv_animal,
          // peso: this.peso_animal,
          nome: this.nome_animal,
          sexo: this.select_sexo,
          idade: this.select_idade,
          id_classe: this.select_classe.toString(),
          id_especie: this.select_especie.toString(),
          // id_pet_status: "2",
        },
        responsavel: {
          nome: this.nome_responsavel,
          cpf: this.cpf_responsavel,
          rg: this.rg_responsavel,
          genero: this.genero_responsavel,
          data_nascimento: this.nascimento_responsavel,
          matricula: this.matricula_responsavel,
          telefone_1: this.telefone1_responsavel,
          telefone_2: this.telefone2_responsavel,
          cep: this.cep_responsavel,
          logradouro: this.logradouro_responsavel,
          numero: this.numero_responsavel,
          complemento: this.complemento_responsavel,
          referencias: "",
          bairro: this.bairro_responsavel,
          reg_adm: this.reg_adm_responsavel.toString(),
          cidade: this.cidade_responsavel,
          estado: this.estado_responsavel,
        },
        entrada: {
          orgao: this.select_orgao.toString(),
          tipo_entrada: this.select_entrada,
          estado_animal: this.select_estado_animal,
          data_entrada: this.data_entrada,
          hora_entrada: this.hora_entrada,
          marcacoes: this.marcacao_entrada,
          observacao: this.observacao_entrada,
          cep: this.cep_entrada,
          logradouro: this.logradouro_entrada,
          numero: this.numero_entrada,
          complemento: this.complemento_entrada,
          referencias: "",
          bairro: this.bairro_entrada,
          reg_adm: this.reg_adm_entrada.toString(),
          cidade: this.cidade_entrada,
          uf: this.estado_entrada,
          id_unidade: JSON.parse(sessionStorage.vuex).unidade.id.toString(),
        },
      };
    },
    atualizarListaOrgaos() {
      this.list_orgao = [];
      this.services.orgaoService
        .getOrgaoPaginatedList()
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            this.list_orgao.push({
              id: element.id_orgao,
              desc: element.nome_fantasia,
            });
          }
        })
        .catch((error) => {
          console.error({ error });
        })
        .finally(() => {});
    },
    getListaEspecies() {
      // lenbrando que Espécies são as raças no sistema original
      this.services.classificacaoService
        .listSelectEspecie(1)
        .then(async (response) => {
          const body = await response.json();
          this.especies = body.data[0].map(
            ({ id, nome_cientifico, nome_popular, id_familia }) => {
              return {
                id: id,
                id_familia: id_familia,
                cientifico: nome_cientifico,
                popular: nome_popular,
              };
            }
          );
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});

      // this.services.petsService.getRacas().then((response) => {
      //   this.especies = response.data.racas;
      // });
    },
    getListaClasse() {
      this.services.classificacaoService
        .listSelectClasse(1)
        .then(async (response) => {
          const body = await response.json();
          // this.familias = body.data[0];
          this.list_classe = body.data[0].map(({ id, descricao }) => {
            return {
              id: id,
              desc: descricao,
            };
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
      // lenbrando que Classe são as especies no sistema original
      // this.services.especieService
      //   .getEspeciesPaginatedList({
      //     page: null,
      //     per_page: null,
      //     sort: null,
      //     desc: null,
      //     search: null,
      //   })
      //   .then(async (response) => {
      //     if (response.status !== 200) return;
      //     const { data } = await response.json();
      //     const { especies } = data;
      //     this.list_classe = especies.map(({ id, nome }) => {
      //       return {
      //         id: id,
      //         desc: nome,
      //       };
      //     });
      //   })
      //   .catch((e) => {
      //     console.error(e);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    getListaFamilia() {
      this.services.classificacaoService
        .listSelectFamilia(1)
        .then(async (response) => {
          const body = await response.json();
          this.familias = body.data[0].map(({ id, descricao, id_classe }) => {
            return {
              id: id,
              desc: descricao,
              id_classe: id_classe,
            };
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    getListaRegiaoAdmin() {
      this.services.regiaoAdministrativaService
        .getRegiaoAdmin()
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            this.list_reg_adm.push({
              id: element.id,
              ra: element.regiao,
              desc: element.cidade,
            });
          }
        });
    },
    addImages() {
      if (this.selectedFiles.length) {
        Array.from(this.selectedFiles).forEach((file) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.images.push({ url: e.target.result, file }); // Armazenar URL e arquivo
          };
          reader.readAsDataURL(file);
        });
        // Limpar o input após adicionar as imagens
        this.$refs.fileInput.reset();
        this.selectedFiles = []; // Limpa o valor do modelo
      }
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
    viewImage(image) {
      this.imageView = image;
      this.dialogImageView = true;
    },
    uploadImages(infoEntrada) {
      this.loadingImagens = true;
      for (let index = 0; index < this.images.length; index++) {
        const element = this.images[index];
        let formData = new FormData();
        formData.append(
          "id_unidade",
          JSON.parse(sessionStorage.vuex).unidade.id.toString()
        );
        formData.append("id_pet", infoEntrada.id_pet.toString());
        formData.append("id_entrada", infoEntrada.id_entrada.toString());
        formData.append("arquivo", element.file);
        formData.append("thumb", index == 0 ? 1 : 0);
        formData.append(
          "descricao",
          this.rgv_animal ? this.rgv_animal : "Sem descrição"
        );
        setTimeout(() => {
          this.sendImageEntrada(formData);
        }, index * 100);
      }
    },
    async sendImageEntrada(model) {
      this.services.entradaService
        .postNovaImagem(model)
        .then(async (response) => {
          if (response.status != 200) {
            this.checkImagesSend.push(false);
          } else {
            this.checkImagesSend.push(true);
          }
        })
        .catch((e) => {
          console.log("erro ", { e });
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped></style>
