<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">{{ titulo }}</h3>
        <v-btn small color="#00868d" dark @click="entradaAnimal()">
          Entrada do animal
          <v-icon right> mdi-hospital-building </v-icon>
        </v-btn>
      </div>
    </div>
    <div class="pt-2">
      <v-expansion-panels v-model="panel" elevation="1">
        <v-expansion-panel>
          <v-expansion-panel-header
            disable-icon-rotate
            expand-icon="mdi-magnify"
          >
            Pesquisa
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="row">
              <div class="col-2">
                <v-select
                  v-model="select_tipo_pesquisa"
                  :items="list_tipo_pesquisa"
                  item-text="descricao"
                  item-value="id"
                  label="Tipo de Pesquisa"
                  hide-details
                />
              </div>
              <template v-if="select_tipo_pesquisa == 1">
                <div class="col-3">
                  <v-text-field v-model="animal_id" label="ID" hide-details />
                </div>
              </template>
              <template v-else-if="select_tipo_pesquisa == 2">
                <div class="col-4">
                  <v-text-field v-model="animal_rgv" label="RGV" hide-details />
                </div>
              </template>
              <template v-else-if="select_tipo_pesquisa == 3">
                <div class="col-4">
                  <v-text-field
                    v-model="animal_nome"
                    label="Nome"
                    hide-details
                  />
                </div>
              </template>
              <template v-else-if="select_tipo_pesquisa == 4">
                <div class="col-3">
                  <v-select
                    v-model="select_classe"
                    :items="list_classe"
                    item-text="descricao"
                    item-value="id"
                    label="Classe"
                    hide-details
                  />
                </div>
                <div class="col-3">
                  <v-select
                    v-model="select_familia"
                    :items="list_familia"
                    item-text="descricao"
                    item-value="id"
                    label="Família"
                    hide-details
                    :disabled="list_familia.length == 0"
                  />
                </div>
                <div class="col-3">
                  <v-select
                    v-model="select_especie"
                    :items="list_especie"
                    item-text="nome_popular"
                    item-value="id"
                    label="Espécie"
                    hide-details
                    :disabled="list_especie.length == 0"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title
                          style="color: #313030; margin-bottom: 5px"
                        >
                          Popular: {{ data.item.nome_popular }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          style="font-weight: bold; color: #999"
                        >
                          Cientifico: {{ data.item.nome_cientifico }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </div>
              </template>
              <template v-else-if="select_tipo_pesquisa == 5">
                <div class="col-3">
                  <v-menu
                    ref="menu_date_inicial"
                    v-model="menu_date_inicial"
                    :close-on-content-click="false"
                    :return-value.sync="date_inicial"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="format_date_inicial"
                        label="Data Inicial"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_inicial"
                      no-title
                      scrollable
                      :max="date_final"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="menu_date_inicial = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu_date_inicial.save(date_inicial)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
                <div class="col-3">
                  <v-menu
                    ref="menu_date_final"
                    v-model="menu_date_final"
                    :close-on-content-click="false"
                    :return-value.sync="date_final"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="format_date_final"
                        label="Data Final"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_final"
                      no-title
                      scrollable
                      :min="date_inicial"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="menu_date_final = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu_date_final.save(date_final)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </template>
              <template v-else>
                <div class="col-9">
                  <h4>Selecione um tipo de pesquisa.</h4>
                </div>
              </template>
            </div>
            <div class="row mb-1 justify-content-between">
              <div
                class="col-3 d-flex justify-content-start align-items-center"
              >
                <v-btn
                  small
                  color="#708bcc"
                  :dark="validForm()"
                  :disabled="!validForm()"
                  @click="buscarAnimais()"
                >
                  <!-- <v-btn
                  small
                  color="#708bcc"
                  :dark="validForm()"
                  :disabled="!validForm()"
                  @click="pesquisaAnimais()"
                > -->
                  Pesquisar {{ getTipoPesquisa() }}
                  <v-icon right> mdi-magnify </v-icon>
                </v-btn>
              </div>
              <div class="col-2 d-flex justify-content-end align-items-center">
                <v-btn
                  small
                  color="#708bcc"
                  outlined
                  @click="limparPesquisaAnimais()"
                >
                  Limpar
                  <v-icon right> mdi-keyboard-return </v-icon>
                </v-btn>
              </div>
            </div>
          </v-expansion-panel-content>
          <!-- <v-expansion-panel-content>
            <div class="row">
              <div class="col-4">
                <v-text-field v-model="animal_id" label="ID" hide-details />
              </div>
              <div class="col-4">
                <v-text-field v-model="animal_rgv" label="RGV" hide-details />
              </div>
              <div class="col-4">
                <v-text-field v-model="animal_nome" label="Nome" hide-details />
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <v-select
                  v-model="select_classe"
                  :items="list_classe"
                  item-text="descricao"
                  item-value="id"
                  label="Classe"
                  hide-details
                />
              </div>
              <div class="col-3">
                <v-select
                  v-model="select_familia"
                  :items="list_familia"
                  item-text="descricao"
                  item-value="id"
                  label="Família"
                  hide-details
                />
              </div>
              <div class="col-3">
                <v-select
                  v-model="select_especie"
                  :items="list_especie"
                  item-text="nome_popular"
                  item-value="id"
                  label="Espécie"
                  hide-details
                  :disabled="list_especie.length == 0"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title
                        style="color: #313030; margin-bottom: 5px"
                      >
                        Popular: {{ data.item.nome_popular }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        style="font-weight: bold; color: #999"
                      >
                        Cientifico: {{ data.item.nome_cientifico }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
              <div class="col-5">
                <v-menu
                  v-model="menu_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_filter"
                      label="Período"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_filter"
                    @input="menu_date = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="col-2 d-flex justify-content-end align-items-center">
                <v-btn small color="#708bcc" dark @click="pesquisaAnimais()">
                  Pesquisar
                  <v-icon right> mdi-magnify </v-icon>
                </v-btn>
              </div>
            </div>
          </v-expansion-panel-content> -->
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="mt-2 pt-5">
      <v-data-table
        :headers="headers"
        :items="pets"
        :options.sync="options"
        :server-items-length="totalList"
        :loading="loading"
        class="elevation-1"
      >
        <!-- <v-data-table
        :headers="headers"
        :items="pets"
        :loading="loading"
        class="elevation-1"
      > -->
        <template v-slot:item.thumb="{ item }">
          <div>
            <v-skeleton-loader
              v-if="!item.url"
              v-bind="attrs"
              type="image"
              height="100px"
              width="100px"
              class="my-1"
              style="
                border-radius: 5px;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
              "
            ></v-skeleton-loader>
            <v-template v-else>
              <v-img
                v-if="item.url == 'sem-imagem'"
                src="@/assets/images/default_image.jpg"
                height="100px"
                width="100px"
                class="my-1"
                style="
                  border-radius: 5px;
                  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
                "
              >
              </v-img>
              <v-img
                v-else
                :src="item.url"
                height="100px"
                width="100px"
                class="my-1"
                style="
                  border-radius: 5px;
                  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
                "
              >
              </v-img>
            </v-template>
          </div>
        </template>
        <template v-slot:item.nome="{ item }">
          <div>Nome: {{ item.nome }}</div>
          <div>Rgv: {{ item.rgv }}</div>
        </template>
        <template v-slot:item.classificacao="{ item }">
          <div>Classe: {{ item.descricao }}</div>
          <div>Espécie: {{ item.nome_popular }}</div>
        </template>
        <template v-slot:item.marcacao="{ item }">
          {{ item.marcacoes }}
        </template>
        <template v-slot:item.entrada="{ item }">
          {{ entradaFormatada(item) }}
        </template>
        <!-- <template v-slot:item.entrada="{ item }">
          {{ entradaFormatada(item) }}
        </template> -->
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="getColor(item)"
            label
            dark
            style="
              width: 110px;
              justify-content: center;
              font-size: 0.7rem;
              font-weight: bold;
            "
          >
            {{ getLabel(item) }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <div
            style="width: 120px"
            class="text-center mt-2 mb-1"
            v-if="
              ![6].includes(parseInt(item.status_progressao)) && perfilId != 1
            "
          >
            <v-btn
              class="ml-2"
              x-small
              color="#0260c4"
              @click="atender(item)"
              dark
            >
              Atender
            </v-btn>
          </div>
          <div style="width: 120px" class="text-center mb-2">
            <!-- <v-btn
            class="ml-2"
            x-small
            color="#00972d"
            :dark="item.id ?? true"
            :disabled="item.id ?? false"
            @click="atender(item)"
          >
            Atender
          </v-btn> -->
            <v-btn
              class="ml-2"
              icon
              color="#1d9b03"
              x-small
              @click="verGaleria(item)"
              title="Galeria"
            >
              <v-icon> mdi mdi-image-multiple </v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              icon
              color="#ff9369"
              x-small
              @click="verArquivos(item)"
              title="Arquivos"
            >
              <v-icon> fas fa-folder </v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              icon
              color="#7735b9"
              x-small
              title="Receitas"
              @click="receitaCarbonara(item)"
              :disabled="perfilId == 1"
            >
              <v-icon>mdi-pill</v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              icon
              color="#79540e"
              x-small
              title="Marcação"
              @click="editarMarcacao(item)"
            >
              <v-icon>mdi-text-box-edit</v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              icon
              color="#606060"
              x-small
              title="Emitir Documento"
              @click="emitirDocumento(item)"
            >
              <v-icon>mdi-file-document</v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              icon
              color="#750800"
              x-small
              title="Emitir Saída"
              @click="saida(item)"
              :disabled="
                [4, 6].includes(parseInt(item.status_progressao)) ||
                perfilId == 1 ||
                item.status_internacao == 2
              "
            >
              <!-- :disabled="[4, 5, 6].includes(parseInt(item.status_progressao))" -->
              <v-icon>mdi-backspace-reverse</v-icon>
            </v-btn>

            <v-btn
              class="ml-2"
              icon
              x-small
              color="primary"
              title="Prontuários"
              @click="prontuario(item)"
            >
              <v-icon>mdi-clipboard-pulse</v-icon>
            </v-btn>

            <v-btn
              class="ml-2"
              icon
              color="#04B795"
              x-small
              title="Detalhe Animal"
              @click="detalheAnimal(item)"
            >
              <v-icon>mdi-file-eye</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:no-data>
          <p class="text-center">Nenhum registro encontrado</p>
        </template>
      </v-data-table>
    </div>

    <!-- Modal Entrada -->
    <v-dialog
      v-model="modalEntrada"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <modal-entrada
        v-if="modalEntrada"
        @close="modalEntrada = false"
        @entradaConcluida="
          listarAnimais();
          modalEntrada = false;
        "
      ></modal-entrada>
      <!-- <modal-entrada
        v-if="modalEntrada"
        @close="modalEntrada = false"
        @entradaConcluida="
          listarAnimais();
          modalEntrada = false;
        "
      ></modal-entrada> -->
    </v-dialog>
    <!-- Modal Prontuário -->
    <v-dialog v-model="dialogProntuarios" v-if="dialogProntuarios" fullscreen>
      <prontuarios
        @close="() => (dialogProntuarios = false)"
        :pacienteInfo="pacienteInfo"
        :entradaInfo="entradaInfo"
        :consultas="prontuarios"
      />
    </v-dialog>
    <!-- Modal sem Prontuário -->
    <v-dialog v-model="dialogErrorProtuarios" width="400">
      <v-card>
        <v-card-title class="text-h5 bg-danger text-white lighten-2">
          Error
        </v-card-title>

        <h4 class="text-center pt-5 px-5">Dados não encontrados</h4>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogErrorProtuarios = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Atendimento -->
    <v-dialog
      v-model="dialogAddAnaminese"
      :persistent="anamneseCriada"
      fullscreen
    >
      <v-card>
        <v-toolbar style="background-color: #1daf80; color: #fff">
          <v-btn
            icon
            dark
            @click="dialogAddAnaminese = false"
            v-if="!anamneseCriada"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="headline">Atendimento</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <modal-nova-anaminese
          v-if="dialogAddAnaminese"
          :paciente="infoAnimal"
          @close="
            anamneseCriada = false;
            dialogAddAnaminese = false;
          "
          @setAnamneseCriada="setAnamneseCriada()"
          @anamneseConcluida="
            listarAnimais();
            dialogAddAnaminese = false;
            anamneseCriada = false;
          "
        />
        <!-- <modal-nova-anaminese
          v-if="dialogAddAnaminese"
          :paciente="infoAnimal"
          @close="
            anamneseCriada = false;
            dialogAddAnaminese = false;
          "
          @setAnamneseCriada="setAnamneseCriada()"
        /> -->
      </v-card>
    </v-dialog>
    <!-- Modal Emitir Documento -->
    <v-dialog
      v-model="modalDocumento"
      width="700"
      transition="dialog-bottom-transition"
    >
      <modal-documento
        v-if="modalDocumento"
        :infoAnimal="infoAnimal"
        @close="modalDocumento = false"
      ></modal-documento>
    </v-dialog>
    <!-- Modal Emitir Documento Saida -->
    <v-dialog
      v-model="modalDocumentoSaida"
      width="700"
      transition="dialog-bottom-transition"
    >
      <modal-documento-saida
        v-if="modalDocumentoSaida"
        :infoAnimal="infoAnimal"
        @close="modalDocumentoSaida = false"
        @emitirSaida="
          listarAnimais();
          modalDocumentoSaida = false;
        "
      ></modal-documento-saida>
      <!-- <modal-documento-saida
        v-if="modalDocumentoSaida"
        :infoAnimal="infoAnimal"
        @close="modalDocumentoSaida = false"
        @emitirSaida="
          modalDocumentoSaida = false;
          modalSaida = true;
        "
      ></modal-documento-saida> -->
    </v-dialog>
    <!-- Modal Emitir Saída -->
    <v-dialog
      v-model="modalSaida"
      width="500"
      transition="dialog-bottom-transition"
    >
      <modal-saida
        v-if="modalSaida"
        :infoAnimal="infoAnimal"
        @close="modalSaida = false"
      ></modal-saida>
    </v-dialog>
    <!-- Modal Detalhe -->
    <v-dialog
      v-model="modalDetalhe"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <modal-detalhe
        v-if="modalDetalhe"
        :infoAnimal="infoAnimal"
        @close="modalDetalhe = false"
        @entradaConcluida="
          listarAnimais();
          modalDetalhe = false;
        "
      ></modal-detalhe>
    </v-dialog>
    <!-- Modal Receita Carbonara -->
    <v-dialog
      v-model="modalReceitaCarbonara"
      transition="dialog-bottom-transition"
    >
      <modal-receita-carbonara
        v-if="modalReceitaCarbonara"
        :infoAnimal="modalReceitaCarbonara"
        @close="modalReceitaCarbonara = false"
      ></modal-receita-carbonara>
    </v-dialog>
    <!-- Modal Marcação -->
    <v-dialog
      v-model="modalMarcacao"
      width="600"
      transition="dialog-bottom-transition"
    >
      <modal-marcacao
        v-if="modalMarcacao"
        :infoAnimal="infoAnimal"
        @close="modalMarcacao = false"
        @marcacaoConcluida="
          listarAnimais();
          modalMarcacao = false;
        "
      ></modal-marcacao>
    </v-dialog>
    <!-- Modal Marcação -->
    <v-dialog
      v-model="dialogArquivo"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <arquivo
        v-if="dialogArquivo"
        :paciente="petArquivo"
        @close="() => (dialogArquivo = false)"
      />
    </v-dialog>
    <!-- Modal Galeria -->
    <v-dialog
      v-model="dialogGaleria"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <ModalGaleriaEntrada
        v-if="dialogGaleria"
        :entradaInfo="infoAnimal"
        @close="() => (dialogGaleria = false)"
      />
    </v-dialog>
  </div>
</template>

<script>
import PetsService from "../services/pets_service.js";
import AnimalService from "../services/animal_service";
import Mixin from "@/mixins/vuex_mixin.js";
import vuex_snackbar_mixin from "@/mixins/vuex_snackbar_mixin.js";
import ModalEntrada from "@/components/animal/modal_entrada.vue";
import ModalSaida from "@/components/animal/modal_saida.vue";
import ModalDetalhe from "@/components/animal/modal_detalhe.vue";
import ModalDocumentoSaida from "@/components/animal/modal_documento_saida.vue";
import ModalDocumento from "@/components/animal/modal_documento.vue";
import ModalReceitaCarbonara from "@/components/animal/modal_receita_carbonara.vue";
import ModalNovaAnaminese from "@/components/animal/novo_atendimento";
import ModalMarcacao from "@/components/animal/modal_marcacao";
import ModalGaleriaEntrada from "@/components/animal/galeria_entrada/modal_galeria";
import Prontuarios from "@/components/prontuarios/prontuarios_new.vue";
import ClassificacaoService from "@/services/classificacao_service.js";
import Arquivo from "@/components/prontuarios/arquivos.vue";
import EntradaService from "@/services/entrada_service.js";

export default {
  mixins: [Mixin, vuex_snackbar_mixin],
  components: {
    ModalEntrada,
    ModalSaida,
    ModalGaleriaEntrada,
    ModalDocumentoSaida,
    ModalDocumento,
    ModalDetalhe,
    Prontuarios,
    ModalNovaAnaminese,
    ModalReceitaCarbonara,
    ModalMarcacao,
    Arquivo,
  },
  data: () => ({
    dialogArquivo: false,
    dialogGaleria: false,
    petArquivo: {},
    Arquivo: null,
    title: "",
    totalList: 10,
    pets: [],
    loading: false,
    panel: "",
    animal_id: "",
    animal_rgv: "",
    animal_nome: "",
    date_inicial: "",
    date_final: "",
    menu_date: false,
    modalEntrada: false,
    select_classe: "",
    select_familia: "",
    select_especie: "",
    especies: [],
    familias: [],
    list_especie: [],
    list_familia: [],
    list_classe: [],
    select_tipo_pesquisa: 1,
    list_tipo_pesquisa: [
      { id: 1, descricao: "ID" },
      { id: 2, descricao: "RGV" },
      { id: 3, descricao: "Nome" },
      { id: 4, descricao: "Classificação" },
      { id: 5, descricao: "Período" },
    ],
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      order: false,
      desc: false,
    },
    headers: [
      {
        text: "",
        align: "start",
        sortable: false,
        value: "thumb",
      },
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "id",
      },
      // {
      //   text: "RGV",
      //   align: "start",
      //   sortable: false,
      //   value: "rgv",
      // },
      {
        text: "Animal",
        align: "start",
        sortable: false,
        value: "nome",
      },
      {
        text: "Classificação",
        align: "start",
        sortable: false,
        value: "classificacao",
      },
      {
        text: "Marcação",
        align: "start",
        sortable: false,
        value: "marcacao",
      },
      {
        text: "Orgão",
        align: "start",
        sortable: false,
        value: "nome_fantasia",
      },
      {
        text: "Entrada",
        align: "start",
        sortable: false,
        value: "entrada",
      },
      {
        text: "Status",
        align: "center",
        sortable: false,
        value: "status",
      },
      { text: "Ações", align: "center", value: "actions", sortable: false },
    ],
    services: {
      petService: PetsService.build(),
      animalService: AnimalService.build(),
      entradaService: EntradaService.build(),
      classificacaoService: ClassificacaoService.build(),
    },
    dialogProntuarios: false,
    dialogErrorProtuarios: false,
    prontuarios: [],
    pacienteInfo: "",
    dialogAddAnaminese: false,
    anamneseCriada: false,
    infoAnimal: "",
    modalDocumento: false,
    modalDocumentoSaida: false,
    modalSaida: false,
    modalReceitaCarbonara: false,
    modalReceita: false,
    modalDetalhe: false,
    modalMarcacao: false,
    perfilId: "",
    menu_date_inicial: false,
    menu_date_final: false,
    format_date_inicial: "",
    format_date_final: "",
    pesquisaFiltro: false,
    formPesquisa: {},
    entradaInfo: {},
  }),

  watch: {
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        if (this.pesquisaFiltro) {
          this.pesquisaAnimais();
        } else {
          this.listarAnimais();
        }
      },
      deep: true,
    },
    select_classe() {
      this.select_familia = "";
      this.select_especie = "";
      if (this.select_classe) {
        this.list_familia = [];
        this.list_especie = [];
        for (let index = 0; index < this.familias.length; index++) {
          const element = this.familias[index];
          if (element.id_classe == this.select_classe) {
            this.list_familia.push(element);
          }
        }
      }
    },
    select_familia() {
      this.select_especie = "";
      if (this.select_familia) {
        this.list_especie = [];
        for (let index = 0; index < this.especies.length; index++) {
          const element = this.especies[index];
          if (element.id_familia == this.select_familia) {
            this.list_especie.push(element);
          }
        }
      }
    },
    date_inicial() {
      this.format_date_inicial = this.formatarData(this.date_inicial);
    },
    date_final() {
      this.format_date_final = this.formatarData(this.date_final);
    },
  },

  mounted() {
    this.perfilId = JSON.parse(sessionStorage.vuex).perfil.id;
    this.listarAnimais();
    this.listClasses();
    this.listFamilias();
    this.listEspecies();
  },
  computed: {
    titulo() {
      return JSON.parse(sessionStorage.vuex).perfil.id === 4
        ? "Meus animais"
        : "Animais";
    },
  },
  methods: {
    entradaAnimal() {
      this.modalEntrada = true;
    },
    async listarAnimais() {
      if (!this.loading) {
        this.loading = true;
        this.pets = [];
        const paginationParams = {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          sort: this.options.order,
          desc: this.options.desc,
          // search: filterId ? this.searchId : this.search,
          search: "",
          // filterId,
        };
        await this.services.animalService
          .getAnimaisPaginatedList(paginationParams)
          // .getAnimaisPaginatedList()
          .then(async (response) => {
            if (response.status !== 200) return;
            const { data } = await response.json();
            // this.ordenarLista(data.dados);
            let listPet = [];
            for (let index = 0; index < data.dados.length; index++) {
              const element = data.dados[index];
              let pet = { ...element, url: "" };
              if (
                element.status_internacao == 2 &&
                element.status_progressao != 6
              ) {
                pet.status_progressao = 4;
              }
              listPet.push(pet);
              // this.pets.push(pet);
            }
            this.pets = listPet;
            for (let index = 0; index < this.pets.length; index++) {
              const element = this.pets[index];
              this.getThumbEntrada(element);
            }
            // this.pets = data.dados;
            // const { pagination, pets } = data;
            // this.totalList = pagination.num_rows;
            this.totalList = data.paginacao.num_rows;
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async getThumbEntrada(entrada) {
      // console.log("ent", entrada);
      await this.services.entradaService
        .getFirstImagemEntrada(entrada.id)
        .then(async (response) => {
          const index = this.pets.findIndex((ele) => ele.id == entrada.id);
          if (response.status == 200) {
            const resp = await response.json();
            // console.log("r", resp);
            const ext = resp.data.arquivo_nome.split(".");
            this.pets[
              index
            ].url = `data:image/${ext[1]};base64,${resp.data.base64}`;
          } else {
            this.pets[index].url = "sem-imagem";
            // this.pets[index].url = "../assets/images/default_image.jpeg";
          }
        })
        .catch((e) => {
          console.log("erro ", { e });
        })
        .finally(() => {});
    },
    ordenarLista(list) {
      list = list.sort((a, b) => {
        if (a.status_progressao > b.status_progressao) return 1;
        if (a.status_progressao < b.status_progressao) return -1;
        return 0;
      });
      this.pets = list;
    },
    mapPet(pet) {
      return {
        petId: pet.id_pet,
        nome: pet.nome_pet ?? "",
        clienteNome: pet.nome,
        clienteCpf: pet.cpf,
        cor: pet.cor,
        sexo: pet.sexo,
        rgaPedigre: pet.rga_pedigree,
        nomeEspecie: pet.nome_especie,
        clienteId: pet.id_cliente,
        pacienteId: pet.id_paciente,
        // animais \/
        rgv: "",
        especie: "",
        classe: "",
        data_entrada: "",
      };
    },
    setAnamneseCriada() {
      this.anamneseCriada = true;
    },
    async prontuario(item) {
      await this.services.petService
        // .getConsultas(this.infoPaciente.PetCodigo)
        .getConsultasbyUnidade(
          item.id_pet,
          // item.PetCodigo,
          JSON.parse(sessionStorage.vuex).unidade.id
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.status !== 200) {
            this.dialogErrorProtuarios = true;
            return;
          }
          this.prontuarios = data.data.consultas;
          this.entradaInfo = item;
          this.pacienteInfo = {
            nome: "",
            nome_pet: item.nome,
            // id_pet: item.petId,
            id_pet: item.id_pet,
            id_paciente: "",
            id_cliente: "",
          };
          this.dialogProntuarios = true;
        });
    },
    atender(item) {
      this.infoAnimal = {
        ...item,
        PetCodigo: item.id_pet,
        // Pensar modo de atendimento anamnese adicional
        TutorCodigo: item.id_pessoa_juridica,
      };
      this.dialogAddAnaminese = true;
    },
    saida(item) {
      this.infoAnimal = {
        ...item,
        PetCodigo: item.petId,
        // Pensar modo de atendimento anamnese adicional
        TutorCodigo: item.pacienteId,
      };
      this.modalDocumentoSaida = true;
      // this.modalSaida = true;
    },
    detalheAnimal(item) {
      this.infoAnimal = {
        ...item,
        PetCodigo: item.petId,
        // Pensar modo de atendimento anamnese adicional
        TutorCodigo: item.pacienteId,
      };
      this.modalDetalhe = true;
    },
    emitirDocumento(item) {
      this.infoAnimal = {
        ...item,
        PetCodigo: item.petId,
        // Pensar modo de atendimento anamnese adicional
        TutorCodigo: item.pacienteId,
      };
      this.modalDocumento = true;
    },
    receitaCarbonara(item) {
      this.infoAnimal = {
        ...item,
        PetCodigo: item.petId,
        // Pensar modo de atendimento anamnese adicional
        TutorCodigo: item.pacienteId,
      };
      this.modalReceitaCarbonara = true;
    },
    editarMarcacao(item) {
      this.infoAnimal = {
        ...item,
        PetCodigo: item.petId,
        // Pensar modo de atendimento anamnese adicional
        TutorCodigo: item.pacienteId,
      };
      this.modalMarcacao = true;
    },
    verArquivos(item) {
      this.petArquivo = {
        id_paciente: item.id_paciente,
        id_orgao: item.id_orgao,
        id_pet: item.id_pet,
      };
      this.dialogArquivo = true;
    },
    verGaleria(item) {
      this.infoAnimal = {
        ...item,
        PetCodigo: item.petId,
        TutorCodigo: item.pacienteId,
      };
      this.dialogGaleria = true;
    },
    entradaFormatada(item) {
      let novaYear = item.data_entrada.split("-");
      let novaHour = item.hora_entrada.split(":");
      return `${novaYear[2]}/${novaYear[1]}/${novaYear[0]} | ${novaHour[0]}:${novaHour[1]}`;
    },
    getColor(item) {
      // 1 = aguardando
      // 2 = em atendimento
      // 3 = atendido
      // 4 = internado
      // 5 = saida
      if (item.status_progressao == 1) {
        return "#006004";
      } else if (item.status_progressao == 2 || item.status_progressao == 3) {
        return item.status_progressao == 2 ? "#bf9903" : "#005395";
      } else if (item.status_progressao == 4 || item.status_progressao == 5) {
        return item.status_progressao == 4 ? "#6217b3" : "#bf9903";
      } else {
        return "#5d5d5d";
        // return "#820900";
      }
    },
    getLabel(item) {
      if (item.status_progressao == 1) {
        return "Entrada";
      } else if (item.status_progressao == 2 || item.status_progressao == 3) {
        return item.status_progressao == 2 ? "Atendimento" : "Atendido";
      } else if (item.status_progressao == 4 || item.status_progressao == 5) {
        return item.status_progressao == 4 ? "Internado" : "Alta Internação";
      } else {
        return "Saída";
      }
    },
    listClasses() {
      this.services.classificacaoService
        .getClasseList()
        .then(async (response) => {
          const body = await response.json();
          this.list_classe = body.data[0];
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    listFamilias() {
      this.services.classificacaoService
        .getFamiliaList()
        .then(async (response) => {
          const body = await response.json();
          this.familias = body.data[0];
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    listEspecies() {
      this.services.classificacaoService
        .getEspecieList()
        .then(async (response) => {
          const body = await response.json();
          this.especies = body.data[0];
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    getTipoPesquisa() {
      let descricao = "";
      descricao = this.list_tipo_pesquisa.find((ele) => {
        return ele.id == this.select_tipo_pesquisa;
      }).descricao;
      return descricao;
    },
    buscarAnimais() {
      this.pesquisaFiltro = true;
      this.options.page = 1;
      this.formPesquisa = {
        select_tipo_pesquisa: this.select_tipo_pesquisa,
        animal_id: this.animal_id,
        animal_rgv: this.animal_rgv,
        animal_nome: this.animal_nome,
        select_classe: this.select_classe,
        select_familia: this.select_familia,
        select_especie: this.select_especie,
        date_inicial: this.date_inicial,
        date_final: this.date_final,
      };
      this.pesquisaAnimais();
    },
    pesquisaAnimais() {
      this.loading = true;
      this.pets = [];
      switch (this.formPesquisa.select_tipo_pesquisa) {
        // ID
        case 1:
          this.getAnimaisbyId(this.formPesquisa.animal_id);
          break;
        // RGV
        case 2:
          this.getAnimaisbyRgv(this.formPesquisa.animal_rgv);
          break;
        // Nome
        case 3:
          this.getAnimaisbyNome(this.formPesquisa.animal_nome);
          break;
        // Categoria
        case 4:
          this.getAnimaisbyClassificacao(
            this.formPesquisa.select_classe,
            this.formPesquisa.select_familia,
            this.formPesquisa.select_especie
          );
          break;
        // Periodo
        case 5:
          this.getAnimaisbyPeriodo(
            this.formPesquisa.date_inicial,
            this.formPesquisa.date_final
          );
          break;
        default:
          this.listarAnimais();
          break;
      }
    },
    limparPesquisaAnimais() {
      this.animal_id = "";
      this.animal_rgv = "";
      this.animal_nome = "";
      this.select_classe = "";
      this.select_familia = "";
      this.select_especie = "";
      this.date_inicial = "";
      this.date_final = "";
      this.pesquisaFiltro = false;
      (this.options.page = 1), (this.formPesquisa = {});
      this.listarAnimais();
    },
    getAnimaisbyId(id) {
      this.services.animalService
        .getEntradaAnimalFilterId(id, {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          let listPet = [];
          for (let index = 0; index < data.dados.length; index++) {
            const element = data.dados[index];
            let pet = { ...element, url: "" };
            if (
              element.status_internacao == 2 &&
              element.status_progressao != 6
            ) {
              pet.status_progressao = 4;
            }
            listPet.push(pet);
            // this.pets.push(pet);
          }
          this.pets = listPet;
          for (let index = 0; index < this.pets.length; index++) {
            const element = this.pets[index];
            this.getThumbEntrada(element);
          }
          // this.pets = data.dados;
          this.totalList = data.paginacao.num_rows;
          // this.ordenarLista(data[0]);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAnimaisbyRgv(rgv) {
      this.services.animalService
        .getEntradaAnimalFilterRgv(rgv, {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          let listPet = [];
          for (let index = 0; index < data.dados.length; index++) {
            const element = data.dados[index];
            let pet = { ...element, url: "" };
            if (
              element.status_internacao == 2 &&
              element.status_progressao != 6
            ) {
              pet.status_progressao = 4;
            }
            listPet.push(pet);
            // this.pets.push(pet);
          }
          this.pets = listPet;
          for (let index = 0; index < this.pets.length; index++) {
            const element = this.pets[index];
            this.getThumbEntrada(element);
          }
          // this.pets = data.dados;
          this.totalList = data.paginacao.num_rows;
          // this.ordenarLista(data[0]);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAnimaisbyNome(nome) {
      this.services.animalService
        .getEntradaAnimalFilterNome(nome, {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          let listPet = [];
          for (let index = 0; index < data.dados.length; index++) {
            const element = data.dados[index];
            let pet = { ...element, url: "" };
            if (
              element.status_internacao == 2 &&
              element.status_progressao != 6
            ) {
              pet.status_progressao = 4;
            }
            listPet.push(pet);
            // this.pets.push(pet);
          }
          this.pets = listPet;
          for (let index = 0; index < this.pets.length; index++) {
            const element = this.pets[index];
            this.getThumbEntrada(element);
          }
          // this.pets = data.dados;
          this.totalList = data.paginacao.num_rows;
          // this.ordenarLista(data[0]);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAnimaisbyClassificacao(idClasse = 0, idFamilia = 0, idEspecie = 0) {
      this.services.animalService
        .getEntradaAnimalFilterClassificacao(idClasse, idFamilia, idEspecie, {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          let listPet = [];
          for (let index = 0; index < data.dados.length; index++) {
            const element = data.dados[index];
            let pet = { ...element, url: "" };
            if (
              element.status_internacao == 2 &&
              element.status_progressao != 6
            ) {
              pet.status_progressao = 4;
            }
            listPet.push(pet);
            // this.pets.push(pet);
          }
          this.pets = listPet;
          for (let index = 0; index < this.pets.length; index++) {
            const element = this.pets[index];
            this.getThumbEntrada(element);
          }
          // this.pets = data.dados;
          this.totalList = data.paginacao.num_rows;
          // this.ordenarLista(data[0]);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAnimaisbyPeriodo(periodo_inicial, periodo_final) {
      this.services.animalService
        .getEntradaAnimalFilterPeriodo(periodo_inicial, periodo_final, {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          let listPet = [];
          for (let index = 0; index < data.dados.length; index++) {
            const element = data.dados[index];
            let pet = { ...element, url: "" };
            if (
              element.status_internacao == 2 &&
              element.status_progressao != 6
            ) {
              pet.status_progressao = 4;
            }
            listPet.push(pet);
            // this.pets.push(pet);
          }
          this.pets = listPet;
          for (let index = 0; index < this.pets.length; index++) {
            const element = this.pets[index];
            this.getThumbEntrada(element);
          }
          // this.pets = data.dados;
          this.totalList = data.paginacao.num_rows;
          // this.ordenarLista(data[0]);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validForm() {
      switch (this.select_tipo_pesquisa) {
        // ID
        case 1:
          return this.animal_id ? true : false;
        // RGV
        case 2:
          return this.animal_rgv ? true : false;
        // Nome
        case 3:
          return this.animal_nome ? true : false;
        // Categoria
        case 4:
          return this.select_especie
            ? true
            : this.select_familia
            ? true
            : this.select_classe
            ? true
            : false;
        // Periodo
        case 5:
          return this.date_inicial && this.date_final ? true : false;
        default:
          return false;
      }
    },
    formatarData(valor) {
      if (valor) {
        return valor.split("-").reverse().join("/");
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped></style>
