<template>
  <fila-compromisso :compromissos="consultas" :titulo="'Consultas'" message-on-empty="Não há nenhuma consulta marcada para hoje.">
    <template v-slot:card-compromisso="{ compromisso }">
      <card-consulta
          :hora-inicial="compromisso.horarioInicio"
          :hora-final="compromisso.horarioFinal"
          :nome-paciente="compromisso.nomePaciente"
          :procedimento="compromisso.procedimento"
      />
    </template>
  </fila-compromisso>
</template>

<script>
import FilaCompromisso from "@/components/dashboards/dashboard_medico/compromissos/templates/fila_compromisso";
import CardConsulta from "@/components/dashboards/dashboard_medico/compromissos/card_consulta";
export default {
  components: { FilaCompromisso, CardConsulta },
  props: {
    consultas: { type: Array, required: true }
  }
}
</script>