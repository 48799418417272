<template>
  <div>
    <VueApexCharts type="bar" :options="chartOptions" :series="series" />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  // mixins: [Mixin],
  props: {
    lista: { required: true, type: Object },
    color: { required: true, type: String },
  },
  components: {
    VueApexCharts,
  },
  data: () => ({
    series: [
      {
        name: "Atendimentos",
        data: [],
        // data: [75, 68, 66, 65, 63, 62, 60, 50, 33, 15],
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
          tools: {
            download: true,
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          barHeight: 15,
          borderRadiusApplication: "end",
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#5e5e5e"],
      xaxis: {
        categories: [],
      },
    },
  }),
  beforeMount() {
    this.series[0].data = this.lista.atendimentos;
    this.chartOptions.colors[0] = this.color;
    this.chartOptions.xaxis.categories = this.lista.descricao;
  },
};
</script>
