<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cadastro de exame</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="nome" label="Nome"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#3E682A" text @click="$emit('close')">
        Cancelar
      </v-btn>
      <v-btn color="#3E682A" :loading="loading" text @click="cadastrar" :disabled="!nome">
          Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ExameService from '@/services/exame_service.js'
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins:[Mixin],
  data: () => ({
    nome: '',
    loading: false,
  }),
  methods: {
    cadastrar() {
      const exame = {
        nome: this.nome,
        // criado_por:this.$_GETTERS_usuario.id
      }
      const exameService = new ExameService()
      this.loading = true
      exameService.cadastrar({
        onSucess: status => body => {
          if (status === 200) {
            exame.id = body.data.id_new_exame // Completar com a API
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: body.message || this.$global.messages.internalServerError,
            color: status === 200 ? 'sucess' : 'error'
          })
          this.$emit('response', exame)
        },
        onError: error => {
          console.error(error)
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: 'error'
          })
        },
        onEnd: () => {
          this.loading = false
        },
      },
      exame,
      )
    },
  },
}
</script>