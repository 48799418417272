var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pb-5"},[_c('v-flex',[_c('v-toolbar',{staticClass:"bg-verde",attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("Iniciar Atendimento")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-tabs',{attrs:{"background-color":"transparent","color":"#1daf80","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"novo-atendimento"},[_vm._v("Novo Atendimento")]),_c('v-tab',{key:"requisicao"},[_vm._v("Atender Requisições")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"novo-atendimento"},[_c('div',{staticClass:"p-2 row justify-content-center"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"mx-auto"},[_c('h3',[_vm._v(" Escolha "+_vm._s(_vm.convenioAtendimento == "publico" ? "o procedimento:" : "a requisição:")+" ")]),_c('div',{staticClass:"mt-8"},[_c('v-autocomplete',{attrs:{"items":_vm.procedimentos,"background-color":"white","label":"Procedimentos","item-text":"procedimento","return-object":"","color":"black"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.procedimento)}}),_c('v-list-item-subtitle',{staticClass:"mt-1",domProps:{"innerHTML":_vm._s(
                        item.tipo_procedimento +
                          ' - ' +
                          item.convenio_tipo +
                          ` ${item.data_requisicao &&
                            '( ' +
                              item.data_requisicao
                                .split('-')
                                .reverse()
                                .join('-') +
                              ' )'}`
                      )}})],1)]}}]),model:{value:(_vm.procedimento),callback:function ($$v) {_vm.procedimento=$$v},expression:"procedimento"}}),_c('v-btn',{on:{"click":_vm.iniciarAtendimento}},[_vm._v("Iniciar atendimento")])],1)])])])]),_c('v-tab-item',{key:"requisicao"},[_c('div',{staticClass:"p-2 row justify-content-center"},[_c('div',{staticClass:"col-lg-12"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.resquisicoesList,"items-per-page":5,"height":"300"},scopedSlots:_vm._u([{key:"item.data_hora",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatarData(item))+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"elevation":"2","fab":"","x-small":"","title":"Atender requisição","color":"green"},on:{"click":function($event){return _vm.atenderRequisicao(item)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-clipboard-pulse ")])],1)]}}])})],1)])])],1),_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.dialogLoading),callback:function ($$v) {_vm.dialogLoading=$$v},expression:"dialogLoading"}},[_c('div',{staticClass:"card-style"},[_c('h3',[_vm._v("Processando, aguarde.")]),_c('v-progress-linear',{attrs:{"indeterminate":"","color":"#1daf80"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }