import HTTPService from "@/services/http_service.js";

export default class OrgaoService extends HTTPService {
  static build() {
    return new OrgaoService();
  }
  /**
        @returns {Promise<Response>}
     */

  getOrgaoPaginatedList() {
    return this.get("orgao/list");
  }
  // getOrgaoPaginatedList({ page, per_page, search, order, desc }) {
  //   return this.get("orgao/list", { page, per_page, search, order, desc });
  // }

  getOrgaoByUsuarioLogado() {
    return this.get("usuario/meus_orgaos");
  }

  async getOrgaoById(orgao_id) {
    return await this.get(`orgao/list/${orgao_id}`);
  }

  cadastrarOrgao(orgao) {
    return this.post("orgao", orgao);
  }

  // cadastrarUnidade({
  //   cnpj,
  //   telefone_1,
  //   telefone_2,
  //   observacao,
  //   nome_fantasia,
  //   razao_social,
  //   cep,
  //   estado,
  //   cidade,
  //   bairro,
  //   logradouro,
  //   numero,
  //   complemento,
  //   referencias,
  //   ativo,
  // }) {
  //   const orgao = {
  //     cnpj,
  //     telefone_1,
  //     telefone_2,
  //     observacao,
  //     nome_fantasia,
  //     razao_social,
  //     cep,
  //     estado,
  //     cidade,
  //     bairro,
  //     logradouro,
  //     numero,
  //     complemento,
  //     referencias,
  //     ativo,
  //   };
  //   return this.post("orgao", orgao);
  // }

  atualizarOrgao(orgaoId, orgao) {
    return this.post(`unidade/${orgaoId}`, orgao);
  }

  // getUf() {
  //   return this.get(`uf/list`).then((resp) => resp.json());
  // }

  // getUfWithUnidade() {
  //   return this.get(`unidade/fisica/listuf`).then((resp) => resp.json());
  // }

  // getUnidadeWithUf() {
  //   return this.get(`unidade/fisica/listalluf`).then((resp) => resp.json());
  // }

  // getUnidadesPorIdUf(idUf) {
  //   return this.get(`unidade/fisica/uf/${idUf}`).then((resp) => resp.json());
  // }

  // postCreateUnidadeByUf({ id_uf, id_unidade, nome_unidade }) {
  //   const unidade = {
  //     id_uf,
  //     id_unidade,
  //     nome_unidade,
  //   };
  //   return this.post("unidade/uf", unidade);
  // }

  // postUpdateUnidadeByUf({ id, id_uf, id_unidade, nome_unidade }) {
  //   const unidade = {
  //     id,
  //     id_uf,
  //     id_unidade,
  //     nome_unidade,
  //   };
  //   return this.post("unidade/uf/update", unidade);
  // }

  // getUnidadeRegiaoPaginatedList({ page, per_page, search, order, desc }) {
  //   return this.get("unidade/listall", { page, per_page, search, order, desc });
  // }

  // /**
  //  @returns {Promise<Response>}
  //  */
  // getUnidadeList () {
  //     return this.get('unidade')
  // }

  // /**
  //    @returns {Promise<{observacao: String, estado: String, cidade: String, telefone1: String, numero: String, bairro: String, referencias: String, cnpj: string|String, cepId: String, telefone2: String, cep: String, nomeFantasia: string|String, complemento: String, enderecoId: String, logradouro: String, pessoaJuridicaId: String, pessoaId: String, razaoSocial: string}[]>}
  //    */
  // async getUnidadeList() {
  //   try {
  //     const response = await this.get("unidade");
  //     if (response.status !== 200) return [];
  //     const { data } = await response.json();
  //     return data.unidades.map((unidade) => ({
  //       pessoaJuridicaId: ~~unidade.pessoa_juridica_id,
  //       nomeFantasia: unidade.nome_fantasia || "",
  //       razaoSocial: unidade.razao_social || "",
  //       cnpj: unidade.cnpj || "",
  //       pessoaId: ~~unidade.pessoa_id,
  //       telefone1: unidade.telefone_1 || "",
  //       telefone2: unidade.telefone_2 || "",
  //       observacao: unidade.observacao || "",
  //       enderecoId: unidade.endereco_id,
  //       cep: unidade.cep || "",
  //       numero: unidade.numero || "",
  //       complemento: unidade.complemento || "",
  //       referencias: unidade.referencias || "",
  //       cepId: ~~unidade.cep_id,
  //       estado: unidade.estado || "",
  //       cidade: unidade.cidade || "",
  //       bairro: unidade.bairro || "",
  //       logradouro: unidade.logradouro || "",
  //       unidadeId: unidade.unidade_id || "",
  //       unidadeDescricao: unidade.descricao || "",
  //     }));
  //   } catch (e) {
  //     return [];
  //   }
  // }

  // /**
  //    @returns {Promise<Response>}
  //    */
  // cadastrarUnidade({
  //   nome_fantasia,
  //   razao_social,
  //   email,
  //   cnpj,
  //   telefone_1,
  //   telefone_2,
  //   cep,
  //   estado,
  //   cidade,
  //   bairro,
  //   logradouro,
  //   numero,
  //   complemento,
  //   referencias,
  //   chamefacil,
  // }) {
  //   const unidade = {
  //     nome_fantasia,
  //     razao_social,
  //     email,
  //     cnpj,
  //     telefone_1,
  //     telefone_2,
  //     cep,
  //     estado,
  //     cidade,
  //     bairro,
  //     logradouro,
  //     numero,
  //     complemento,
  //     referencias,
  //     chamefacil,
  //   };
  //   return this.post("unidade", unidade);
  // }

  // /**
  //    @returns {Promise<Response>}
  //    */
  // atualizarUnidade(
  //   unidadeId,
  //   pessoaJuridicaId,
  //   {
  //     nome_fantasia,
  //     descricao,
  //     razao_social,
  //     email,
  //     cnpj,
  //     telefone_1,
  //     telefone_2,
  //     cep,
  //     estado,
  //     cidade,
  //     bairro,
  //     logradouro,
  //     numero,
  //     complemento,
  //     referencias,
  //     chamefacil,
  //   }
  // ) {
  //   if (~~pessoaJuridicaId <= 0) {
  //     throw new Error(
  //       "Invalid parameter pessoaJuridicaId: " + pessoaJuridicaId
  //     );
  //   }
  //   const unidade = {
  //     nome_fantasia,
  //     descricao,
  //     razao_social,
  //     email,
  //     cnpj,
  //     telefone_1,
  //     telefone_2,
  //     cep,
  //     estado,
  //     cidade,
  //     bairro,
  //     logradouro,
  //     numero,
  //     complemento,
  //     referencias,
  //     chamefacil,
  //   };
  //   return this.post(`unidade/${unidadeId}/${pessoaJuridicaId}`, unidade);
  // }
}
