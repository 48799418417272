<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div class="px-2 text-right">
      <v-btn text @click="editandoSessao2 = !editandoSessao2">
        <v-icon v-if="editandoSessao2"> mdi-close </v-icon>
        <v-icon v-else> mdi-pencil </v-icon>
      </v-btn>
    </div>
    <div class="row">
      <div class="col-md-3">
        <label for="CPF">CPF</label>
        <v-text-field
          :disabled="!editandoSessao2"
          v-mask="'###.###.###-##'"
          v-model="cpf"
          :rules="DADOS_CADASTRAIS_MIXIN_validationCPF"
          :type="'text'"
          placeholder="000.000.000-00"
          style="padding-top: 0 !important"
        ></v-text-field>
        <span class="text-muted">ex: "123.456.7890-12"</span>
      </div>
      <div class="col-md-3">
        <label for="RG">RG</label>
        <v-text-field
          :disabled="!editandoSessao2"
          v-model="rg"
          :rules="DADOS_CADASTRAIS_MIXIN_validationRG"
          :type="'text'"
          placeholder="00.000.000-0"
          style="padding-top: 0 !important"
        ></v-text-field>
        <span class="text-muted">ex: "xx.xxx.xxx-x"</span>
      </div>
      <div class="col-md-3">
        <label>Data de nascimento</label>
        <v-text-field
          :disabled="!editandoSessao2"
          v-model="data_nascimento"
          :rules="DADOS_CADASTRAIS_MIXIN_validationDataNascimento"
          clear-icon="clear"
          style="margin: 0 5px; padding-top: 5px !important"
          :type="'date'"
          :outlined="false"
        />
        <span class="text-muted">ex: "Dia/Mês/Ano"</span>
      </div>
      <div class="col-md-3">
        <label class="">Gênero</label>
        <v-radio-group :disabled="!editandoSessao2" v-model="genero">
          <v-radio label="Masculino" value="M" />
          <v-radio label="Feminino" value="F" />
          <v-radio label="Outros" value="O" />
        </v-radio-group>
      </div>
    </div>
    <div class="px-2 text-right">
      <v-btn :disabled="!editandoSessao2" color="success" @click="salvar" :loading="DADOS_CADASTRAIS_MIXIN_loading">
        Salvar
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import DadosCadastraisMixin from '@/mixins/dados_cadastrais_mixin.js';

export default {
  mixins: [DadosCadastraisMixin],
  data: () => ({
    cpf: '',
    rg: '',
    data_nascimento: '',
    genero: '',
    editandoSessao2: false,
    valid: false
  }),
  mounted() {
    this.cpf = this.$_GETTERS_usuario.dados.cpf
    this.rg = this.$_GETTERS_usuario.dados.rg
    this.data_nascimento = this.$_GETTERS_usuario.dados.data_nascimento.substr(0,10)
    this.genero = this.$_GETTERS_usuario.dados.genero
  },
  methods: {
    salvar() {
      if (!this.$refs.form.validate() || !this.valid) {
        return;
      }
      const dados = {};
      ['cpf', 'rg', 'data_nascimento', 'genero'].forEach(param => {
          dados[param] = this[param];
      })
      // TODO: Implementar validações
      this.DADOS_CADASTRAIS_MIXIN_updateDadosCadastrais(dados);
      this.$emit('reloadData')
    },
  },
};
</script>