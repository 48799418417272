var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-12 text-left"},[_c('v-btn',{attrs:{"color":"#0e3d79","dark":""},on:{"click":function($event){_vm.dialogCadastro = true}}},[_vm._v(" Nova Região ")])],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{ 'items-per-page-options': [5, 10, 15, 20] },"headers":_vm.headers,"items":_vm.regiaoList,"options":_vm.options,"server-items-length":_vm.totalList,"loading":_vm.loading,"loading-text":'Carregando...'},on:{"update:sort-by":function($event){_vm.options.ordenacao = true},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteRegiao(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"text-center"},[_vm._v("Nenhum registro encontrado")]),_c('v-btn',{attrs:{"color":"#3E682A","dark":""},on:{"click":_vm.atualizarListaRegioes}},[_vm._v(" Atualizar ")])]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"500px","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogCadastro),callback:function ($$v) {_vm.dialogCadastro=$$v},expression:"dialogCadastro"}},[(_vm.dialogCadastro)?_c('ModalNovaRegiao',{on:{"close":function($event){_vm.dialogCadastro = false},"concluido":function($event){_vm.atualizarListaRegioes();
        _vm.dialogCadastro = false;}}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500px","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogEdicao),callback:function ($$v) {_vm.dialogEdicao=$$v},expression:"dialogEdicao"}},[(_vm.dialogEdicao)?_c('ModalEditarRegiao',{attrs:{"regiao":_vm.regiaoEdit},on:{"close":function($event){_vm.dialogEdicao = false},"concluido":function($event){_vm.atualizarListaRegioes();
        _vm.regiaoEdit = {};
        _vm.dialogEdicao = false;}}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"600px","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[(_vm.dialogDelete)?_c('ModalDeleteRegiao',{attrs:{"regiao":_vm.regiaoEdit},on:{"close":function($event){_vm.dialogDelete = false},"concluido":function($event){_vm.atualizarListaRegioes();
        _vm.regiaoEdit = {};
        _vm.dialogDelete = false;}}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-lg-3"},[_c('div',{staticClass:"row justify-content-between"},[_c('h3',{staticClass:"mt-0 mb-2 font-weight-bold text-left"},[_vm._v(" Região Administrativa ")])])])
}]

export { render, staticRenderFns }