<template>
  <v-card>
    <v-toolbar style="background-color: #bd5000; color: #fff">
      <v-toolbar-title>
        <span class="headline">Nova Familia</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="container">
        <div class="row">
          <div class="col-4">
            <v-select
              v-model="selectedClasse"
              :items="listClasse"
              item-text="descricao"
              item-value="id"
              label="Classe"
              hide-details
            />
          </div>
        </div>
        <div class="row">
          <div class="col-9">
            <v-text-field v-model="descricao" label="Descrição" hide-details />
          </div>
          <div class="col-3">
            <v-switch
              v-model="ativo"
              hide-details
              :label="ativo ? 'Ativo' : 'Inativo'"
            ></v-switch>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-content-between">
      <v-btn color="primary" outlined @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        color="#bd5000"
        :dark="descricao != '' && selectedClasse != ''"
        :disabled="descricao == '' || selectedClasse == ''"
        @click="novaFamilia()"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ClassificacaoService from "@/services/classificacao_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  props: {
    familia: Object,
  },
  components: {},
  data: () => ({
    services: {
      classificacaoService: ClassificacaoService.build(),
    },
    descricao: "",
    ativo: true,
    listClasse: [],
    selectedClasse: "",
  }),
  watch: {},
  mounted() {
    this.selectedClasse = this.familia.id_classe.toString();
    this.descricao = this.familia.descricao;
    this.ativo = this.familia.status == 1 ? true : false;
    this.listClasses();
  },
  computed: {},
  methods: {
    listClasses() {
      this.services.classificacaoService
        .getClasseList()
        .then(async (response) => {
          const body = await response.json();
          this.listClasse = body.data[0];
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    novaFamilia() {
      this.services.classificacaoService
        .postUpdateFamilia(this.model())
        .then(async (response) => {
          const body = await response.json();
          if (response.status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: "sucess",
            });
            this.$emit("response");
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: "error",
            });
            console.error(response);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    model() {
      // descricao:jumentobis de pirambas
      // id:5
      // status: 1 true || 2 false
      // id_classe:3
      return {
        id: this.familia.id,
        descricao: this.descricao,
        id_classe: this.selectedClasse,
        status: this.ativo ? 1 : 2,
      };
    },
  },
};
</script>
<style scoped></style>
