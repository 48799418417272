import { render, staticRenderFns } from "./animais.vue?vue&type=template&id=6f5a1c40&scoped=true"
import script from "./animais.vue?vue&type=script&lang=js"
export * from "./animais.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f5a1c40",
  null
  
)

export default component.exports