<template>
  <div>
    <v-card min-width="400px" style="width: 100%">
      <v-card-title class="text-break">
        <h3 class="text-center">
          <span v-if="HorarioVencido">Este horario está indiponivel.</span>
          <span v-else>certeza que deseja habilitar este horário?</span>
        </h3>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">
          <span v-if="HorarioVencido"> Fechar </span> 
          <span v-else> Cancelar </span> 
        </v-btn>

        <!-- @click="habilitarHorario" -->
        <v-btn
          v-if="!HorarioVencido"
          color="green darken-4"
          :loading="loadingHabilitar"
          style="color: #fff !important"
        >
          <span v-if="!loadingHabilitar"> Habilitar </span>
          <v-progress-circular
            v-else
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    HorarioVencido: Boolean,
  },
  data: () => ({
    loadingHabilitar: false,
  }),
};
</script>
