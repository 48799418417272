import { render, staticRenderFns } from "./nova_regiao.vue?vue&type=template&id=2ab36fbd&scoped=true"
import script from "./nova_regiao.vue?vue&type=script&lang=js"
export * from "./nova_regiao.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ab36fbd",
  null
  
)

export default component.exports