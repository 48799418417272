<template>
  <div class="container">
    <v-stepper v-model="step" non-linear>
      <v-stepper-header>
        <v-stepper-step
          step="1"
          editable
          :edit-icon="checkInternacao() ? 'mdi-check' : 'mdi-pencil'"
          :complete="checkInternacao()"
        >
          Ficha Internação
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" editable :edit-icon="'mdi-check'" complete>
          Fotos
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="3"
          editable
          :edit-icon="checkPrescricao() ? 'mdi-check' : 'mdi-pencil'"
          :complete="checkPrescricao()"
        >
          Prescrição
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <!-- Entrada -->
        <v-stepper-content step="1">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h6 class="text-left">
                  Nome: {{ infoEntrada.nome }}<br />
                  RGV: {{ infoEntrada.rgv }}<br />
                  Classificação: {{ infoEntrada.descricao }} |
                  {{ infoEntrada.nome_popular }}
                </h6>
              </div>
            </div>
            <v-divider class="my-0"></v-divider>
            <div class="row">
              <div class="col-3">
                <v-text-field
                  v-model="form_peso"
                  label="Peso (Kg)"
                  type="number"
                  min="0.0"
                  step="0.1"
                  hide-details
                >
                  <!-- <span slot="append"> Kg </span> -->
                </v-text-field>
              </div>
              <div class="col-3">
                <v-select
                  v-model="form_curativo"
                  :items="[
                    { value: 'true', desc: 'Sim' },
                    { value: 'false', desc: 'Não' },
                  ]"
                  item-text="desc"
                  item-value="value"
                  label="Curativo"
                  hide-details
                />
              </div>
              <div class="col-3">
                <v-select
                  v-model="form_consciencia"
                  :items="[
                    { value: 1, desc: 'Sim' },
                    { value: 2, desc: 'Não' },
                  ]"
                  item-text="desc"
                  item-value="value"
                  label="Consciencia"
                  hide-details
                />
              </div>
              <div class="col-3">
                <v-select
                  v-model="form_esCorpal"
                  label="Escore Corporal"
                  :items="[
                    { value: 1, desc: '1' },
                    { value: 2, desc: '2' },
                    { value: 3, desc: '3' },
                    { value: 4, desc: '4' },
                    { value: 5, desc: '5' },
                    { value: 6, desc: '6' },
                    { value: 7, desc: '7' },
                    { value: 8, desc: '8' },
                    { value: 9, desc: '9' },
                  ]"
                  item-text="desc"
                  item-value="value"
                  hide-details
                />
                <!-- <v-text-field
                  v-model="form_esCorpal"
                  label="Escore Corporal"
                  hide-details
                /> -->
              </div>
            </div>
            <div
              class="row"
              v-if="infoEntrada.id_classe == 1 || infoEntrada.id_classe == 2"
            >
              <div class="col-4">
                <!-- <v-text-field
                  v-model="form_temperatura"
                  label="Temperatura"
                  hide-details
                /> -->
                <v-text-field
                  v-model="form_temperatura"
                  label="Temperatura (°C)"
                  type="number"
                  min="0.0"
                  hide-details
                >
                  <!-- <span slot="append"> Kg </span> -->
                </v-text-field>
              </div>
              <div class="col-4">
                <!-- <v-text-field
                  v-model="form_glicemia"
                  label="Glicemia"
                  hide-details
                /> -->
                <v-text-field
                  v-model="form_glicemia"
                  label="Glicemia (mg/dL)"
                  type="number"
                  min="0.0"
                  hide-details
                >
                  <!-- <span slot="append"> Kg </span> -->
                </v-text-field>
              </div>
              <div class="col-4" v-if="infoEntrada.id_classe == 2">
                <v-text-field
                  v-model="form_pa"
                  label="Pressão Arterial (mmHg)"
                  hide-details
                >
                  <!-- <span slot="append"> Kg </span> -->
                </v-text-field>
                <!-- <v-text-field v-model="form_pa" label="Pressão Arterial (mmHg)" hide-details /> -->
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <v-textarea
                  v-model="form_alimentacao"
                  label="Alimentação"
                  hide-details
                  rows="3"
                />
              </div>
              <div class="col-6">
                <v-textarea
                  v-model="form_protMedicacao"
                  label="Protocolo Medicação"
                  hide-details
                  rows="3"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <v-textarea
                  v-model="form_metas"
                  label="Metas"
                  hide-details
                  rows="3"
                />
              </div>
              <div class="col-6">
                <v-textarea
                  v-model="form_evolucao"
                  label="Evolução"
                  hide-details
                  rows="3"
                />
              </div>
            </div>
            <div class="row"></div>
          </div>
        </v-stepper-content>
        <!-- Entrada -->
        <v-stepper-content step="2">
          <div class="container">
            <div class="row justify-content-left">
              <div class="col-12">
                <h5 class="text-left">
                  Fotos do animal ao entrar na internação
                </h5>
              </div>
            </div>
            <div class="row justify-content-center mt-0">
              <div class="col-3">
                <v-file-input
                  v-model="selectedFiles"
                  label="Escolha uma ou mais imagens"
                  multiple
                  ref="fileInput"
                  accept="image/jpeg, image/png"
                ></v-file-input>
              </div>
              <div class="col-1">
                <v-btn
                  class="mt-3"
                  @click="addImages"
                  color="primary"
                  :disabled="selectedFiles == ''"
                  >Adicionar</v-btn
                >
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="d-flex justify-content-center flex-wrap"
                  style="gap: 20px"
                >
                  <v-card
                    v-for="(image, index) in images"
                    :key="index"
                    width="200px"
                  >
                    <v-img
                      :src="image.url"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="200px"
                      width="200px"
                    >
                      <v-card-title v-text="image.file.name"></v-card-title>
                    </v-img>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn @click="viewImage(image)" icon>
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>

                      <v-btn @click="removeImage(index)" icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
              </div>
              <v-dialog
                v-if="dialogImageView"
                v-model="dialogImageView"
                width="1000"
                transition="dialog-bottom-transition"
              >
                <v-img :src="imageView.url" class="white--text align-end">
                  <v-card-title>
                    <h4 class="" style="color: white">
                      {{ imageView.file.name }}
                    </h4>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="dialogImageView = false"
                      fab
                      x-small
                      color="red"
                      dark
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                </v-img>
              </v-dialog>
            </div>
          </div>
        </v-stepper-content>
        <!-- Entrada -->
        <v-stepper-content step="3">
          <div class="container">
            <div class="row">
              <div class="col">
                <h5>Medicamento</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <v-select
                  v-model="presc_tipo"
                  :items="list_presc_tipo"
                  item-text="desc"
                  item-value="value"
                  label="Tipo Prescrição"
                  hide-details
                />
              </div>
              <div class="col-3">
                <v-select
                  v-model="presc_adm_medicamento"
                  :items="list_adm_medicamento"
                  item-text="desc"
                  item-value="value"
                  label="Administração do medicamento"
                  hide-details
                />
              </div>
              <div class="col-3">
                <v-select
                  v-model="presc_medicamento"
                  :items="list_medicamentos"
                  item-text="procedimento_descricao"
                  item-value="procedimento_id"
                  label="Medicamento"
                  hide-details
                />
              </div>
              <div class="col-3">
                <v-text-field
                  v-model="presc_dose"
                  label="dosagem"
                  hide-details
                />
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <v-select
                  v-model="presc_tip_frequencia"
                  :items="list_tip_frequencia"
                  item-text="desc"
                  item-value="value"
                  label="Tipo Frequencia"
                  hide-details
                />
              </div>
              <div class="col-2">
                <v-text-field
                  v-model="presc_frequencia"
                  label="Frequencia"
                  :type="presc_tip_frequencia == 1 ? 'time' : 'number'"
                  :min="presc_tip_frequencia == 1 ? '' : '1'"
                  hide-details
                />
              </div>
              <div class="col-2">
                <v-text-field
                  v-model="presc_ciclo"
                  label="Ciclo"
                  type="number"
                  min="1"
                  hide-details
                />
              </div>
              <div class="col-2">
                <v-text-field
                  v-model="presc_dataInicio"
                  label="Data Inicio"
                  type="date"
                  hide-details
                />
              </div>
              <div class="col-2">
                <v-text-field
                  v-model="presc_horaInicio"
                  label="Frequencia"
                  type="time"
                  hide-details
                />
              </div>
              <div class="col-2 d-flex justify-content-end align-items-end">
                <v-btn
                  icon
                  color="red"
                  title="Adicionar Medicamento"
                  @click="clearMedicamento()"
                  :disabled="checkFormMedicamentoClean()"
                >
                  <v-icon>mdi mdi-close-thick</v-icon>
                </v-btn>
                <v-btn
                  icon
                  :color="presc_edit !== '' ? 'blue' : 'green'"
                  title="Adicionar Medicamento"
                  @click="addMedicamento()"
                  :disabled="!checkFormMedicamento()"
                >
                  <v-icon
                    >mdi
                    {{
                      presc_edit !== "" ? "mdi-pencil" : "mdi-plus-thick"
                    }}</v-icon
                  >
                </v-btn>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="row">
              <div class="col-12">
                <v-simple-table height="200px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Tipo</th>
                        <th class="text-left">Adm Medicamento</th>
                        <th class="text-left">Medicamento</th>
                        <th class="text-left">dosagem</th>
                        <th class="text-left">Tipo Frequência</th>
                        <th class="text-left">Frequência</th>
                        <th class="text-left">Ciclo</th>
                        <th class="text-left">Início</th>
                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in prescricao" :key="index">
                        <th class="text-left">
                          {{ getDesc(item.tipo, "tipo_prescricao") }}
                        </th>
                        <th class="text-left">
                          {{ getDesc(item.adm_medicamento, "adm_frequencia") }}
                        </th>
                        <th class="text-left">
                          {{ getDesc(item.medicamento, "medicamento") }}
                        </th>
                        <th class="text-left">
                          {{ item.dosagem }}
                        </th>
                        <th class="text-left">
                          {{ getDesc(item.tip_frequencia, "tipo_frequencia") }}
                        </th>
                        <th class="text-left">
                          {{ item.frequencia }}
                        </th>
                        <th class="text-left">
                          {{ item.ciclo }}
                        </th>
                        <th class="text-left">
                          {{ item.dataInicio.split("-").reverse().join("/") }}
                        </th>
                        <th class="text-left">
                          <v-btn
                            class="mr-1"
                            x-small
                            icon
                            color="red"
                            title="Deletar"
                            @click="deleteMedicamento(index)"
                          >
                            <v-icon>mdi mdi-delete</v-icon>
                          </v-btn>
                          <v-btn
                            x-small
                            icon
                            color="primary"
                            title="Editar"
                            @click="editMedicamento(item, index)"
                          >
                            <v-icon>mdi mdi-pencil</v-icon>
                          </v-btn>
                        </th>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <div class="container">
      <div class="row">
        <div class="col-2">
          <v-btn
            color="primary"
            @click="entradaInternacao()"
            :disabled="!checkInternacao() || !checkPrescricao() || loading"
            :dark="checkInternacao() && checkPrescricao() && !loading"
          >
            <span v-if="!loading"> Concluir </span>
            <v-progress-circular
              indeterminate
              size="20"
              width="3"
              v-else
            ></v-progress-circular>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import InternacaoService from "@/services/internacao_service.js";
import PrescricaoService from "@/services/prescricao_service.js";
import MedicamentoAnimalService from "@/services/medicamento_animal_service.js";

export default {
  mixins: [Mixin],

  components: {},
  data: () => ({
    step: 1,
    services: {
      internacaoService: InternacaoService.build(),
      prescricaoService: PrescricaoService.build(),
      medicamentoAnimalService: MedicamentoAnimalService.build(),
    },
    // Form Ambos|Reptil
    form_peso: "",
    form_curativo: "",
    form_alimentacao: "",
    form_esCorpal: "",
    form_consciencia: "",
    form_protMedicacao: "",
    form_metas: "",
    form_evolucao: "",
    // Form Mamifero|Aves
    form_temperatura: "",
    form_glicemia: "",
    // Form Mamifero
    form_pa: "",
    // Form Prescricao
    presc_tipo: "",
    presc_adm_medicamento: "",
    presc_medicamento: "",
    presc_dose: "",
    presc_tip_frequencia: 1,
    presc_frequencia: "",
    presc_ciclo: "",
    presc_dataInicio: new Date().toISOString().split("T")[0],
    presc_horaInicio: "",
    presc_edit: "",
    prescricao: [],
    list_tip_frequencia: [
      { value: 1, desc: "Hora" },
      { value: 2, desc: "Dia" },
      { value: 3, desc: "Semana" },
    ],
    list_presc_tipo: [
      { value: 1, desc: "Medicamentosa" },
      { value: 2, desc: "Cuidados Intensivos" },
      { value: 3, desc: "Nutricional" },
      { value: 4, desc: "Terapia de Suporte" },
      { value: 5, desc: "Exames Diagnósticos" },
      { value: 6, desc: "Isolamento" },
      { value: 7, desc: "Pós-Cirúrgica" },
      { value: 8, desc: "Cuidados Paliativos" },
    ],
    list_adm_medicamento: [
      { value: 1, desc: "Via Oral" },
      { value: 2, desc: "Via Intravenosa" },
      { value: 3, desc: "Via Subcutânea" },
      { value: 4, desc: "Via Intramuscular" },
      { value: 5, desc: "Via Tópica" },
      { value: 6, desc: "Via Intraocular" },
      { value: 7, desc: "Via Intranasal" },
      { value: 8, desc: "Via Intraarticular" },
      { value: 9, desc: "Via Intraóssea" },
      { value: 10, desc: "Via Intradérmica" },
      { value: 11, desc: "Via Retal" },
      { value: 12, desc: "Via Transdérmica" },
    ],
    list_medicamentos: [],
    unidadeId: JSON.parse(sessionStorage.vuex).unidade.id,
    selectedFiles: [],
    images: [],
    imageView: {},
    loading: false,
    loadingImagens: false,
    dialogImageView: false,
    checkImagesSend: [],
    prescricaoResp: "",
  }),
  props: {
    infoEntrada: {
      required: true,
    },
  },
  watch: {
    presc_tip_frequencia() {
      if (this.presc_edit != -1) {
        this.presc_frequencia = "";
      }
    },
    checkImagesSend() {
      if (this.checkImagesSend.length == this.images.length) {
        this.loadingImagens = false;
        const checkFalse = this.checkImagesSend.some(
          (element) => element == false
        );
        if (checkFalse) {
          this.$_ACTIONS_showSnackbarMessage({
            message: `Animal internado sucesso, porem algumas imagens não puderam ser salvas.`,
            color: "warning",
          });
        } else {
          this.messagePrescricao();
          // this.$_ACTIONS_showSnackbarMessage({
          //   message: "Internação cadastrada com sucesso.",
          //   color: "sucess",
          // });
        }
        this.$emit("finalizarClose");
      }
    },
  },
  mounted() {
    this.listarMedicamento();
  },
  computed: {},
  methods: {
    entradaInternacao_modelo() {
      const model = this.getModel(this.infoEntrada);
      const modelPrescricao = this.getModelPrescricao(1);
      console.log("model", model);
      console.log("modelPrescricao", modelPrescricao);
    },
    async entradaInternacao() {
      // infoEntrada.id_classe => 1 Aves | 2 Mammalia | 3 Reptilia
      this.loading = true;
      const model = this.getModel(this.infoEntrada.id_classe);
      if (this.infoEntrada.id_classe == 1) {
        this.services.internacaoService
          .postEntradaInternacaoAves(model)
          .then(async (response) => {
            if (response.status == 200) {
              const resp = await response.json();
              this.criandoPrescricao(resp.data.id_internacao);
              // this.$_ACTIONS_showSnackbarMessage({
              //   message: "Marcação atualizado com sucesso",
              //   color: "sucess",
              // });
              // this.$emit("marcacaoConcluida");
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Erro ao internar",
                color: "error",
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {});
      } else if (this.infoEntrada.id_classe == 2) {
        this.services.internacaoService
          .postEntradaInternacaoMammalia(model)
          .then(async (response) => {
            if (response.status == 200) {
              const resp = await response.json();
              console.log("r", resp);
              this.criandoPrescricao(resp.data.id_internacao);
              // this.$_ACTIONS_showSnackbarMessage({
              //   message: "Marcação atualizado com sucesso",
              //   color: "sucess",
              // });
              // this.$emit("marcacaoConcluida");
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Erro ao internar",
                color: "error",
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {});
      } else if (this.infoEntrada.id_classe == 3) {
        this.services.internacaoService
          .postEntradaInternacaoReptillia(model)
          .then(async (response) => {
            if (response.status == 200) {
              const resp = await response.json();
              console.log("r", resp);
              this.criandoPrescricao(resp.data.id_internacao);
              // this.$_ACTIONS_showSnackbarMessage({
              //   message: "Marcação atualizado com sucesso",
              //   color: "sucess",
              // });
              // this.$emit("marcacaoConcluida");
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Erro ao internar",
                color: "error",
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {});
      }
    },
    listarMedicamento() {
      this.services.medicamentoAnimalService
        .getMedicamentosProcedimentobyUnidade(this.unidadeId)
        // .getAll()
        .then(async (response) => {
          if (response.status == 200) {
            const resp = await response.json();
            this.list_medicamentos = resp.data;
            // this.list_medicamentos = resp.data.medicamentos;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    async criandoPrescricao(id_internacao) {
      this.services.prescricaoService
        .postCriarPrescricao(this.getModelPrescricao(id_internacao))
        .then(async (response) => {
          this.prescricaoResp = response.status;
          // if (response.status == 200) {
          //   this.$_ACTIONS_showSnackbarMessage({
          //     message: "Animal Internado com sucesso",
          //     color: "sucess",
          //   });
          // } else {
          //   this.$_ACTIONS_showSnackbarMessage({
          //     message:
          //       "Animal internado, porem não foi possivel prescrever medicação",
          //     color: "error",
          //   });
          // }
          // this.$emit("finalizarClose");
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (this.images.length > 0) {
            this.uploadImages(id_internacao);
          } else {
            this.loading = false;
            this.messagePrescricao();
            this.$emit("finalizarClose");
          }
        });
    },
    messagePrescricao() {
      if (this.prescricaoResp == 200) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Animal Internado com sucesso",
          color: "sucess",
        });
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Não foi possivel prescrever medicação",
          color: "error",
        });
      }
      // if (this.prescricaoResp == 200) {
      //   this.$_ACTIONS_showSnackbarMessage({
      //     message: "Animal Internado com sucesso",
      //     color: "sucess",
      //   });
      // } else {
      //   this.$_ACTIONS_showSnackbarMessage({
      //     message:
      //       "Animal internado, porem não foi possivel prescrever medicação",
      //     color: "error",
      //   });
      // }
    },
    getModel(tipo) {
      let model = {
        id_entrada: this.infoEntrada.id,
        dados_internacao: {
          peso: parseFloat(this.form_peso),
          curativo: this.form_curativo,
          alimentacao: this.form_alimentacao,
          escore_corporal: parseInt(this.form_esCorpal),
          id_consciencia: this.form_consciencia,
          protocolo_medicacao: this.form_protMedicacao,
          metas: this.form_metas,
          evolucao: this.form_evolucao,
        },
      };
      if (tipo == 1 || tipo == 2) {
        model.dados_internacao = {
          ...model.dados_internacao,
          temperatura: parseInt(this.form_temperatura),
          glicemia: parseInt(this.form_glicemia),
        };
        if (tipo == 2) {
          model.dados_internacao = {
            ...model.dados_internacao,
            pressao_arterial: this.form_pa,
          };
        }
      }
      return model;
    },
    checkFormMedicamento() {
      if (
        this.presc_tipo == "" ||
        this.presc_frequencia == "" ||
        this.presc_adm_medicamento == "" ||
        this.presc_medicamento == "" ||
        this.presc_dose == "" ||
        this.presc_ciclo == "" ||
        this.presc_horaInicio == "" ||
        this.presc_dataInicio == ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    checkFormMedicamentoClean() {
      if (
        this.presc_tipo != "" ||
        this.presc_frequencia != "" ||
        this.presc_adm_medicamento != "" ||
        this.presc_medicamento != "" ||
        this.presc_dose != "" ||
        this.presc_ciclo != "" ||
        this.presc_horaInicio != "" ||
        this.presc_dataInicio != ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    editMedicamento(item, index) {
      this.presc_tip_frequencia = item.tip_frequencia;
      this.presc_tipo = item.tipo;
      this.presc_adm_medicamento = item.adm_medicamento;
      this.presc_medicamento = item.medicamento;
      this.presc_dose = item.dosagem;
      this.presc_ciclo = item.ciclo;
      this.presc_horaInicio = item.horaInicio;
      this.presc_dataInicio = item.dataInicio;
      let frequencia = item.tip_frequencia == 1 ? item.frequencia : 23;
      setTimeout(() => {
        this.presc_frequencia = frequencia;
      }, 200);
      this.presc_edit = index;
    },
    deleteMedicamento(index) {
      this.prescricao.splice(index, 1);
      if (index == this.presc_edit) {
        this.clearMedicamento();
      }
    },
    clearMedicamento() {
      this.presc_edit = "";
      this.presc_tipo = "";
      this.presc_tip_frequencia = 1;
      this.presc_adm_medicamento = "";
      this.presc_medicamento = "";
      this.presc_dose = "";
      this.presc_frequencia = "";
      this.presc_ciclo = "";
      this.presc_horaInicio = "";
      this.presc_dataInicio = new Date().toISOString().split("T")[0];
    },
    getModelPrescricao(id_internacao) {
      let model = {
        id_internacao: id_internacao,
        id_responsavel: JSON.parse(sessionStorage.vuex).usuario.id,
        id_perfil_responsavel: JSON.parse(sessionStorage.vuex).perfil.id,
        prescricoes: this.getModelMedicamento(),
      };
      return model;
    },
    getModelMedicamento() {
      let model = [];
      for (let index = 0; index < this.prescricao.length; index++) {
        const element = this.prescricao[index];
        // { value: 1, desc: "Hora" },
        // { value: 2, desc: "Dia" },
        // { value: 3, desc: "Semana" },
        let frequencia = 0;
        if (element.tip_frequencia == 2) {
          frequencia = element.frequencia * 24 * 60;
        } else if (element.tip_frequencia == 3) {
          frequencia = element.frequencia * 7 * 24 * 60;
        } else {
          let hora = element.frequencia.split(":")[0];
          let minutos = element.frequencia.split(":")[1];
          frequencia = parseInt(hora) * 60 + parseInt(minutos);
        }
        model.push({
          id_medicamento: element.medicamento,
          id_convenio_procedimento: this.getConvenioMedicamento(
            element.medicamento
          ),
          dosagem: element.dosagem,
          adm_medicamento: element.adm_medicamento,
          tipoPrescricao: element.tipo,
          tip_frequencia: element.tip_frequencia,
          frequencia: frequencia,
          ciclo: element.ciclo,
          dataInicio: element.dataInicio,
          horaInicio: element.horaInicio + ":00",
        });
      }
      return model;
    },
    addMedicamento() {
      const medicacao = {
        tipo: this.presc_tipo,
        adm_medicamento: this.presc_adm_medicamento,
        medicamento: this.presc_medicamento,
        dosagem: this.presc_dose,
        tip_frequencia: this.presc_tip_frequencia,
        frequencia: this.presc_frequencia,
        ciclo: this.presc_ciclo,
        horaInicio: this.presc_horaInicio,
        dataInicio: this.presc_dataInicio,
      };
      if (this.presc_edit !== "") {
        this.prescricao.splice(this.presc_edit, 1, medicacao);

        // this.prescricao[this.presc_edit] = medicacao;
      } else {
        this.prescricao.push(medicacao);
      }
      this.clearMedicamento();
    },
    checkInternacao() {
      // infoEntrada.id_classe == 1 || infoEntrada.id_classe == 2;
      if (
        this.form_peso != "" &&
        this.form_curativo != "" &&
        this.form_alimentacao != "" &&
        this.form_esCorpal != "" &&
        this.form_consciencia != "" &&
        this.form_protMedicacao != "" &&
        this.form_metas != "" &&
        this.form_evolucao != ""
      ) {
        if (
          this.infoEntrada.id_classe == 1 &&
          (this.form_temperatura == "" || this.form_glicemia == "")
        ) {
          return false;
        } else if (
          this.infoEntrada.id_classe == 2 &&
          (this.form_temperatura == "" ||
            this.form_glicemia == "" ||
            this.form_pa == "")
        ) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
    checkPrescricao() {
      if (this.prescricao.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    getConvenioMedicamento(idMedicamento) {
      let medicacao = this.list_medicamentos.find((ele) => {
        return ele.procedimento_id == idMedicamento;
      });
      return medicacao.convenio_procedimento_id;
    },
    getDesc(value, tipo) {
      let desc = "-";
      switch (tipo) {
        case "tipo_prescricao":
          desc = this.list_presc_tipo.find((ele) => {
            return ele.value == value;
          }).desc;
          break;
        case "adm_frequencia":
          desc = this.list_adm_medicamento.find((ele) => {
            return ele.value == value;
          }).desc;
          break;
        case "medicamento":
          desc = this.list_medicamentos.find((ele) => {
            return ele.procedimento_id == value;
          }).procedimento_descricao;
          break;
        case "tipo_frequencia":
          desc = this.list_tip_frequencia.find((ele) => {
            return ele.value == value;
          }).desc;
          break;
        default:
          break;
      }
      return desc;
    },
    addImages() {
      if (this.selectedFiles.length) {
        Array.from(this.selectedFiles).forEach((file) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.images.push({ url: e.target.result, file }); // Armazenar URL e arquivo
          };
          reader.readAsDataURL(file);
        });
        // Limpar o input após adicionar as imagens
        this.$refs.fileInput.reset();
        this.selectedFiles = []; // Limpa o valor do modelo
      }
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
    viewImage(image) {
      this.imageView = image;
      this.dialogImageView = true;
    },
    uploadImages(id_internacao) {
      this.loadingImagens = true;
      for (let index = 0; index < this.images.length; index++) {
        const element = this.images[index];
        let formData = new FormData();
        formData.append("id_unidade", this.unidadeId.toString());
        formData.append("id_pet", this.infoEntrada.id_pet.toString());
        formData.append("id_internacao", id_internacao.toString());
        formData.append("arquivo", element.file);
        formData.append("descricao", "Sem descrição");
        setTimeout(() => {
          this.sendImageInternacao(formData);
        }, index * 100);
      }
    },
    async sendImageInternacao(model) {
      this.services.internacaoService
        .postNovaImagem(model)
        .then(async (response) => {
          if (response.status != 200) {
            this.checkImagesSend.push(false);
          } else {
            this.checkImagesSend.push(true);
          }
        })
        .catch((e) => {
          console.log("erro ", { e });
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        })
        .finally(() => {});
    },
  },
};
</script>

<style></style>
