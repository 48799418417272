import HTTPService from "@/services/http_service.js";

export default class AnimalService extends HTTPService {
  resource = "agendamento";

  static build() {
    return new AnimalService();
  }

  postEntradaAnimal(model) {
    return this.post(`pet/entrada-animal`, model);
  }

  // getAnimaisPaginatedList() {
  //   return this.get("pet/getEntradaAnimal");
  // }
  // getAnimaisPaginatedList({ page, per_page, search, order, desc }) {
  //   return this.get("pet/getEntradaAnimal", {
  //     page,
  //     per_page,
  //     search,
  //     order,
  //     desc,
  //   });
  // }

  // getAnimaisPaginatedList({ page, per_page, search, order, desc }) {
  //   return this.get("pet/list", { page, per_page, search, order, desc });
  // }

  getAnimaisPaginatedList({ page, per_page, search, order, desc, filterId }) {
    console.log(search, filterId);
    return this.get("pet/getEntradaAnimal", {
      page,
      per_page,
      // search,
      order,
      desc,
      // filterId, // Incluindo filterId nos parâmetros, caso necessário
    });
  }

  postNewAnimal(model) {
    return this.post(`newPet`, model);
  }

  updateMarcacao(model) {
    return this.post(`pet/updatemarcacoes`, model);
  }

  getEntradaAnimalbyId(idEntrada) {
    return this.get(`pet/getEntradaAnimalById/${idEntrada}`);
  }

  getEntradaAnimalFilterId(id, { page, per_page }) {
    return this.get(`pet/getFilterByIdEntrada/${id}`, { page, per_page });
  }
  getEntradaAnimalFilterRgv(rgv) {
    // return this.get(`pet/getFilterByRgv/${rgv}`);
    const model = {
      rgv: rgv,
    };
    return this.post(`pet/getFilterByRgv`, model);
  }
  // getEntradaAnimalFilterNome(nome,{ page, per_page}) {
  getEntradaAnimalFilterNome(nome) {
    // return this.get(`pet/getFilterByNome/${nome}`);
    const model = {
      nome: nome,
    };
    return this.post(`pet/getFilterByNome`, model);
  }
  getEntradaAnimalFilterClassificacao(
    idClasse,
    idFamilia = 0,
    idEspecie = 0,
    { page, per_page }
  ) {
    if (idEspecie == 0) {
      if (idFamilia == 0) {
        return this.get(`pet/getFilterByClasse/${idClasse}`, {
          page,
          per_page,
        });
      } else {
        return this.get(`pet/getFilterByFamilia/${idFamilia}`, {
          page,
          per_page,
        });
      }
    } else {
      return this.get(`pet/getFilterByEspecie/${idEspecie}`, {
        page,
        per_page,
      });
    }
  }
  getEntradaAnimalFilterPeriodo(periodo_inicio, periodo_final) {
    // getEntradaAnimalFilterPeriodo(periodo_inicio, periodo_final, { page, per_page}) {
    let model = {
      data_inicio:
        periodo_inicio < periodo_final ? periodo_inicio : periodo_final,
      data_fim: periodo_inicio > periodo_final ? periodo_inicio : periodo_final,
    };

    return this.post(`pet/getFilterByPeriodo`, model);
    // return this.get(`pet/getFilterByPeriodo/${periodo}`);
  }
}
