<template>
  <v-card>
    <v-toolbar class="bg-verde mb-5">
      <h3 class="text-white">Edição de procedimento</h3>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="descricao"
              outlined
              label="Nome"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <!-- CAMPO TIPO DE PROCEDIMENTO -->
            <v-select
              v-model="TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento"
              :items="TIPO_PROCEDIMENTO_MIXIN_tipos_procedimento"
              :loading="TIPO_PROCEDIMENTO_MIXIN_loading"
              item-text="descricao"
              item-value="id"
              color="black"
              outlined
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12"> -->
        <!-- CAMPO EXAMES -->
        <!-- <v-select
              v-model="edited_exame"
              :items="exames"
              item-text="nome"
              item-value="id"
              outlined
              chips
              label="exames"
              multiple
              :loading="loadingExames"
            ></v-select>
          </v-col>
        </v-row> -->
      </v-container>
    </v-card-text>
    <v-card-actions class="pb-5 px-8">
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        class="btn-app-primary"
        style="color: #fff !important"
        :loading="loading"
        text
        @click="edit"
        :disabled="!allowEdition"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ProcedimentoService from "@/services/procedimento_service.js";
import Mixin from "@/mixins/vuex_mixin.js";
import TipoProcedimentoMixin from "@/mixins/tipo_procedimento_mixin.js";
import ExameService from "@/services/exame_service.js";

export default {
  mixins: [Mixin, TipoProcedimentoMixin],
  beforeMount() {
    this.edited_exame = this.procedimento.exames.map(function (e) {
      return e.id;
    });
    this.lista_exames();
  },
  mounted() {
    this.TIPO_PROCEDIMENTO_MIXIN_atualizarLista();
    (this.descricao = this.procedimento.descricao),
      (this.TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento =
        this.procedimento.id_tipo_procedimento);
  },
  data: () => ({
    exames: [],
    descricao: "",
    loading: false,
    loadingExames: false,
    edited_exame: [],
  }),
  props: {
    procedimento: {
      type: Object,
      required: true,
      validator: (obj) => {
        return obj.id && obj.descricao && obj.id_tipo_procedimento;
      },
    },
  },
  methods: {
    edit() {
      const procedimento = {};
      Object.assign(procedimento, this.procedimento);
      procedimento.descricao = this.descricao;
      procedimento.id_tipo_procedimento =
        this.TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento;
      procedimento.alterado_por = this.$_GETTERS_usuario.id;
      console.log(this.edited_exame);
      // procedimento.exames = this.edited_exame;
      procedimento.exames = [];
      const procedimentoService = new ProcedimentoService();
      this.loading = true;
      procedimentoService.updateDescricaoProcedimento(
        {
          onSucess: (status) => (body) => {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: status === 200 ? "sucess" : "error",
            });
          },
          onError: () => {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
            this.$emit("response", procedimento);
          },
        },
        procedimento
      );
    },
    lista_exames() {
      this.loadingExames = true;
      const exameService = new ExameService();
      exameService.getList({
        onSucess: (status) => (body) => {
          if (status === 200) {
            this.exames = body.data.exames.map(function (e) {
              return { id: parseInt(e.id), nome: e.nome };
            });
          } else {
            this.exames = [];
          }
        },
        onError: (error) => {
          console.error(error);
          this.exames = [];
        },
        onEnd: () => {
          this.loadingExames = false;
        },
      });
    },
  },
  computed: {
    allowEdition() {
      return true;
    },
  },
};
</script>
