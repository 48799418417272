<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #3e682a; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Observação financeira</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
            <v-textarea
              class="col-10 m-auto"
              v-model="obs"
              rows="7"
           ></v-textarea>
        </v-container>
      </v-card-text>
    <v-btn
      style="background-color: #3e682a; color: #fff !important"
      text
      @click="atualizarPaciente"
    >                                            
      <span v-if="!loading">
          Salvar
      </span>    
      <v-progress-circular
          v-else
          indeterminate
          color="white"
      ></v-progress-circular>
    </v-btn>
    </v-card>
  </div>
</template>

<script>
import PacienteService from "@/services/paciente_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  props: {
    paciente: Object,
  },
  beforeMount(){
    this.obs = this.paciente.observacao
  },
  data() {
    return {
      osb:"",
      loading:false,
    };
  },
  methods: {
    atualizarPaciente() {
      const params= {
        observacao : this.obs
      }
      this.loading = true;
      const pacienteService = new PacienteService();
      const responseFunctions = {
        onSucess: (status) => (body) => {
          this.$_ACTIONS_showSnackbarMessage({
            message: body.message || this.$global.messages.internalServerError,
            color: status === 200 ? "sucess" : "error",
          });
        },
        onError: (error) => {
          this.$_ACTIONS_showSnackbarMessage({
            message: error || this.$global.messages.internalServerError,
            color: "error",
          });
        },
        onEnd: () => {
          this.loading = false;
          this.$emit("save")
        },
      };
      pacienteService.updateObs(responseFunctions, params, this.paciente.id);
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>