import HTTPService from "@/services/http_service.js";

export default class ResponsavelService extends HTTPService {
  static build() {
    return new ResponsavelService();
  }

  buscarCpf(cpf) {
    return this.get(`responsavel/${cpf}`);
  }
}
