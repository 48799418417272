<template>
  <div class="container">
    <div class="row">
      <template v-for="(tipo, indexTipo) in listaTipos">
        <div
          class="col-4 text-left px-5"
          :key="tipo.valor + '-content-' + indexTipo"
        >
          <h3 class="title-tipo">{{ tipo.label }}</h3>
          <div
            v-for="(infoItem, index) in info.filter((ele) => {
              return ele.tipo == tipo.valor;
            })"
            :key="index"
            class="mb-1"
          >
            <h5 class="desc-tipo">{{ infoItem.desc }}</h5>
            <p class="response-tipo">
              {{ infoItem.resp ? infoItem.resp : "-" }}
            </p>
          </div>
        </div>
        <v-divider
          vertical
          v-if="indexTipo < listaTipos.length - 1"
          :key="tipo.valor + '-divider-' + indexTipo"
        ></v-divider>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  mixins: [],
  components: {},
  props: {
    info: Object,
  },
  data: () => ({
    listaTipos: [
      { valor: "entrada", label: "Entrada" },
      { valor: "animal", label: "Animal" },
      { valor: "responsavel", label: "Responsável" },
    ],
  }),
  watch: {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>
<style scoped>
.title-tipo {
  font-weight: bold;
  color: #666;
  margin-bottom: 20px;
}
.desc-tipo {
  margin: 0px 0px 8px 0px;
  font-size: 16px;
  font-weight: bold;
  color: #1a1a1a;
}
.response-tipo {
  margin: 0px;
  color: #606060;
}
</style>
