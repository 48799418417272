<template>
  <div>
    <VueApexCharts
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  // mixins: [Mixin],
  props: {
    lista: { required: true, type: Object },
    color: { required: true, type: Array },
  },
  components: {
    VueApexCharts,
  },
  data: () => ({
    series: [],
    chartOptions: {
      chart: {
        type: "bar",
        height: 250,
        toolbar: {
          show: false,
          tools: {
            download: true,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: [],
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      // yaxis: {
      //   title: {
      //     text: "Status",
      //   },
      // },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  }),
  beforeMount() {
    for (let index = 0; index < this.lista.length; index++) {
      const element = this.lista[index];
      for (let indexD = 0; indexD < element.dados.length; indexD++) {
        const elementDados = element.dados[indexD];
        const indexFind = this.series.findIndex((ele) => {
          return ele.name == elementDados.label;
        });
        if (indexFind == -1) {
          this.series.push({
            name: elementDados.label,
            data: [elementDados.value],
          });
        } else {
          this.series[indexFind].data.push(elementDados.value);
        }
      }
      this.chartOptions.xaxis.categories.push(element.desc);
    }
    this.chartOptions.colors = this.color;
  },
};
</script>
