import HTTPService from "@/services/http_service.js";

export default class RegiaoAdministrativaService extends HTTPService {
  static build() {
    return new RegiaoAdministrativaService();
  }

  getRegiaoAdmin() {
    return this.get(`regiao-administrativa`);
  }

  getRegiaoPaginatedList({ page, per_page }) {
    // getRegiaoPaginatedList({ page, per_page, search, order, desc }) {
    return this.get(`regiaoadm/list`, { page, per_page });
  }

  postNovaRegiao(model) {
    return this.postJson(`regiaoadm/new`, model);
  }

  putEditarRegiao(model) {
    return this.putJson(`regiaoadm/edit`, model);
  }

  deleteRegiao(idRegiao) {
    return this.deleteJson(`regiaoadm/remove/${idRegiao}`, {});
  }
}
