<template>
  <v-snackbar v-if="$_GETTER_snackbar.message"
              v-model="syncSnackbar"
              :color="color"
              timeout="2000"
  >
    {{ $_GETTER_snackbar.message }}
    <template v-slot:action="{ attrs }">
      <v-btn
          text
          :color="'white'"
          v-bind="attrs"
          @click="$_ACTION_showSnackbarMessage({ message: '', color: '' })"
      >
        Ok
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      $_GETTER_snackbar: 'getSnackbar',
    }),
    color () {
      const color = this.$_GETTER_snackbar.color
      const configColors = this.$global.snackbar.colors
      return configColors[color]
    },
    syncSnackbar: {
      get() {
        return this.$_GETTER_snackbar.message
      },
      set() {
        this.$_ACTION_showSnackbarMessage({ message: '', color: ''})
      }
    }
  },
  methods: {
    ...mapActions({
      $_ACTION_showSnackbarMessage: 'showSnackbarMessage'
    })
  },
}
</script>