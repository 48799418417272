<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Classificação</h3>
      </div>
    </div>
    <v-tabs v-model="tab" background-color="transparent" color="#1daf80" grow>
      <v-tab key="classe"> Classe </v-tab>
      <v-tab key="familia"> Família </v-tab>
      <v-tab key="especie"> Espécie </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="classe">
        <v-card flat> <TableClasse /> </v-card>
      </v-tab-item>
      <v-tab-item key="familia">
        <v-card flat> <TableFamilia /> </v-card>
      </v-tab-item>
      <v-tab-item key="especie">
        <v-card flat> <TableEspecie /> </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import TableClasse from "@/components/classificacao/classe/table.vue";
import TableFamilia from "@/components/classificacao/familia/table.vue";
import TableEspecie from "@/components/classificacao/especie/table.vue";

export default {
  mixins: [],
  components: {
    TableClasse,
    TableFamilia,
    TableEspecie,
  },
  data: () => ({
    tab: "",
  }),

  watch: {},

  mounted() {},
  computed: {},
  methods: {},
};
</script>
