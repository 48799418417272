<template>
  <div>
    <VueApexCharts
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  // mixins: [Mixin],
  props: {
    lista: { required: true, type: Object },
    color: { required: true, type: Array },
  },
  components: {
    VueApexCharts,
  },
  data: () => ({
    series: [
      // {
      //   name: "Exames",
      //   data: [44, 55, 41],
      // },
      // {
      //   name: "Exames Imagem",
      //   data: [53, 32, 33],
      // },
      // {
      //   name: "Cirurgia",
      //   data: [12, 17, 11],
      // },
      // {
      //   name: "Consulta",
      //   data: [9, 7, 5],
      // },
      // {
      //   name: "Medicação",
      //   data: [25, 12, 19],
      // },
      // {
      //   name: "Internação",
      //   data: [7, 20, 4],
      // },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 250,
        stacked: true,
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      // title: {
      //   text: "Fiction Books Sales",
      // },
      xaxis: {
        categories: [],
        // categories: ["Ave", "Mamífero", "Óbito"],
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        offsetX: 40,
      },
    },
  }),
  beforeMount() {
    this.chartOptions.xaxis.categories = this.lista.categorias;
    this.series = this.lista.series;
    this.chartOptions.colors[0] = this.color;
  },
};
</script>
