<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Região Administrativa
        </h3>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 text-left">
        <v-btn color="#0e3d79" dark @click="dialogCadastro = true">
          Nova Região
        </v-btn>
      </div>
    </div>
    <!-- <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :headers="headers"
      :items="regiaoList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    > -->
    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :headers="headers"
      :items="regiaoList"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:item.actions="{ item }">
        <!-- <v-icon small class="mr-2" @click="editarRegiao(item)">
          mdi-pencil
        </v-icon> -->
        <v-icon small class="mr-2" @click="deleteRegiao(item)">
          mdi-delete
        </v-icon>
        <!-- <v-icon
          v-if="allowEditarBtn"
          small
          class="mr-2"
          @click="editItem(item, false)"
        >
          fas fa-edit
        </v-icon> -->
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#3E682A" dark @click="atualizarListaRegioes">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
    <!-- Cadastro Região -->
    <v-dialog
      v-model="dialogCadastro"
      max-width="500px"
      transition="dialog-bottom-transition"
    >
      <ModalNovaRegiao
        v-if="dialogCadastro"
        @close="dialogCadastro = false"
        @concluido="
          atualizarListaRegioes();
          dialogCadastro = false;
        "
      />
    </v-dialog>
    <!-- Edição Região -->
    <v-dialog
      v-model="dialogEdicao"
      max-width="500px"
      transition="dialog-bottom-transition"
    >
      <ModalEditarRegiao
        v-if="dialogEdicao"
        :regiao="regiaoEdit"
        @close="dialogEdicao = false"
        @concluido="
          atualizarListaRegioes();
          regiaoEdit = {};
          dialogEdicao = false;
        "
      />
    </v-dialog>
    <!-- Delete Região -->
    <v-dialog
      v-model="dialogDelete"
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <ModalDeleteRegiao
        v-if="dialogDelete"
        :regiao="regiaoEdit"
        @close="dialogDelete = false"
        @concluido="
          atualizarListaRegioes();
          regiaoEdit = {};
          dialogDelete = false;
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import RegiaoAdministrativaService from "@/services/regiao_administrativa_service";
import ModalNovaRegiao from "@/components/regiao/nova_regiao.vue";
import ModalEditarRegiao from "@/components/regiao/editar_regiao.vue";
import ModalDeleteRegiao from "@/components/regiao/delete_regiao.vue";

export default {
  mixins: [Mixin],
  components: {
    ModalNovaRegiao,
    ModalEditarRegiao,
    ModalDeleteRegiao,
  },

  data: () => ({
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
    },
    headers: [
      {
        text: "Região",
        align: "start",
        sortable: true,
        value: "regiao",
      },
      { text: "Cidade", value: "cidade", sortable: false },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    services: {
      regiaoAdministrativaService: RegiaoAdministrativaService.build(),
    },
    regiaoList: [],
    regiaoEdit: {},
    dialogEdicao: false,
    dialogCadastro: false,
    dialogDelete: false,
  }),

  computed: {},

  watch: {
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarListaRegioes();
      },
      deep: true,
    },
  },

  mounted() {
    this.atualizarListaRegioes();
  },

  methods: {
    atualizarListaRegioes() {
      this.services.regiaoAdministrativaService
        .getRegiaoPaginatedList({
          page: this.options.page,
          per_page: this.options.itemsPerPage,
        })
        // .getRegiaoAdmin()
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.regiaoList = data.dados;
          this.totalList = data.paginacao.num_rows;
        });
    },
    editarRegiao(ele) {
      this.regiaoEdit = ele;
      this.dialogEdicao = true;
    },
    deleteRegiao(ele) {
      this.regiaoEdit = ele;
      this.dialogDelete = true;
    },
  },
};
</script>
