<template>
  <v-card>
    <v-toolbar style="background-color: #820606; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Deletar</span>
      </v-toolbar-title>
    </v-toolbar>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-10">
          <h3>Deseja excluir essa região administrativa?</h3>
          <h5 class="mt-4 text-left">
            Região: {{ regiao.regiao }} <br />
            Cidade: {{ regiao.cidade }}
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-end align-items-end">
          <v-btn color="#820606" dark @click="deleteRegiao()"> Deletar </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import RegiaoAdministrativaService from "@/services/regiao_administrativa_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  components: {},
  props: {
    regiao: Object,
  },
  data: () => ({
    services: {
      regiaoAdministrativaService: RegiaoAdministrativaService.build(),
    },
    inputRegiao: "",
    inputCidade: "",
  }),
  watch: {},
  async mounted() {},
  computed: {},
  methods: {
    deleteRegiao() {
      this.services.regiaoAdministrativaService
        .deleteRegiao(this.regiao.id)
        .then(async (response) => {
          console.log("resp", response);
          if (response.status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Região deletada com sucesso!",
              color: "sucess",
            });
            this.$emit("concluido");
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao cadastrar",
              color: "error",
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped></style>
